import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { ProjectIcon } from '../widgets/IconSet'

import TextLink from '../widgets/TextLink.js'
import { BUILD_REPORT, HISTORY, KNN } from '../../constants/paths.js'
import { PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID } from '../../constants/report_builder.js'
import { format_integer_with_comma, to_local_date } from '../../utils/utils.js'
import OrgParentsDisplay from '../orgs/OrgParentsDisplay.js'
import { get_parent_details } from '../../utils/organisation_utils.js'
import { get_project_viewer_url, get_report_viewer_url, report_has_bespoke_viewer } from '../../utils/report_url_utils.js'
import { track_knn_events, track_project_viewer_event, track_report_viewer_event } from '../../utils/tracking_utils.js'
import CipherFamilyLink from '../widgets/CipherFamilyLink.js'
import { normalise_search_phrase } from '../../utils/url_utils.js'
import { REPORT_SEARCH_PARAM_NAME } from '../../utils/report_history_utils.js'
import { CONTEXT } from '../../utils/knn_search.js'
import { is_creator } from '../../utils/user_permissions.js'

import cs from '../cipher_styles.module.scss'
import s from './DashboardDisplays.module.scss'

export const SectionHeader = ({children, className}) => {
  return (
    <div className={cn('mb-1', s.section_header, className)}>
      {children}
    </div>
  )
}

export const SectionWrapper = ({title, children}) => {
  return (
    <div className='mb-2'>
      <SectionHeader>{title}</SectionHeader>
      {children}
    </div>
  )
}

export const QuickSearchOrgsDisplay = ({title, orgs=[], on_click, search_phrase, user, className}) => {
  return (
    <div className={className}>
      <SectionHeader className='d-flex'>
        <div>{title || 'Organisations'}</div>
        {is_creator(user) &&
          <TextLink
            element='a'
            className={cn('ms-2 my-auto', cs.font_size_smaller)}
            href={`${BUILD_REPORT}?portfolio_search_mode=${PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID}&${PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID}=${encodeURIComponent(search_phrase)}`}
          >
            Show more results
          </TextLink>
        }
      </SectionHeader>
      {orgs.map((org, i) => {
        const { size_active, name } = org
        return (
          <div key={i} onClick={() => {on_click(org)}} className={cn(' pb-1', cs.cursor_pointer)}>
            <span><TextLink>{name}</TextLink> ({format_integer_with_comma(size_active)} {size_active === 1 ? 'family' : 'families'})</span>
            <OrgParentsDisplay org={org} get_parent_details={org => get_parent_details(org, orgs)}/>
          </div>
        )})}
    </div>
  )
}

export const QuickSearchReportsDisplay = ({reports=[], total_count, search_phrase}) => {
  return (
    <div className='mb-2'>
      <SectionHeader className='d-flex'>
        <div>Reports</div>
        {total_count > reports.length &&

          <TextLink
            element='a'
            className={cn('ms-2 my-auto', cs.font_size_smaller)}
            href={`${HISTORY}?${REPORT_SEARCH_PARAM_NAME}=${encodeURIComponent(normalise_search_phrase(search_phrase))}`}
          >
            Show all {total_count} results
          </TextLink>
        }
      </SectionHeader>
      <ReportsListDisplay
        reports={reports}
      />
    </div>
  )
}

export const ReportsListDisplay = ({reports=[]}) => {
  return (
    <div>
      {(reports || []).map((report, i) => {
        const {title, report_type, external_report_id, last_viewed, created_at} = report

        return (
          <div key={i} className='pb-1'>
            <span className='me-1'>{to_local_date(last_viewed || created_at)}</span>
            <TextLink
              element={Link}
              to={get_report_viewer_url(report_type, external_report_id)}
              onClick={() => track_report_viewer_event('obj="report" action="show" context="dashboard"')}
              className={s.report_name}
            >
              <span>{title}</span>
            </TextLink>
            { report_has_bespoke_viewer(report_type) &&
              <span className='ms-1'>({report_type})</span>
            }
          </div>
        )
      })}
    </div>
)}

export const HistoryListDisplay = ({list_items = [], history}) => {
  function on_click_report_name(e, report_type, report_id) {
    e.stopPropagation()
    track_report_viewer_event('obj="report" action="show" context="dashboard"')
    const url = get_report_viewer_url(report_type, report_id)
    history.push(url)
  }

  function on_click_project_name(e, project_id) {
    e.stopPropagation()
    track_project_viewer_event('obj="project" action="show" context="dashboard"')
    const url = get_project_viewer_url(project_id)
    history.push(url)
  }

  return (
    <div>
      {(list_items || []).map((item, idx) => {
        if (item.project_id) {
          const { project_id, name, last_viewed, created_at } = item

          return (
            <div key={idx} className='d-flex'>
              <span className={cn('me-1', cs.white_space_nowrap)}>{to_local_date(last_viewed || created_at)}</span>
              <TextLink
               onClick={(e) => {on_click_project_name(e, project_id)}}
               className={cn(cs.white_text, s.name)}
              >
                <span className={cn('me-1')}><ProjectIcon /></span> {name}
              </TextLink>
            </div>
          )
        } else {
          const {title, report_type, external_report_id, last_viewed, created_at} = item
          return (
            <div key={idx} className='d-flex'>
              <span className={cn('me-1', cs.white_space_nowrap)}>{to_local_date(last_viewed || created_at)}</span>
              <span>
                <TextLink
                  onClick={(e) => on_click_report_name(e, report_type, external_report_id)}
                  className={cn(cs.white_text, s.name)}
                >
                  <span>{title}</span>
                </TextLink>
                { report_has_bespoke_viewer(report_type) &&
                  <span className={cn('ms-1', s.name)}>({report_type})</span>
                }
              </span>
            </div>
          )
        }
      })
      }
    </div>
  )
}

export const ResultsMenu = ({children, className}) => {
  return (
    <div className='dropdown show w-100'>
      <div className={cn('dropdown-menu w-100 d-block', s.menu, className)}>
        {children}
      </div>
    </div>
  )
}

export const FamiliesDisplay = ({families=[], show_similar_families_search}) => {
  return (
    <SectionWrapper title='Families'>
      <FamiliesList
        families={families}
        show_similar_families_search={show_similar_families_search}
      />
    </SectionWrapper>
  )
}

const FamiliesList = ({families=[], show_similar_families_search}) => {
  return (
    <>
    {families.map((item, i) => {
      const {family, owner, title, publication} = item

      return (
        <div className='py-1 d-block' key={i}>
          <CipherFamilyLink
            family_id={family}
            display_text_as_link={true}
            display_link_icon={true}
            selected_patent_number={publication}
          />
          <div>
            {title && <span>{title}</span>}
            {owner &&
              <span> ({owner})</span>
            }
          </div>
        </div>
      )})}
    </>
  )
}

export const SimilarFamiliesSearch = ({families, tech_name, id}) => {
  function on_click() {
    track_knn_events(`context="${CONTEXT}" action="knn_search" obj="dashboard_search"`)
  }

  const results_url = `${KNN}/${id}`

  return (
    <div className='mb-2'>
      <SectionHeader className='d-flex'>
        <div>Similar families</div>

        {!tech_name &&
          <TextLink
            element='a'
            className={cn('ms-2 my-auto', cs.font_size_smaller)}
            href={results_url}
            onClick={on_click}
          >
            Show more results in TechDiscovery
          </TextLink>
        }
      </SectionHeader>

      {tech_name &&
        <TextLink
          element='a'
          href={results_url}
          onClick={on_click}
        >
          {tech_name}
        </TextLink>
      }
      {!tech_name &&
        <FamiliesList
          families={families}
        />
      }
    </div>
  )
}