import React, { useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import cn from 'classnames'
import { RouteWithTracing } from '../RouteWithTracing'

import {
  ALL_ES_FIELD_IDS,
  CIPHER_FAMILY_ID_FIELD_ID,
  ID_TO_FIELD,
  PAT_FAM_ID_FIELD_ID,
  PRIORITY_DATE_FIELD_ID,
  SIMILARITY_SCORE_ID
} from '../../model/patent_family_fields.js'
import { DESCENDING,  } from '../../model/sort_directions.js'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../model/patent_family_list_page_sizes.js'
import {
  FAMILIES_TO_DOWNLOAD_THRESHOLD,
  get_list_view_preference,
  get_page_size_preference,
  get_patent_families_by_ids_as_csv,
  get_patent_link_option_id_preference,
  get_sorted_families,
  update_list_view_preference,
  update_page_size_preferences,
  update_patent_link_option_id_preference,
} from '../../utils/patent_family_list_utils.js'
import { CARDS, PATENT_FAMILY_LIST_VIEWS, TABLE } from '../../model/patent_family_list_views.js'
import { PATENT_LINK_OPTION_DEFAULT_ID } from '../../model/patent_links.js'
import { withUser } from '../UserContext.js'
import PatentCardList from '../classifiers_editor/components/PatentCardList.js'
import { format_integer_with_comma, get_as_map, is_tech_discovery } from '../../utils/utils.js'
import { with_next_subidx, with_prev_subidx } from '../../utils/pagination_utils.js'
import { get_static_classname, track_knn_events, track_list_event } from '../../utils/tracking_utils.js'
import {
  CSV_FILE_EXT,
  EXCEL_FILE_EXT,
  fetch_excel_document,
  get_clean_filename,
  MIMETYPE_CSV, MIMETYPE_XLSX,
  trigger_download
} from '../../utils/download_utils.js'
import ErrorModal from '../ErrorModal.js'
import { usePatentKeyboardShortcuts } from '../../utils/keyboard_shortcuts/patent_keyboard_utils.js'
import { KNN_SEARCH } from '../classifiers_editor/constants/classifier_paths.js'
import { FAMILY_SUBPATH } from '../../constants/viewer_paths.js'
import { is_family_details_selected_patent_page, is_family_details_view_page } from '../../utils/viewer_utils.js'
import { get_csv_string, parse_csv } from '../../utils/csv_utils.js'
import { order_patent_numbers_and_select_best_representation } from '../../utils/family_view_utils.js'
import { add_family_subpath } from '../../utils/url_utils.js'
import { TABLE_ID } from '../../model/view_ids.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import { get_language_preference } from '../../utils/user_settings_utils.js'
import FamiliesTable from './FamiliesTable.js'
import PatentFamilyListViewSelector from '../patent_family_list/list_controls/PatentFamilyListViewSelector.js'
import PageSizeControl from '../PageSizeControl.js'
import PageControl from '../PageControl.js'
import { RESULTS_COLUMN_IDS } from './utils/families_table.js'
import { is_creator } from '../../utils/user_permissions.js'
import { DropdownItem } from 'reactstrap'
import PatentFamilyListActions from '../patent_family_list/PatentFamilyListActions.js'
import TextLink from '../widgets/TextLink.js'
import {
  CopyAllPublicationsToClipboardAction,
  CopyFamilyIdsToClipboardAction,
  CopyPrimaryPublicationsToClipboardAction
} from './CopyToClipboardAction.js'
import SearchBar from '../widgets/SearchBar.js'
import PatentFamilyListGuideLink from '../patent_family_list/PatentFamilyListGuideLink.js'
import { InfoIcon } from '../widgets/IconSet.js'
import { DownloadSpinner } from '../widgets/Spinner.js'
import { CONTEXT, mask_knn_id } from '../../utils/knn_search.js'
import PatentFamilyListDetailsContainer from '../patent_family_list/PatentFamilyListDetailsContainer.js'
import PatentFamilyListDetailViewNav from '../patent_family_list/PatentFamilyListDetailViewNav.js'

import cs from '../cipher_styles.module.scss'
import s from './SearchResultsDisplay.module.scss'

const ResultsBooleanSearchBox = (
  {
    search_input_ref,
    search_phrase: search_phrase_prop,
    on_change_search_phrase_handler,
    className
  }) => {

  const [search_phrase, set_search_phrase] = useState(null)

  function on_clear_search_phrase() {
    set_search_phrase('')
    search_input_ref.current.focus()
  }

  function apply_search_phrase() {
    if (search_phrase == null) return
    on_change_search_phrase_handler(search_phrase)
    set_search_phrase(null)
  }
  const search_phrase_to_display = search_phrase != null ? search_phrase : search_phrase_prop

  return (
    <div className={cn('d-flex', className)}>
      <SearchBar
        search_input_ref={search_input_ref}
        search_input={search_phrase_to_display || ''}
        on_change_search_input={set_search_phrase}
        do_search={apply_search_phrase}
        on_clear={on_clear_search_phrase}

        placeholder='Enter query to filter results'
        autofocus={false}
        is_search_valid={true}
        search_is_fetching={false}
        textAreaClassName={cs.white_bg}
        className='flex-grow-1'
      />
      <div className='fs-unmask ms-1 my-auto'>
        <PatentFamilyListGuideLink
          title={<InfoIcon />}
        />
      </div>
    </div>
  )
}

const SearchResultsDisplay = (
  {
    history,
    location,
    base_path,
    user,

    current_id,

    families_list,
    family_ids,
    blocklist,
    bookmarked,

    sort_field_id: sort_field_id_prop,
    sort_direction_id: sort_direction_id_prop,
    size: size_prop,
    search_phrase,

    build_report_from_list_path,
    build_clustered_report_from_list_path,
    build_utt_report_from_list_path,
    create_report_button_title,

    on_change_sort_field_id_and_sort_direction_id_handler,
    on_change_page_size_handler,

    on_change_search_phrase_handler,
    on_add_to_family_ids,
    on_add_to_blocklist,
    on_add_to_bookmarked,
    quick_report_path,

    export_title,
    export_file_name,
  }) => {

  const { user_settings } = useContext(UserSettingsContext) || {}
  const preferred_language = get_language_preference(user_settings)

  const is_tech_discovery_context = is_tech_discovery()

  const total_results_count = (families_list || []).length

  const patent_link_option_id_preference = get_patent_link_option_id_preference()

  const [should_reorder_list, set_should_reorder_list] = useState(true)
  const [families_list_to_display, set_families_list_to_display] = useState(null)

  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(size_prop || get_page_size_preference() || DEFAULT_PAGE_SIZE)
  const [patent_family_list_view_id, set_patent_family_list_view_id] = useState(get_list_view_preference(user) || TABLE)
  const [patent_link_option_id, set_patent_link_mode_id] = useState(patent_link_option_id_preference || PATENT_LINK_OPTION_DEFAULT_ID)
  const [sort_field_id, set_sort_field_id] = useState(sort_field_id_prop || PRIORITY_DATE_FIELD_ID)
  const [sort_direction_id, set_sort_direction_id] = useState(sort_direction_id_prop || DESCENDING)

  const [subidx, set_subidx] = useState(null)
  const [selected_patent_number, set_selected_patent_number] = useState(null)

  const [is_csv_downloading, set_is_csv_downloading] = useState(false)
  const [is_xls_downloading, set_is_xls_downloading] = useState(false)

  const [is_family_tagging_mode_on, set_is_family_tagging_mode_on] = useState(false)
  const [family_tagging_search_phrase, set_family_tagging_search_phrase] = useState(null)

  const [csv_download_error, set_csv_download_error] = useState(null)
  const [xls_download_error, set_xls_download_error] = useState(null)

  const search_input_ref = useRef()

  const num_pages = get_total_num_pages()

  const families_for_page_display = get_families_by_page()

  const is_details_view = is_family_details_view_page(location.pathname)

  usePatentKeyboardShortcuts({
    disable: (total_results_count === 0),
    source: KNN_SEARCH,
    on_prev_patent: on_prev_family,
    on_next_patent: on_next_family,
    selected_patent: subidx !== null ? (families_for_page_display || [])[subidx] : null,
    on_show_details: () => on_show_details(subidx),
    on_hide_details,
  })

  useEffect(() => {
    const _sorted_and_reversed_with_idx = get_sorted_families(families_list, sort_field_id, sort_direction_id).map((item, idx) => ({...item, idx}))
    const cipher_family_id = is_family_details_view_page(location.pathname)
    const patent_number_in_path = is_family_details_selected_patent_page(location.pathname)
    if (cipher_family_id && families_list_to_display == null) {
      const selected_family = _.find(_sorted_and_reversed_with_idx, item => item[CIPHER_FAMILY_ID_FIELD_ID] === cipher_family_id)

      const {idx=0} = selected_family || {}

      const page_updated = Math.floor(idx / page_size )
      const subidx_updated = idx % page_size
      const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)

      set_page(page_updated)
      set_subidx(subidx_updated)
      set_selected_patent_number(patent_number_in_path || representative_patent_number)

      if (!patent_number_in_path) {
        navigate_to_family_details(selected_family, representative_patent_number)
      }
    }

    set_families_list_to_display(_sorted_and_reversed_with_idx)
    set_should_reorder_list(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [should_reorder_list])

  if (families_list == null) {
    return null
  }

  function on_change_page(page) {
    track_list_event(`context="${CONTEXT}" action="pager" page="${page}"`)
    set_page(page)
  }

  function on_change_page_size(page_size) {
    track_list_event(`context="${CONTEXT}" action="change_page_size" page_size="${page_size}"`)
    set_page_size(page_size)
    set_page(0)
    update_page_size_preferences(page_size)

    if (on_change_page_size_handler) {
      on_change_page_size_handler(page_size)
    }
  }

  function on_change_patent_family_list_view_id(patent_family_list_view_id) {
    set_patent_family_list_view_id(patent_family_list_view_id)
    update_list_view_preference(patent_family_list_view_id)
  }

  function on_change_patent_link_option_id(patent_link_option_id) {
    set_patent_link_mode_id(patent_link_option_id)
    update_patent_link_option_id_preference(patent_link_option_id)
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="${CONTEXT}"`)
    set_sort_field_id(sort_field_id)
    set_sort_direction_id(sort_direction_id)
    set_should_reorder_list(true)
    if (on_change_sort_field_id_and_sort_direction_id_handler) {
      on_change_sort_field_id_and_sort_direction_id_handler(sort_field_id, sort_direction_id)
    }
  }

  function on_change_subidx(subidx) {
    set_subidx(subidx)
  }

  function on_show_details(subidx) {
    if (!families_list_to_display || families_list_to_display.length === 0) return

    track_knn_events(`context="${CONTEXT}" action="show_details_view" id="${mask_knn_id(current_id)}"`)

    set_subidx(subidx)

    const selected_family = families_list_to_display[subidx]

    if (!selected_family) return

    const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)
    set_selected_patent_number(representative_patent_number)

    navigate_to_family_details(selected_family, representative_patent_number)
  }

  function on_hide_details() {
    history.push(base_path)
  }

  function on_prev_family() {
    const { page: new_page, subidx: new_subidx } = with_prev_subidx(subidx, page, page_size)
    set_page(new_page)
    set_subidx(new_subidx)

    if (is_details_view) {
      const start_index = new_page * page_size
      const idx = start_index + new_subidx

      const selected_family = families_list_to_display[idx]
      const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)
      set_selected_patent_number(representative_patent_number)
      navigate_to_family_details(selected_family, representative_patent_number)
    }
  }

  function on_next_family() {
    const total_num_pages = get_total_num_pages()

    const { page: new_page, subidx: new_subidx } = with_next_subidx(subidx, page, page_size, total_num_pages, total_results_count)
    set_page(new_page)
    set_subidx(new_subidx)

    if (is_details_view) {
      const start_index = new_page * page_size
      const idx = start_index + new_subidx

      const selected_family = families_list_to_display[idx]
      const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_family, preferred_language)
      set_selected_patent_number(representative_patent_number)
      navigate_to_family_details(selected_family, representative_patent_number)
    }
  }

  function navigate_to_family_details(family, patent_number) {
    const url = add_family_subpath(base_path, family[CIPHER_FAMILY_ID_FIELD_ID], patent_number)

    history.push(url)
  }

  function get_total_num_pages() {
    return Math.ceil(total_results_count / page_size)
  }

  function prepare_csv_to_download() {
    const family_ids = families_list.map(family => family.patFamId)

    const selected_field_ids = [...ALL_ES_FIELD_IDS, SIMILARITY_SCORE_ID]
    const field_ids = [PAT_FAM_ID_FIELD_ID, ...ALL_ES_FIELD_IDS]

    return get_patent_families_by_ids_as_csv(family_ids, field_ids, PRIORITY_DATE_FIELD_ID, DESCENDING)
      .then(csv_response => {
        //response from the service is nicely formatted for download but it doesn't contain custom fields and can't be custom ordered so we transform it here and add values that were missing
        const parsed_response = parse_csv(csv_response)
        const families_from_response_as_objects = parsed_response.slice(1, parsed_response.length).map(list => _.object(field_ids, list))

        const families_from_response_to_patfamid = get_as_map(families_from_response_as_objects, 'patFamId')

        const header_row = selected_field_ids.map(field_id => ID_TO_FIELD[field_id].name)

        const value_rows = families_list_to_display.map(family => {
          const {patFamId} = family
          const formatted_family = families_from_response_to_patfamid[patFamId]
          return selected_field_ids.map(field_id => {
            if (field_id === SIMILARITY_SCORE_ID) {
              const similarity_score = family[SIMILARITY_SCORE_ID]

              if (!_.isNumber(similarity_score)) {
                return similarity_score || ''
              }

              return similarity_score.toFixed(2)
            }
            return formatted_family[field_id]
          })
        })

        return get_csv_string([header_row, ...value_rows])
      })
  }

  function download_as_csv() {
    set_is_csv_downloading(true)

    track_knn_events(`context="${CONTEXT}" obj="families_list" format="CSV" id="${mask_knn_id(current_id)}"`)

    prepare_csv_to_download()
      .then(csv_response => {
        const csv = csv_response
        set_is_csv_downloading(false)
        const filename = get_clean_filename( `${export_file_name || 'export_file'}${CSV_FILE_EXT}`)
        trigger_download(csv, MIMETYPE_CSV, filename)
      })
      .catch(error => {
        set_is_csv_downloading(false)
        set_csv_download_error(error)
      })
  }

  function download_as_xls() {
    set_is_xls_downloading(true)

    track_knn_events(`context="${CONTEXT}" obj="families_list" format="XLS" id="${mask_knn_id(current_id)}"`)

    prepare_csv_to_download()
      .then(csv_response => {

        const input = {
          title: export_title || '',
          description: '',
          view_id: TABLE_ID,
          data: csv_response,
          publication_link_type: patent_link_option_id
        }

        return fetch_excel_document([input], false)
      })
      .then(arraybuffer => {
        set_is_xls_downloading(false)
        const filename = get_clean_filename( `${export_file_name || 'export_file'}${EXCEL_FILE_EXT}`)
        trigger_download(arraybuffer, MIMETYPE_XLSX, filename)
      })
      .catch(error => {
        set_is_xls_downloading(false)
        set_xls_download_error(error)
      })
  }

  function get_family_idx_by_subix() {
    const start_index = page * page_size
    return start_index + subidx
  }

  function get_families_by_page() {
    const start_index = page * page_size
    return families_list_to_display ? families_list_to_display.slice(start_index, start_index + page_size) : []
  }

  function on_toggle_family_tagging_mode() {
    set_is_family_tagging_mode_on(!is_family_tagging_mode_on)
  }

  function on_update_family_tagging_search_phrase(text) {
    set_family_tagging_search_phrase(text)
  }

  function change_selected_patent_number(family, patent_number) {
    set_selected_patent_number(patent_number)
    navigate_to_family_details(family, patent_number)
  }

  function apply_search_phrase(search_phrase) {
    if (search_phrase == null) return
    on_change_search_phrase_handler(search_phrase)
  }

  function on_build_report_link_click() {
    track_knn_events(`context="${CONTEXT}" obj="families_list" format="report" id="${mask_knn_id(current_id)}"`)
  }

  function get_custom_actions() {
    const has_any_report_building_option = is_creator(user) && !is_tech_discovery_context && (build_report_from_list_path || build_clustered_report_from_list_path || build_utt_report_from_list_path)

    const custom_actions = (
      <>
        {has_any_report_building_option &&
          <>
            <DropdownItem header><span>Report</span></DropdownItem>
            {build_report_from_list_path &&
              <TextLink element='a' target='_blank' href={build_report_from_list_path} className={cn('d-inline-block dropdown-item', get_static_classname('btn_start_report_btn'), s.custom_action)} onClick={on_build_report_link_click}>Start report from list</TextLink>
            }
            {build_clustered_report_from_list_path &&
              <TextLink element='a' target='_blank' href={build_clustered_report_from_list_path} className={cn('d-inline-block dropdown-item', get_static_classname('btn_build_clustered_report_btn'), s.custom_action)} onClick={on_build_report_link_click}>
                Cluster this list
              </TextLink>
            }
            {build_utt_report_from_list_path &&
              <TextLink element='a' target='_blank' href={build_utt_report_from_list_path} className={cn('d-inline-block dropdown-item', get_static_classname('btn_build_utt_report_btn'), s.custom_action)} onClick={on_build_report_link_click}>
                Build UTT report from list
              </TextLink>
            }
            <DropdownItem divider />
          </>
        }
        <DropdownItem header>
          <span>Copy</span>
        </DropdownItem>
        {!is_tech_discovery_context &&
          <CopyFamilyIdsToClipboardAction results={families_list_to_display} context={CONTEXT} />
        }

        {is_tech_discovery_context &&
          <>
            <CopyPrimaryPublicationsToClipboardAction results={families_list_to_display} context={CONTEXT} />
            <CopyAllPublicationsToClipboardAction results={families_list_to_display}  context={CONTEXT} />
          </>
        }
      </>
    )

    return custom_actions
  }

  return (
    <>
      <RouteWithTracing
        exact={true}
        path={base_path}
        render={() => (
          <>
            <div className={cn('d-md-flex justify-content-between pt-1', s.controls_row)}>
              <div className={cn('d-md-flex flex-grow-1 my-auto', s.controls)}>
                <div className='my-auto me-2 fs-unmask'>Found families: <span className={cs.font_weight_bold}>{format_integer_with_comma(total_results_count || 0)}</span></div>
                <ResultsBooleanSearchBox
                  search_input_ref={search_input_ref}
                  search_phrase={search_phrase || ''}
                  on_change_search_phrase_handler={apply_search_phrase}
                  className='flex-grow-1 me-md-3 my-auto'
                />
              </div>

              <div className={cn('d-flex mt-2 mt-md-0', s.controls)}>
                <PatentFamilyListViewSelector
                  size='sm'
                  patent_family_list_views={PATENT_FAMILY_LIST_VIEWS}
                  patent_family_list_view_id={patent_family_list_view_id}
                  on_change_patent_family_list_view_id={on_change_patent_family_list_view_id}
                  className='my-auto d-flex'
                />
                <PageSizeControl
                  className='mx-3 fs-unmask align-items-center d-flex'
                  page_sizes={PAGE_SIZES}
                  selected_page_size={page_size}
                  on_change_page_size={on_change_page_size}
                />
                <PageControl
                  current_page={page}
                  num_pages={num_pages}
                  on_change_current_page={on_change_page}
                  className='fs-unmask'
                />
              </div>
            </div>

            <div className={s.results_wrapper}>
              {(patent_family_list_view_id === TABLE) &&
                <FamiliesTable
                  className='mt-3'
                  headerClassName={s.table_header}

                  column_ids={RESULTS_COLUMN_IDS}
                  family_ids={family_ids}
                  blocklist={blocklist}
                  bookmarked={bookmarked}
                  families={families_for_page_display}

                  subidx={subidx}

                  selected_sort_field_id={sort_field_id} // relates to 'fields' prop
                  selected_sort_direction_id={sort_direction_id}
                  on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
                  on_add_to_family_ids={(family_id) => on_add_to_family_ids({family_id, component: 'results_table'})}
                  on_add_to_blocklist={(family_id) => on_add_to_blocklist({family_id, component: 'results_table'})}
                  on_add_to_bookmarked={(family_id) => on_add_to_bookmarked({family_id, component: 'results_table'})}
                  on_change_subidx={on_change_subidx}
                  on_show_details={on_show_details}

                  selected_patent_link_option_id={patent_link_option_id}
                />
              }

              {(patent_family_list_view_id === CARDS) &&
                <PatentCardList
                  className='mt-3'
                  key={`_${(family_ids || []).length}_${(blocklist || []).length}_${(bookmarked || []).length}`}

                  patents={families_for_page_display}
                  on_show_details={on_show_details}

                  num_pages={num_pages}
                  current_page={page}
                  on_change_current_page={on_change_page}

                  subidx={subidx}

                  knn_family_ids={family_ids}
                  knn_blocklist={blocklist}
                  knn_bookmarked={bookmarked}
                  on_add_to_knn_family_ids={(family_id) => on_add_to_family_ids({family_id, component: 'results_cards'})}
                  on_add_to_knn_blocklist={(family_id) => on_add_to_blocklist({family_id, component: 'results_cards'})}
                  on_add_to_knn_bookmarked={(family_id) => on_add_to_bookmarked({family_id, component: 'results_cards'})}
                />
              }
            </div>
            <div className={cn('w-100 d-flex justify-content-center', s.toolbar)}>
              <PatentFamilyListActions
                download_as_csv_handler={download_as_csv}
                download_as_xls_handler={download_as_xls}

                is_export_in_progress={is_csv_downloading}
                no_field_ids_selected={false}

                list_no_results={total_results_count === 0}
                list_too_long={total_results_count > FAMILIES_TO_DOWNLOAD_THRESHOLD}

                CustomActions={get_custom_actions()}

                menu_on_right={false}
                menu_in_navbar={true}
                direction='up'
                button_label='Export or copy'
                download_header='Export file format'

                className='my-auto'
                buttonClassName={cn('d-flex', s.results_export_btn)}
                menuClassName={s.actions_menu}
              />

              <div className={cn('my-auto', s.download_spinner_wrapper)}>
                {(is_csv_downloading || is_xls_downloading) &&
                  <DownloadSpinner
                    text=''
                  />
                }
              </div>

              {!is_tech_discovery_context &&
                <TextLink
                  element='a'
                  href={quick_report_path}
                  title={create_report_button_title || 'Build report from search results'}
                  className={cn('my-auto px-2 d-flex fs-unmask', s.quick_report_link)}
                  onClick={() => track_knn_events(`context="${CONTEXT}" obj="families_list" format="report" id="${mask_knn_id(current_id)}"`)}
                >
                  <span className='my-auto'>Create report</span>
                </TextLink>
              }
            </div>
          </>
        )}
      />

      <RouteWithTracing
        path={`${base_path}/${FAMILY_SUBPATH}/:cipher_family_id`}
        render={() => {
          const idx = get_family_idx_by_subix()
          const selected_family = families_list_to_display ? families_list_to_display[idx] : null

          if (!selected_family) return null

          return (
            <div className={s.details_view_wrapper}>
              <PatentFamilyListDetailViewNav
                on_cancel_details_view={on_hide_details}
                current_idx={idx}
                total_results_count={total_results_count}
                on_prev_item={on_prev_family}
                on_next_item={on_next_family}

                className={cn('sticky-top py-2', s.details_nav_container)}
              />

              <PatentFamilyListDetailsContainer
                family={selected_family}
                patent_families={families_for_page_display}
                on_change_subidx={on_change_subidx}
                top_className={s.family_details_controls_row}

                patent_link_option_id={patent_link_option_id}
                on_change_patent_link_option_id={on_change_patent_link_option_id}

                selected_patent_number={selected_patent_number}
                on_change_selected_patent_number={(patent_number) => {change_selected_patent_number(selected_family, patent_number)}}

                is_family_tagging_mode_on={is_family_tagging_mode_on}
                family_tagging_search_phrase={family_tagging_search_phrase}
                on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
                on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

                knn_family_ids={family_ids}
                knn_blocklist={blocklist}
                knn_bookmarked={bookmarked}
                on_add_to_knn_family_ids={(family_id) => on_add_to_family_ids({family_id, component: 'results_details_view'})}
                on_add_to_knn_blocklist={(family_id) => on_add_to_blocklist({family_id, component: 'results_details_view'})}
                on_add_to_knn_bookmarked={(family_id) => on_add_to_bookmarked({family_id, component: 'results_details_view'})}

                className={s.details_container}
              />

            </div>
          )}}
      />

      {csv_download_error &&
        <ErrorModal
          on_hide={() => set_csv_download_error(null)}
          error={csv_download_error}
          context='exporting families as csv'
        />
      }

      {xls_download_error &&
        <ErrorModal
          on_hide={() => set_xls_download_error(null)}
          error={xls_download_error}
          context='exporting families to Excel'
        />
      }

    </>
  )
}

export default withRouter(withUser(SearchResultsDisplay))