import React from 'react'
import cn from 'classnames'

import { SimpleErrorIcon, SimpleInfoIcon } from '../widgets/IconSet.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { PORTFOLIO_SIZE_NO_PASS_THRESHOLD, PORTFOLIO_SIZE_WARNING_THRESHOLD } from '../../constants/report_builder.js'

import TextLink from '../widgets/TextLink.js'
import { SUPPORT_EMAIL_ADDRESS } from '../../constants/constants.js'
import Tooltip from '../widgets/Tooltip.js'

import s from './ReportBuilderBasketWarning.module.scss'


export const ReportBuilderBasketWarning = ({className}) => {
  return (
    <div className={cn('p-2', s.block, className)}>
      <SimpleErrorIcon className={s.warning_icon} />
      <span className='ms-2'>
        Clustering is not available for reports containing more than {format_integer_with_comma(PORTFOLIO_SIZE_WARNING_THRESHOLD)} families.
      </span>
    </div>
  )
}


export const ReportBuilderBasketStopMessage = ({user_has_classifiers, className}) => {
  return (
    <div className={cn('p-2', s.block, className)}>
      <SimpleErrorIcon className={s.stop_icon} />
      <span className='ms-2'>
        {user_has_classifiers && <span>Reports without classifiers&nbsp;</span>}
        are limited to {format_integer_with_comma(PORTFOLIO_SIZE_NO_PASS_THRESHOLD)} families or fewer. Please contact <TextLink element='a' href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>support</TextLink> for ideas on how to get the data you would like.
      </span>
    </div>
  )
}

export const BasketSizeShortWarning = ({title, is_report_big, is_report_too_big}) => {
  return (
    <span className='d-flex' title={title}>
      <SimpleErrorIcon className={cn('my-auto me-2', {[s.stop_icon] : is_report_too_big}, {[s.warning_icon]: is_report_big && !is_report_too_big})} />
      <span>Includes over {format_integer_with_comma(is_report_too_big ? PORTFOLIO_SIZE_NO_PASS_THRESHOLD : PORTFOLIO_SIZE_WARNING_THRESHOLD)} families</span>
    </span>
  )
}

export const ReportBuilderBasketWarningTooltip = ({is_in_modal, buttonClassName}) => {
  return (
    <Tooltip
      toggler={<span className={buttonClassName}><SimpleErrorIcon className={s.warning_icon} /></span>}
      is_in_modal={is_in_modal}
    >
      Clustering is not available for reports containing more than {format_integer_with_comma(PORTFOLIO_SIZE_WARNING_THRESHOLD)} families.
    </Tooltip>
  )
}

export const ReportBuilderNegativesProcessingDisabledTooltip = ({buttonClassName}) => {
  return (
    <Tooltip
      toggler={<span className={buttonClassName}><SimpleErrorIcon className={s.warning_icon} /></span>}
    >
      The input for this report contains more than {format_integer_with_comma(PORTFOLIO_SIZE_WARNING_THRESHOLD)} families. Unclassified families can not be included for reports of this size.
    </Tooltip>
  )
}

export const ReportBuilderBasketInfo = ({message, className}) => {
  return (
    <div className={cn('p-2', s.block, className)}>
      <SimpleInfoIcon className={s.info_icon} />
      <span className='ms-2'>{message}</span>
    </div>
  )
}
