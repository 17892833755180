import React from 'react'
import cn from 'classnames'

import SelectedMark from './SelectedMark.js'

import s from './ClassifierGroupsSelector.module.scss'
import cs from '../cipher_styles.module.scss'

const ClassifierGroupsSelector = ({
  name, is_selected, is_disabled, is_anything_selected, is_in_basket, on_select_handler, className
}) => {
  return (
    <div className={cn('d-flex', className)}>
      <div className={cn(s.display_marker, {[s.display_marker__active]: is_selected})}/>
      <div
        className={cn(['d-flex py-1 ps-1 pe-3 my-auto', cs.cursor_pointer, s.heading, {[s.heading__selected]: is_selected}, {[s.heading__group_displayed]: is_anything_selected}, {[s.heading__disabled]: is_disabled}])}
        onClick={on_select_handler}
      >
        <SelectedMark is_selected={is_in_basket} className={cn('my-auto', s.selected_mark)}/>
        <span className={cn('my-auto', s.name)}>{name}</span>
      </div>
    </div>
  )
}

export default ClassifierGroupsSelector