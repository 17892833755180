import React from 'react'
import { DropdownItem } from 'reactstrap'

import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import {
  LANDSCAPE_GROUPS,
  OPTION_ID_TO_LANDSCAPE_GROUP_ID,
  ID_TO_LANDSCAPE_GROUP,
  ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION,
  ACCESSION_DATE_CUT_OFF_OPTIONS
} from '../../constants/report_builder.js'
import { withUser } from '../UserContext.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

const LandscapeOptions = ({landscape, selected_option_id, on_set_selected_landscape_option}) => {
  const { options=[] } = landscape || {}

  if (options.length === 0) return null

  const option_ids = options.map(item => item.id)

  const is_any_selected = option_ids.indexOf(selected_option_id) > -1

  return (
    <div>
      <CheckboxAndLabel
        label='Select a territory:'
        is_checked={is_any_selected}
        on_click={() => is_any_selected ? on_set_selected_landscape_option(landscape) : on_set_selected_landscape_option(options[0])}

        checkboxClassName='me-2'
        className='mb-2'
      />

      <div className='d-flex flex-column ps-4'>
        {options.map(item => {

          const {id, name, short_name} = item || {}
          return (
            <RadiobuttonWithLabel
              key={id}
              label={short_name || name || ''}
              on_click={() => on_set_selected_landscape_option(item)}
              is_checked={selected_option_id === item.id}
            />
          )
        })}
      </div>
    </div>
  )
}

const LandscapeAsPortfolio = (
  {
    user,
    selected_landscape_option,
    selected_new_families_option,
    on_set_selected_landscape_option,
    on_set_selected_new_families_option,
    className
  }) => {

  function get_selected_main_landscape_option(selected_option_id) {
    return OPTION_ID_TO_LANDSCAPE_GROUP_ID[selected_option_id] ? ID_TO_LANDSCAPE_GROUP[OPTION_ID_TO_LANDSCAPE_GROUP_ID[selected_option_id]] : selected_landscape_option
  }

  const geo_filters = LANDSCAPE_GROUPS.filter(item => {
    const { check_if_available } = item

    if (!check_if_available) return true

    return check_if_available({ user })
  })

  const selected_option_id = (selected_landscape_option || {}).id

  return (
    <div className={className}>
      <h3>Landscape report options:</h3>

      <div className='d-flex mb-2'>
        {geo_filters.map((item, i) => {
          const { name, id } = item || {}

          const is_selected = (id === selected_option_id) || (OPTION_ID_TO_LANDSCAPE_GROUP_ID[selected_option_id] === id)

          return (
            <RadiobuttonWithLabel
              key={i}
              label={name}
              on_click={() => on_set_selected_landscape_option(item)}
              is_checked={is_selected}

              className='me-3'
            />
          )
        })}
      </div>

      <LandscapeOptions
        key={selected_option_id || 'none'}
        landscape={get_selected_main_landscape_option(selected_option_id)}
        selected_option_id={selected_option_id}
        on_set_selected_landscape_option={on_set_selected_landscape_option}
      />

      <div className='d-flex mt-4'>
        <CheckboxStatic
          is_checked={selected_new_families_option != null}
          onClick={() => on_set_selected_new_families_option(selected_new_families_option == null ? ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION : null)}
          className='my-auto'
        />
        <span className='d-flex'>
          <span className='my-auto mx-1'>only families added in the last</span>
          <BaseDropdown
            disabled={!selected_new_families_option}
            label={selected_new_families_option || ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION}
          >
            {ACCESSION_DATE_CUT_OFF_OPTIONS.map((item, i) => {
              return (
                <DropdownItem
                  key={i}
                  onClick={() => on_set_selected_new_families_option(item)}
                  active={selected_new_families_option === item}
                >
                  {item}
                </DropdownItem>
              )
            })}
          </BaseDropdown>
          <span className='my-auto ms-1'>days</span>
        </span>
      </div>
    </div>
  )
}

export default withUser(LandscapeAsPortfolio)
