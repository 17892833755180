import axios from 'axios'

import { FEEDBACK_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from './axios_utils.js'

export function send_feedback_email({url, category, subject, comment, additional_comment}) {
  const data = { url, category, subject, comment, additional_comment }

  return axios.post(`${FEEDBACK_BASE_URL}`, data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to send email via email provider: ')
      throw wrapped_err
    })
}
