import {
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  ACTIVE_FAMILIES_BY_TECH_ID,
  COST_BY_COUNTRY_BY_YEAR_ID,
  FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
  FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
  GRANTS_BY_COUNTRY_ID,
  GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  LITIGATED_FAMILIES_BY_PORTFOLIO_ID,
  NUM_FAMILIES_BY_SCORE_BAND,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  LITIGATED_FAMILIES_BY_TECH_ID,
  ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID,
  CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_STATUS_ID,
  GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  FAMILIES_BY_CPC_CODE,
  APPLICATIONS_VS_GRANTS_BY_YEAR_ID,
  EXPIRING_BY_YEAR_BY_ORGANISATION_ID,
  AVERAGE_COST_BY_PORTFOLIO_ID,
  PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID,
  AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID,
  LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  ENTRANCE_YEAR_BY_ORG_BY_TECH_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID,
  FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID,
  AVERAGE_COST_BY_TECH_ID,
  PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID,
  AGE_AT_EXPIRY_BY_TECH_ID,
  PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID,
  COST_BY_TYPE_BY_YEAR_ID,
  FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
} from './spec_ids.js'

import {
  BUBBLE_ID,
  CHOROPLETH_MAP_ID, COLUMN_CLUSTER_ID,
  COLUMN_STACK_ID,
  HEATMAP_ID,
  LINE_ID,
  PIE_ID
} from './view_ids.js'
import { get_as_map } from '../utils/utils.js'

const MAIN_ITEMS_DEFAULT = [
  { spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID, default_view_id: PIE_ID },
  { spec_id: ACTIVE_FAMILIES_BY_TECH_ID, default_view_id: PIE_ID },
  { spec_id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID, default_view_id: HEATMAP_ID },
  { spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID, default_view_id: LINE_ID },
  { spec_id: FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID, default_view_id: BUBBLE_ID },
  { spec_id: FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID, default_view_id: BUBBLE_ID },
  { spec_id: GRANTS_BY_COUNTRY_ID, default_view_id: CHOROPLETH_MAP_ID },
  { spec_id: GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID, default_view_id: HEATMAP_ID },
  { spec_id: LITIGATED_FAMILIES_BY_PORTFOLIO_ID, default_view_id: PIE_ID },
  { spec_id: LITIGATED_FAMILIES_BY_TECH_ID, default_view_id: PIE_ID }
]

const MAIN_ITEMS_SINGLE_ORG_SINGLE_TECH = [
  { spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID, default_view_id: LINE_ID },
  { spec_id: FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID, default_view_id: COLUMN_STACK_ID },
  { spec_id: GRANTS_BY_COUNTRY_ID, default_view_id: CHOROPLETH_MAP_ID },
  { spec_id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID, default_view_id: COLUMN_STACK_ID },
  { spec_id: COST_BY_COUNTRY_BY_YEAR_ID, default_view_id: BUBBLE_ID }
]

const MAIN_ITEMS_SINGLE_ORG_MULTI_TECH = [
  { spec_id: ACTIVE_FAMILIES_BY_TECH_ID, default_view_id: PIE_ID },
  { spec_id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID, default_view_id: HEATMAP_ID },
  { spec_id: FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID, default_view_id: BUBBLE_ID },
  { spec_id: GRANTS_BY_COUNTRY_ID, default_view_id: CHOROPLETH_MAP_ID },
  { spec_id: GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID, default_view_id: HEATMAP_ID },
  { spec_id: LITIGATED_FAMILIES_BY_TECH_ID, default_view_id: PIE_ID }
]

const MAIN_ITEMS_MULTI_ORG_SINGLE_TECH = [
  { spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID, default_view_id: PIE_ID },
  { spec_id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID, default_view_id: HEATMAP_ID },
  { spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID, default_view_id: LINE_ID },
  { spec_id: FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID, default_view_id: BUBBLE_ID },
  { spec_id: GRANTS_BY_COUNTRY_ID, default_view_id: CHOROPLETH_MAP_ID },
  { spec_id: GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID, default_view_id: HEATMAP_ID },
  { spec_id: LITIGATED_FAMILIES_BY_PORTFOLIO_ID, default_view_id: PIE_ID }
]

export const CLASSIFIER_EVALUATION_EXTRAS = [
  // Extra datasets that are useful in Classifier Evaluation reports
  { spec_id: NUM_FAMILIES_BY_SCORE_BAND }
]

export const SINGLE_ORG_SINGLE_TECH_PRESET = {
  id: 'org_single_tech_single',
  main_items: MAIN_ITEMS_SINGLE_ORG_SINGLE_TECH
}

export const MULTI_ORG_MULTI_TECH_PRESET = {
  id: 'org_multi_tech_multi',
  main_items: MAIN_ITEMS_DEFAULT
}

export const SINGLE_ORG_MULTI_TECH_PRESET = {
  id: 'org_single_tech_multi',
  main_items: MAIN_ITEMS_SINGLE_ORG_MULTI_TECH
}

export const MULTI_ORG_SINGLE_TECH_PRESET = {
  id: 'ort_multi_tech_multi',
  main_items: MAIN_ITEMS_MULTI_ORG_SINGLE_TECH
}

export const DEFAULT_PRESET = {
  id: 'default',
  name: 'Automatic',
  desc: 'Automatic selection based on report.'
}

export const COMPETITIVE_INTELLIGENCE_PRESET = {
  id: 'competitive_intelligence',
  name: 'Competitive Intelligence',
  items: [
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID},
    {spec_id: ACTIVE_FAMILIES_BY_TECH_ID},
    {spec_id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID},
    {spec_id: ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID},
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID},
    {spec_id: AVERAGE_COST_BY_PORTFOLIO_ID},
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID},
    {spec_id: AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID},
    {spec_id: LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID},
    {spec_id: PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID},
    {spec_id: ENTRANCE_YEAR_BY_ORG_BY_TECH_ID},
    {spec_id: APPLICATIONS_VS_GRANTS_BY_YEAR_ID}
  ]
}

export const BENCHMARKING_PRESET = {
  id: 'benchmarking',
  name: 'Benchmarking',
  items: [
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID, view_id: COLUMN_STACK_ID},
    {spec_id: CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID},
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID},
    {spec_id: PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID, view_id: HEATMAP_ID},
    {spec_id: PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID},
    {spec_id: AVERAGE_COST_BY_PORTFOLIO_ID},
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_STATUS_ID, view_id: COLUMN_CLUSTER_ID},
    {spec_id: EXPIRING_BY_YEAR_BY_ORGANISATION_ID, view_id: LINE_ID},
    {spec_id: GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID, view_id: LINE_ID},
    {spec_id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID}
  ]
}

export const TECH_TRENDS_PRESET = {
  id: 'tech_trends',
  name: 'Tech Trends',
  items: [
    {spec_id: ACTIVE_FAMILIES_BY_TECH_ID},
    {spec_id: ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID},
    {spec_id: FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID},
    {spec_id: AVERAGE_COST_BY_TECH_ID},
    {spec_id: COST_BY_TYPE_BY_YEAR_ID},
    {spec_id: AVERAGE_COST_BY_PORTFOLIO_ID},
    {spec_id: FAMILIES_BY_CPC_CODE, view_id: PIE_ID},
    {spec_id: AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID},
    {spec_id: PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID},
    {spec_id: AGE_AT_EXPIRY_BY_TECH_ID},
    {spec_id: LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID},
    {spec_id: ENTRANCE_YEAR_BY_ORG_BY_TECH_ID},
    {spec_id: PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID},
    {spec_id: APPLICATIONS_VS_GRANTS_BY_YEAR_ID, view_id: COLUMN_CLUSTER_ID},
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID}
  ]
}

export const CLASSIFIER_EVAL_PRESET = {
  id: 'classifier_eval',
  name: 'Classifier Evaluation',
  items: [
    {spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID},
    {spec_id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID},
    {spec_id: FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID},
    {spec_id: FAMILIES_BY_CPC_CODE},
    {spec_id: NUM_FAMILIES_BY_SCORE_BAND},
  ]
}

export const CIPHER_CHART_SETS = [
  BENCHMARKING_PRESET, COMPETITIVE_INTELLIGENCE_PRESET, TECH_TRENDS_PRESET, CLASSIFIER_EVAL_PRESET
]

export const CIPHER_CHART_SET_BY_NAME = get_as_map(CIPHER_CHART_SETS, 'name')