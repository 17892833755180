import React from 'react'

import { withUser } from '../UserContext.js'
import ReportNameInput from '../ReportNameInput.js'
import MultiLabelOptions from './MultiLabelOptions.js'
import NegativesProcessingOptions from './NegativesProcessingOptions.js'
import { get_negatives_processing } from '../../model/negatives_processing.js'
import ScoreThresholdControl from './ScoreThesholdControl.js'
import { AVAILABLE_SCORE_THRESHOLDS, TECH_PARTITIONING_TYPE_UTT } from '../../model/technology_basket.js'
import OwnerLevelControl from './OwnerLevelControl.js'
import PortfolioRollupLimitControl from './PortfolioRollupLimitControl.js'
import PortfolioCheckboxList from './PortfolioCheckboxList.js'
import { is_poweruser } from '../../utils/user_permissions.js'
import { get_all_groupable_idxs, is_all_group_by_selected } from '../../model/portfolio_basket.js'
import { remove_not_allowed_chars_from_text } from '../../utils/name_utils.js'

const ReportOptions = (
  {
    user,

    portfolios,
    classifiers,
    technology_partitioning,
    utt_version,

    report_name,
    negatives_processing,
    multi_label,
    score_threshold,
    portfolio_roll_up_limit,
    group_by_owner_level,

    on_change_report_name,
    on_change_technology_multi_label,
    on_change_score_threshold,
    on_change_negatives_processing,
    on_change_group_by_owner_level,
    on_change_portfolio_roll_up_limit,
    on_change_group_by_owner_flag,
    on_change_all_portfolio_basket_items,

    is_landscape,
    is_valuation_report,
    is_nd_report,
    is_report_big,

    className
  }) => {

  const groupable_portfolio_idxs = get_all_groupable_idxs(portfolios)
  const has_groupable_portfolios = (groupable_portfolio_idxs || []).length > 0
  const has_all_group_by_selected = is_all_group_by_selected(portfolios)

  const show_owner_level_option = has_groupable_portfolios
  const show_portfolio_rollup_option = is_poweruser(user)
  const show_group_by_options = has_groupable_portfolios && !is_landscape

  const show_any_portfolio_options = !is_valuation_report && (show_owner_level_option || show_portfolio_rollup_option || show_group_by_options)

  const show_multi_label_option = (classifiers || []).length > 1
  const show_negatives_processing_option = !(is_valuation_report || is_landscape)
  const show_score_threshold_option = !is_nd_report && ((technology_partitioning || {}).type !== TECH_PARTITIONING_TYPE_UTT)

  const show_any_technology_setting = (classifiers || []).length > 0 && (show_multi_label_option || show_negatives_processing_option || show_score_threshold_option)

  return (
    <div className={className}>
      <h3>Report options</h3>
      <ReportNameInput
        report_name={report_name}
        on_change={(value) => on_change_report_name(remove_not_allowed_chars_from_text(value))}
      />

      {show_any_technology_setting &&
        <div>
          <h4>Technology settings</h4>

          {show_multi_label_option &&
            <MultiLabelOptions
              technology_multi_label={multi_label}
              update_technology_multi_label={on_change_technology_multi_label}
              className='mb-3'
            />
          }

          {show_negatives_processing_option &&
            <NegativesProcessingOptions
              selected={negatives_processing.type}
              is_nd_report={is_nd_report}
              on_select={(id) => on_change_negatives_processing(get_negatives_processing({ type: id, utt_version }))}

              is_report_big={is_report_big}
              className='mb-3'
            />
          }

          {show_score_threshold_option &&
            <ScoreThresholdControl
              score_threshold_objs={AVAILABLE_SCORE_THRESHOLDS}
              selected_score_threshold_value={score_threshold}
              on_change_score_threshold={on_change_score_threshold}
              user={user}
              className='mb-3'
            />
          }
        </div>
      }

      {show_any_portfolio_options &&
        <div>
          <h4>Organisations</h4>
          {show_owner_level_option &&
            <OwnerLevelControl
              selected_level={group_by_owner_level}
              on_select={on_change_group_by_owner_level}
              className='mb-3'
            />
          }

          {show_portfolio_rollup_option &&
            <PortfolioRollupLimitControl
              portfolio_roll_up_limit={portfolio_roll_up_limit}
              set_portfolio_roll_up_limit={on_change_portfolio_roll_up_limit}
              className='mb-3'
            />
          }

          {show_group_by_options &&
            <div>
              <label>Group by owner:</label>

              <PortfolioCheckboxList
                items={groupable_portfolio_idxs.map(idx => ({ ...portfolios[idx], idx }))}
                selected_items_idxs={groupable_portfolio_idxs.filter(idx => portfolios[idx].group_by_owner === true)}
                on_click_from_item_checkbox={on_change_group_by_owner_flag}
                is_header_checkbox_selected={has_all_group_by_selected}
                on_click_from_header_checkbox={() => on_change_all_portfolio_basket_items(groupable_portfolio_idxs, 'group_by_owner', !has_all_group_by_selected)}
              />

            </div>
          }
        </div>
      }
    </div>
  )
}

export default withUser(ReportOptions)
