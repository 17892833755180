import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import {
  get_technology_partitioning_by_classifiers,
  get_technology_partitioning_by_clustering,
  get_technology_partitioning_by_utt,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT
} from '../../model/technology_basket.js'
import Tooltip from '../widgets/Tooltip.js'
import { InfoIcon } from '../widgets/IconSet.js'
import {
  enable_tech_partitioning_by_classifiers,
  enable_tech_partitioning_by_clustering,
  enable_tech_partitioning_by_super_utt,
  enable_tech_partitioning_by_utt
} from '../../utils/report_builder_utils.js'
import { save_to_local_storage } from '../../utils/local_storage_utils.js'
import {TECH_SPLIT_TYPE_LS_KEY} from '../../constants/report_builder.js'

const TechnologySelector = (
  {
    technology_partitioning,
    on_change_technology_partitioning,
    selected_classifiers,

    has_classifiers,
    is_nd_report,
    is_valuation_report,
    is_landscape,

    utt_version,
    is_report_big,
    is_report_too_big,
    className
  }) => {

  const {type: selected_type, use_superclasses} = technology_partitioning || {}

  function change_partitioning_and_update_ls(selected_partitioning) {
    if (!(is_nd_report || is_valuation_report)) {
      const {type: new_type, use_superclasses} = selected_partitioning || {}
      save_to_local_storage(TECH_SPLIT_TYPE_LS_KEY, { type: new_type, ...use_superclasses != null ? { use_superclasses } : {} })
    }

    on_change_technology_partitioning(selected_partitioning)
  }

  return (
    <div className={cn('d-flex pt-3', className)}>
      {enable_tech_partitioning_by_classifiers({has_classifiers}) &&
        <RadiobuttonWithLabel
          is_checked={selected_type === TECH_PARTITIONING_TYPE_CLASSIFIER}
          label='Classifiers'
          on_click={() => change_partitioning_and_update_ls(get_technology_partitioning_by_classifiers())}
          className='me-2'
          is_disabled={is_landscape && (selected_classifiers || []).length > 0 && _.some(selected_classifiers || [], item => item.is_utt)}
        />
      }

      {enable_tech_partitioning_by_utt({is_report_too_big, is_nd_report, is_valuation_report}) &&
        <RadiobuttonWithLabel
          is_checked={selected_type === TECH_PARTITIONING_TYPE_UTT && !use_superclasses}
          label={(
            <span className='d-flex'>
              <span className='me-1'>UTT</span>
              <Tooltip toggler={(<span><InfoIcon /></span>)}>
                Each patent family in Classification falls into exactly one Universal Technology Taxonomy (UTT) class.
              </Tooltip>
            </span>
          )}
          on_click={() => change_partitioning_and_update_ls(get_technology_partitioning_by_utt({ utt_version }))}
          className='me-2'
          is_disabled={is_landscape && (selected_classifiers || []).length > 0 && _.some(selected_classifiers || [], item => !item.is_utt)}
        />
      }

      {enable_tech_partitioning_by_super_utt({is_report_too_big, is_nd_report, is_valuation_report, is_landscape}) &&
        <RadiobuttonWithLabel
          is_checked={selected_type === TECH_PARTITIONING_TYPE_UTT && use_superclasses}
          label={(
            <span className='d-flex'>
              <span className='me-1'>UTT superclasses</span>
              <Tooltip toggler={(<span><InfoIcon /></span>)}>
                Each patent family in Classification falls into exactly one Universal Technology Taxonomy (UTT) superclass.
              </Tooltip>
            </span>
          )}
          on_click={() => change_partitioning_and_update_ls(get_technology_partitioning_by_utt({
            use_utt_superclasses: true,
            utt_version
          }))}
          className='me-2'
        />
      }

      {enable_tech_partitioning_by_clustering({is_report_big, is_nd_report, is_valuation_report, is_landscape}) &&
        <RadiobuttonWithLabel
          is_checked={selected_type === TECH_PARTITIONING_TYPE_CLUSTERING}
          label={(
            <span className='d-flex'>
              <span className='me-1'>Clustering</span>
              <Tooltip toggler={(<span><InfoIcon /></span>)}>
                Technology Clustering dynamically groups patent families per report.
              </Tooltip>
            </span>
          )}
          on_click={() => change_partitioning_and_update_ls(get_technology_partitioning_by_clustering())}
          className='me-2'
        />
      }

      {is_report_big && !(is_nd_report || is_valuation_report) &&
        <RadiobuttonWithLabel
          is_checked={selected_type === TECH_PARTITIONING_TYPE_CLUSTERING}
          label='No classification'
          on_click={() => on_change_technology_partitioning(get_technology_partitioning_by_clustering())}
          className='me-2'
        />
      }
    </div>

  )
}

export default TechnologySelector