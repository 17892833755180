import _ from 'underscore'

import { get_as_map } from '../utils/utils.js'
import { DEFAULT_UTT_VERSION, UTT_VERSION_IDS } from './utt_version.js'

export const TECH_PARTITIONING_TYPE_CLUSTERING = 'clustering'
export const TECH_PARTITIONING_TYPE_CLASSIFIER = 'classifier'
export const TECH_PARTITIONING_TYPE_CUSTOM     = 'custom'
export const TECH_PARTITIONING_TYPE_MULTICLASS = 'multiclass'
export const TECH_PARTITIONING_TYPE_UTT        = 'utt'

export const TECH_PARTITIONING_TYPES = [
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CUSTOM,
  TECH_PARTITIONING_TYPE_MULTICLASS,
  TECH_PARTITIONING_TYPE_UTT
]

export const AVAILABLE_SCORE_THRESHOLDS = [
  {value: 0.1, label: 'No limit', only_poweruser: true},
  {value: 0.2, label: '0.2',      only_poweruser: true},
  {value: 0.3, label: '0.3',      only_poweruser: true},
  {value: 0.4, label: '0.4'},
  {value: 0.5, label: '0.5'},
  {value: 0.6, label: '0.6'},
  {value: 0.7, label: '0.7'}
]
export const AVAILABLE_SCORE_THRESHOLDS_BY_VALUE = get_as_map(AVAILABLE_SCORE_THRESHOLDS, 'value')

export function get_technology_partitioning_by_utt({use_utt_superclasses, utt_version, utt_class_ids}) {
  const version_id = utt_version && _.contains(UTT_VERSION_IDS, utt_version) ? utt_version : DEFAULT_UTT_VERSION

  return {
    type: TECH_PARTITIONING_TYPE_UTT,
    version_id,

    ...utt_class_ids && _.isArray(utt_class_ids) ? {class_ids: utt_class_ids} : {},
    ...use_utt_superclasses ? {use_superclasses: use_utt_superclasses} : {}
  }
}

export function get_technology_partitioning_by_classifiers() {
  return {type: TECH_PARTITIONING_TYPE_CLASSIFIER}
}

export function get_technology_partitioning_by_clustering() {
  return { type: TECH_PARTITIONING_TYPE_CLUSTERING }
}

export function get_technology_partitioning_by_manual_clustering(technologies) {
  return {
    type: TECH_PARTITIONING_TYPE_CUSTOM,
    technologies
  }
}

export function is_report_with_clustered_techs({tech_partitioning_type}) {
  return is_selected_tech_partitioning_type(tech_partitioning_type, TECH_PARTITIONING_TYPE_CLUSTERING)
}

export function is_report_with_classifiers({tech_partitioning_type}) {
  return is_selected_tech_partitioning_type(tech_partitioning_type, TECH_PARTITIONING_TYPE_CLASSIFIER)
}

export function is_report_with_multiclass({tech_partitioning_type}) {
  return (is_selected_tech_partitioning_type(tech_partitioning_type, TECH_PARTITIONING_TYPE_MULTICLASS) ||
    is_selected_tech_partitioning_type(tech_partitioning_type, TECH_PARTITIONING_TYPE_UTT))
}

export function is_report_with_custom_clustered_techs({tech_partitioning_type}) {
  return is_selected_tech_partitioning_type(tech_partitioning_type, TECH_PARTITIONING_TYPE_CUSTOM)
}

function is_selected_tech_partitioning_type(tech_partitioning_type, selected_tech_partitioning_type) {
  return _.isEqual(tech_partitioning_type, selected_tech_partitioning_type)
}

export function get_selected_classifiers_from_classifier_sources(classifier_sources) {
  if ((classifier_sources || []).length === 0) return null

  return classifier_sources.map(item => {
    const {id, classifier_id} = item || {}

    return classifier_id != null ? item : {...item, classifier_id: id}
  })
}