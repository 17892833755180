import React from 'react'
import _ from 'underscore'
import { createTheme } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import {
  PS_INPUT_HIGHLIGHT_BLUE,
  PS_LIGHT_BLUE_HIGHLIGHT,
  PS_INPUT_FOCUS_BORDER_COLOUR,
  PS_INPUT_HOVER_BORDER_COLOUR,
  PS_LABEL_TEXT_COLOUR,
  PS_PLACEHOLDER_TEXT,
  PS_ICON_GREY,
  PS_ICON_HOVER_HALO,
  PS_SEE_THRU_ACTIVE_BLUE,
  PS_CONTROL_DISABLED,
  PS_CONTROL_DISABLED_CHECKED,
  PS_TABLE_ROW_BG_EVEN,
  PS_GLOBAL_TEXT_COLOUR,
  PS_GREY_BORDER,
  PS_CHIP_HOVER_BORDER_COLOUR,
  PS_CHIP_ACTIVE_BORDER_COLOUR,
  PS_CHIP_FOCUS_BORDER_COLOUR,
  DARK_TEXT_COLOUR,
  PS_BLUE_GREY_BG_1,
  PS_LINK_TEXT_COLOUR,
  PS_LINK_TEXT_HOVER
} from './colours.js'
import {
  PS_CHIP_ACTIVE_OUTLINE,
  PS_INPUT_BORDER_DEFAULT,
  PS_INPUT_FOCUS_OUTLINE,
  PS_INPUT_HEIGHT
} from './layout.js'
import { RemoveIcon } from '../components/widgets/IconSet.js'
import {
  EXTRA_LARGE,
  EXTRA_SMALL,
  FOUR_EXTRA_LARGE,
  LARGE,
  MEDIUM,
  SMALL,
  THREE_EXTRA_LARGE,
  TWO_EXTRA_LARGE,
  TWO_EXTRA_SMALL
} from './screen_breakpoints.js'

LicenseInfo.setLicenseKey(
  '1c32ca46b922a9afb3550fb154e1814bT1JERVI6MzQxNjYsRVhQSVJZPTE2NzA3NjQ4MzgwMDAsS0VZVkVSU0lPTj0x',
)

// MUI has a set of default Z-values, see https://mui.com/material-ui/customization/z-index/
// Here we increase them all by 10000 (so that modals appear over our header etc)
const DEFAULT_Z_INDEX = {
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500
}
const CUSTOM_Z_INDEX = _.mapObject(DEFAULT_Z_INDEX, (val) => val + 10000)

export const mui_theme = createTheme({
  typography: {
    fontFamily: [
      '"Lato"',
    ].join(','),
  },

  palette: {
    primary: {
      main: PS_INPUT_HIGHLIGHT_BLUE
    }
  },

  breakpoints: {
    //following bootstrap breakpoints
    values: {
      [TWO_EXTRA_SMALL]: 0,
      [EXTRA_SMALL]: 356, //made up as not in bootstrap
      [SMALL]: 576,
      [MEDIUM]: 768,
      [LARGE]: 992,
      [EXTRA_LARGE]: 1200,
      [TWO_EXTRA_LARGE]: 1400,
      [THREE_EXTRA_LARGE]: 1600, //made up as not in bootstrap
      [FOUR_EXTRA_LARGE]: 1800 //made up as not in bootstrap
    }
  },

  zIndex: CUSTOM_Z_INDEX,

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          // avoid inconsistent dropshadowing
          boxShadow: 'none !important',
          border: '1px solid ' + PS_GREY_BORDER
        }
      }
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none !important',
          fontSize: '16px',
        },

        iconButtonContainer: {
          button: {
            padding: '0 !important',
            ':hover': {
              backgroundColor: `transparent`,
            },
          }
        },

        row: {
          '&.even': {
            backgroundColor: `${PS_TABLE_ROW_BG_EVEN} !important`,
          },
          ':hover': {
            // blue halo on hover
            backgroundColor: `${PS_LIGHT_BLUE_HIGHLIGHT} !important`,
          },
        },

        columnHeader: {
          borderBottom: `1px solid ${PS_GREY_BORDER} !important`,
          borderRight: 'none !important',
        },

        columnHeaderTitle: {
          fontWeight: '700 !important',
        },

        cell: {
          border: 'none !important',
          borderBottom: `1px solid ${PS_GREY_BORDER} !important`,
          padding: '0.5rem 0.75rem !important',
          fontSize: '16px',
          lineHeight: '1.8rem',
          color: PS_GLOBAL_TEXT_COLOUR,
          fontWeight: 400,
        },

        paper: {
          marginLeft: '6rem'
        },

        panelFooter: {
          padding: '8px',
          button: {
            color: DARK_TEXT_COLOUR,
            border: '2px solid transparent',
            fontWeight: 600,
            backgroundColor: 'transparent',
            paddingLeft: '0.8rem',
            paddingRight: '1rem',
            ':hover': {
              backgroundColor: PS_BLUE_GREY_BG_1
            },
            ':focus': {
              borderColor: PS_INPUT_FOCUS_BORDER_COLOUR
            }
          }
        },

        filterForm: {
          label: {
            //display: 'none',
            color: PS_GLOBAL_TEXT_COLOUR + ' !important'
          },

          button: {
            color: PS_LINK_TEXT_COLOUR + ' !important',
            fontSize: '16px',
            ':hover': {
              color: PS_LINK_TEXT_HOVER + ' !important',
              backgroundColor: 'transparent'
            }
          }
        },

        filterFormDeleteIcon: {
          alignSelf: 'end',
          paddingBottom: '0.3rem',
          paddingRight: '0.1rem',
        },
        filterFormColumnInput: {
          padding: '0.5rem 1rem 0 0',
          minWidth: 180
        },
        filterFormOperatorInput: {
          padding: '0.5rem 1rem 0 0',
          minWidth: 160
        },
        filterFormValueInput: {
          padding: '0.5rem 0 0 0'
        },
        filterFormLinkOperatorInput: {
          padding: '0.5rem 1rem 0 0',
          minWidth: '95px'
        }
      }
    },

    // base class for dropdown/ popper menus like classifier actions in training set list
    MuiList: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          padding: '4px'
        }
      }
    },

    // items in mui lists/ popper menus
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontSize: '15px',
          padding: '8px 12px 8px 8px',
          color: PS_LABEL_TEXT_COLOUR,
          background: 'none !important',
          justifyContent: 'space-between !important',
          textTransform: 'none !important',
          "&[aria-selected ='true']": {
            '&::after': {
              display: 'inline',
              content: '""',
              width: '0.5rem',
              height: '1rem',
              marginLeft: '0.5rem',
              borderBottom: `1px solid ${PS_LABEL_TEXT_COLOUR}`,
              borderRight: `1px solid ${PS_LABEL_TEXT_COLOUR}`,
              transform: 'rotate(45deg)',
              msTransform: 'rotate(45deg)'
            },
          },
          ':hover': {
            backgroundColor: `${PS_LIGHT_BLUE_HIGHLIGHT} !important`,
          },
        }
      }
    },

    // native text label for form inputs, autocomplete
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: PS_LABEL_TEXT_COLOUR + ' !important',
          transform: 'none !important',
          position: 'relative !important',
          fontSize: '15px',
          fontWeight: '700',
          lineHeight: '18px'
        }
      }
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        },
        sizeSmall: {
          lineHeight: '16px',
          fontSize: '12px'
        }
      }
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          // override default uppercase button text
          textTransform: 'capitalize !important'
        }
      }
    },

    // toggle switch, used for on-off states
    MuiSwitch: {
      defaultProps: {
        // medium switch is the default à la PS
        // if we want to use small or large switches later we may need to add adjustments to size and padding for them
        size: 'medium'
      },
      styleOverrides: {
        switchBase: {
          // halo size
          padding: '4px',
          ':hover': {
            // blue halo on hover
            backgroundColor: `${PS_SEE_THRU_ACTIVE_BLUE} !important`,
          },
          '&.Mui-disabled': {
            // thumb buttons should be white when disabled and unchecked
            color: 'white',
          },
          '&.Mui-checked.Mui-disabled': {
            // thumb buttons should be grey-blue when disabled and checked
            color: PS_CONTROL_DISABLED_CHECKED,
            opacity: 1
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: PS_CONTROL_DISABLED,
            opacity: 1
          }
        },
        track: {
          borderRadius: '10px'
        },
        sizeMedium: {
          // size adjustments to make the medium switch's proportions match PS style
          width: '48px',
          height: '28px',
          padding: '7px 10px',
        }
      }
    },

    // used for autocomplete, inputs
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: PS_LABEL_TEXT_COLOUR + ' !important',
          outline: 'none',
          margin: '1px',
          border: PS_INPUT_BORDER_DEFAULT,
          height: PS_INPUT_HEIGHT,
          ':hover': {
            borderColor: PS_INPUT_HOVER_BORDER_COLOUR
          },
          ':focus-within': {
            // for autocomplete, this is applied to the input while the dropdown is open
            borderColor: PS_INPUT_HOVER_BORDER_COLOUR
          },
          ':focus': {
            // the :focus prop has no effect on autocomplete fields, but possibly works for other inputs?
            borderColor: PS_INPUT_FOCUS_BORDER_COLOUR,
            outline: PS_INPUT_FOCUS_OUTLINE
          },
          ':active': {
            borderColor: PS_INPUT_FOCUS_BORDER_COLOUR,
            outline: PS_INPUT_FOCUS_OUTLINE
          },
          padding: '0 !important',
        },
        sizeSmall: {
          paddingTop: '4px !important'
        },
        notchedOutline: {
          // without this we get an extra, ugly border
          border: 'none'
        },
        input: {
          '&::placeholder': {
            color: PS_PLACEHOLDER_TEXT + ' !important',
            opacity: '1 !important'
          }
        }
      }
    },

    // autocomplete searchable dropdown, used in classifiers UI, tagging etc
    MuiAutocomplete: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        popupIndicator: {
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
        clearIndicator: {
          borderRadius: '50%'
        },
        listbox: {
          // keeping this value small helps avoid flickering on open (mui bug with longer lists)
          maxHeight: '18rem'
        },
        option: {
          color: PS_LABEL_TEXT_COLOUR + ' !important',
          background: 'none !important',
          margin: '0 6px',
          borderRadius: '4px',
          justifyContent: 'space-between !important',
          // styles for already-selected item(s)
          "&[aria-selected ='true']": {
            '&::after': {
              display: 'inline',
              content: '""',
              width: '0.5rem',
              height: '1rem',
              marginLeft: '0.5rem',
              borderBottom: `1px solid ${PS_LABEL_TEXT_COLOUR}`,
              borderRight: `1px solid ${PS_LABEL_TEXT_COLOUR}`,
              transform: 'rotate(45deg)',
              msTransform: 'rotate(45deg)'
            },
          },
          ':hover': {
            backgroundColor: PS_LIGHT_BLUE_HIGHLIGHT + ' !important'
          }
        },
        popper: {
          top: '4px !important',
          borderRadius: '4px',
          outline: '1px solid #455A64',
          boxShadow: 'none',
          fontWeight: 'normal'
        },

      },
    },

    MuiTreeItem: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          fontSize: '15px'
        },
        label: {
          padding: 0,
          fontSize: '15px',
          color: PS_LABEL_TEXT_COLOUR
        },
        content: {
          marginBottom: '0.4rem',
          backgroundColor: 'transparent',
          ':hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important'
          },
          '&.Mui-focused': {
            backgroundColor: 'transparent !important'
          }
        }
      }
    },

    MuiChip: {
      defaultProps: {
        deleteIcon: <div><RemoveIcon /></div>,
        size: 'small',
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          color: PS_LABEL_TEXT_COLOUR + ' !important',
          fontWeight: '700',
          backgroundColor: 'white',
          border: PS_INPUT_BORDER_DEFAULT,
          ':hover': {
            // use hover styles whether or not the chip is clickable
            borderColor: PS_CHIP_HOVER_BORDER_COLOUR
          }
        },
        clickable: {
          // styles applicable to clickable chips only
          ':active': {
            borderColor: PS_CHIP_ACTIVE_BORDER_COLOUR,
            outline: PS_CHIP_ACTIVE_OUTLINE,
            boxShadow: 'none'
          },
          ':focus': {
            borderColor: PS_CHIP_FOCUS_BORDER_COLOUR,
            outline: 'none !important'
          }
        },
        deleteIcon: {
          color: PS_ICON_GREY,
          backgroundColor: 'transparent',
          textAlign: 'center',
          alignSelf: 'center',
          marginRight: '1px',
          ':hover': {
            color: PS_ICON_GREY,
            backgroundColor: PS_ICON_HOVER_HALO,
          }
        },
        sizeSmall: {
          lineHeight: '16px',
          fontSize: '12px',
          height: '24px'
        },
        label: {
          paddingLeft: '6px',
          textTransform: 'none',
          maxWidth: '94%' // ensure we always have space to show the delete icon
        },
        deleteIconSmall: {
          fontSize: '9px',
          marginRight: '3px',
          width: '15px',
          height: '15px',
          borderRadius: '15px'
        },
        sizeMedium: {
          // do we want these larger size chips for anything? would need to add corresponding sizes for deleteIcon etc
          height: '40px'
        }
      }
    }
  }
})