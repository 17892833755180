import React from 'react'

import { PORTFOLIO_SEARCH_TYPE_BY_ID } from '../../constants/report_builder.js'
import { can_view_tagged_families } from '../../utils/user_permissions.js'
import PortfolioSearchNavigationItem from './PortfolioSearchNavigationItem.js'
import { withUser } from '../UserContext.js'
import { NavWrapper } from '../widgets/NavTab.js'

function can_display_tags({user, is_nd_report}) {
  return !is_nd_report && user && can_view_tagged_families(user)
}

function can_display_org_search_nav({is_valuation_report}) {
  return !is_valuation_report
}

function can_display_family_search_nav({is_nd_report, is_valuation_report}) {
  return !(is_nd_report || is_valuation_report)
}

function can_display_landscape_nav({is_nd_report, is_valuation_report}) {
  return !(is_nd_report || is_valuation_report)
}

const NAV_ITEMS = [
  { item: PORTFOLIO_SEARCH_TYPE_BY_ID.landscape,       should_disable: (org_group_started) => {return org_group_started}, can_display: can_display_landscape_nav },
  { item: PORTFOLIO_SEARCH_TYPE_BY_ID.org_search,      should_disable: () => {return false},                     can_display: can_display_org_search_nav },
  { item: PORTFOLIO_SEARCH_TYPE_BY_ID.families_search, should_disable: (org_group_started) => {return org_group_started}, can_display: can_display_family_search_nav },
  { item: PORTFOLIO_SEARCH_TYPE_BY_ID.tag_sets,        should_disable: (org_group_started) => {return org_group_started}, can_display: can_display_tags },
  { item: PORTFOLIO_SEARCH_TYPE_BY_ID.upload,          should_disable: (org_group_started) => {return org_group_started} }
]

const PortfolioSearchNavigation = ({user, on_change, is_nd_report, is_valuation_report, org_group_started, active, disabled_options, className}) =>  {
  function get_total_items_count() {
    if (is_nd_report) return 2
    if (is_valuation_report) {
      return can_view_tagged_families(user) ? 2 : 1
    }

    return 4
  }

  function get_active_tab() {
    if (active) return active

    return is_valuation_report ? PORTFOLIO_SEARCH_TYPE_BY_ID.upload : PORTFOLIO_SEARCH_TYPE_BY_ID.org_search
  }

  const active_nav = get_active_tab()
  const total_items = get_total_items_count()
  const can_display_param = {user, is_nd_report, is_valuation_report}

  return (
    <NavWrapper className={className}>
      {NAV_ITEMS.map((nav_item, i) => {
        const {item, should_disable, can_display} = nav_item

        const {id} = item || {}

        if (can_display && !can_display(can_display_param)) return null

        return (
          <PortfolioSearchNavigationItem
            key={i}
            item={item}
            active={active_nav}
            on_click={on_change}
            total_items={total_items}
            disabled={should_disable(org_group_started) || ((disabled_options || []).indexOf(id) !== -1)}
          />
        )
      })}
    </NavWrapper>
  )
}

export default withUser(PortfolioSearchNavigation)