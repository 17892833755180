import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

import { KNN } from '../../constants/paths.js'
import { KNN_SEARCH } from '../classifiers_editor/constants/classifier_paths.js'
import { get_path_to_classifier_ui } from '../../utils/report_utils.js'
import { create_cipher_family_id_from_family_id } from '../../utils/family_view_utils.js'
import {
  COUNT,
  FAMILY_ID,
  get_default_classifier_builder_count_setting,
  get_default_count_setting
} from '../../utils/knn_search.js'
import { ShowSimilarIcon } from '../widgets/IconSet.js'
import {
  check_is_eval_report_with_access,
  check_is_in_classifiers_ui,
  get_training_set_id_from_url
} from '../../utils/custom_classifiers_utils.js'
import { withUser } from '../UserContext.js'
import { is_creator } from '../../utils/user_permissions.js'

import s from './SimilarFamiliesLink.module.scss'

/**
 * A button for navigating to the "SimilarFamilies" page, pre-populated for a particular patent family.
 *
 * By default, clicking it opens a new tab - except for in the ClassifiersUI (although user can still right-click to open a new tab).
 * ClassifiersUI is detected by the URL location.
 *
 * By default, the destination page is the standalone SimilarFamilies page, except for:
 * - ClassifiersUI: page within the ClassifiersUI.
 * - Eval reports where user has access to the underlying classifier: page within the ClassifiersUI.
 */
const SimilarFamiliesLink = (
  {
    location,
    className,
    user,

    patfam_id,

    eval_training_set_id, // Optional: non-null in the ClassifiersUI context, or for Eval reports
    eval_classifier_data, // Optional: non-null in the ClassifiersUI context, or for Eval reports where the user has access to the underlying classifier.
  }) => {


  const cipher_family_id = create_cipher_family_id_from_family_id(patfam_id)


  function get_base_path() {
    const is_in_classifiers_ui= check_is_in_classifiers_ui(location)
    const is_eval_report_with_access = check_is_eval_report_with_access(eval_training_set_id, eval_classifier_data)

    // Just in case we are in the ClassifiersUI context, and training set id has not been passed in (should not happen but)...
    // ... try extracting training_set_id from the url
    const extracted_training_set_id = is_in_classifiers_ui ? get_training_set_id_from_url(location) : null
    const eval_training_set_id_or_fallback = eval_training_set_id || extracted_training_set_id

    const is_use_classifiers_knn_page = is_in_classifiers_ui || is_eval_report_with_access

    if (is_use_classifiers_knn_page && (eval_training_set_id_or_fallback != null)) {
      return`${get_path_to_classifier_ui(eval_training_set_id_or_fallback)}/${KNN_SEARCH}`
    }

    return  KNN
  }

  const url = `${get_base_path()}?${FAMILY_ID}=${cipher_family_id}&${COUNT}=${check_is_in_classifiers_ui(location) ? get_default_classifier_builder_count_setting() : get_default_count_setting()}`

  if (!is_creator(user)) {
    return null
  }

  return (
    <Link
      className={cn(s.block, className)}
      {...(!check_is_in_classifiers_ui(location) ? { target: '_blank' } : {})} // Open in new tab, except for when in ClassifiersUI context
      to={url}
      title='Search similar families'
      onClick={e => e.stopPropagation()}
    >
      <ShowSimilarIcon />
    </Link>
  )
}

export default withRouter(withUser(SimilarFamiliesLink))