import { get_as_map } from '../utils/utils.js'
import { CN, EP, IP5_COUNTRIES, JP, KR, UPC_COUNTRIES, US } from './countries.js'
import { has_us_centric_territories } from '../utils/user_permissions.js'

export const REPORT_BUILDER_DATE_FORMAT = 'YYYY-MM-DD'

export const ACCESSION_DATE_PORTFOLIO_ITEM = {
  date_format: REPORT_BUILDER_DATE_FORMAT,
  prefix: 'accession_date:[',
  suffix: ']',
  connector: ' TO '
}

export const LANDSCAPE_PORTFOLIO_CONNECTOR = ' AND '

export const GROUP_BY_OWNER_LEVEL_LS_KEY = 'group_by_owner_level'
export const PORTFOLIO_ROLLUP_LIMIT_LS_KEY='portfolio_rollup_limit'
export const SCORE_THRESHOLD_LS_KEY='score_threshold'
export const TECH_SPLIT_TYPE_LS_KEY='tech_split'

export const PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID = 'org_search'
export const PORTFOLIO_SEARCH_TYPE_FAMILIES_SEARCH_ID = 'families_search'
export const PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID = 'upload'
export const PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID = 'landscape'
export const PORTFOLIO_SEARCH_TYPE_ORG_SETS_ID = 'org_sets' //used in previous versions of the portfolio basket
export const PORTFOLIO_SEARCH_TYPE_TAG_SETS_ID = 'tag_sets'

export const PORTFOLIO_SEARCH_TYPE = [
  {id: PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID, title: 'Landscape', short_title: 'Landscape'},
  {id: PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID, title: 'Organisation search', short_title: 'Org search'},
  {id: PORTFOLIO_SEARCH_TYPE_FAMILIES_SEARCH_ID, title: 'Boolean search', short_title: 'Boolean search'},
  {id: PORTFOLIO_SEARCH_TYPE_TAG_SETS_ID, title: 'Tagged families', short_title: 'Tags'},
  {id: PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID, title: 'Patent upload', short_title: 'Patent upload'},
]

export const NON_LANDSCAPE_PORTFOLIO_SEARCH_TYPES = [
  PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID,
  PORTFOLIO_SEARCH_TYPE_FAMILIES_SEARCH_ID,
  PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID,
  PORTFOLIO_SEARCH_TYPE_TAG_SETS_ID
]
export const LANDSCAPE_PORTFOLIO_SEARCH_TYPES = [PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID]

export const PORTFOLIO_SEARCH_TYPE_BY_ID = get_as_map(PORTFOLIO_SEARCH_TYPE, 'id')

export const REPORT_BUILDER_MODE_ID_PORTFOLIO = 'portfolio'
export const REPORT_BUILDER_MODE_ID_TECHNOLOGY = 'technology'
export const REPORT_BUILDER_MODE_ID_OPTIONS = 'options'

const REPORT_BUILDER_MODE = [
  {id: REPORT_BUILDER_MODE_ID_PORTFOLIO,  title: 'Portfolio search'},
  {id: REPORT_BUILDER_MODE_ID_TECHNOLOGY, title: 'Technology filter'},
  {id: REPORT_BUILDER_MODE_ID_OPTIONS,    title: 'Options'}
]

export const REPORT_BUILDER_MODE_BY_ID = get_as_map(REPORT_BUILDER_MODE, 'id')

export const CACHED_REPORT_THRESHOLD_RIGID = 'rigid'
export const CACHED_REPORT_THRESHOLD_STRICT = 'strict'
export const CACHED_REPORT_THRESHOLD_RELAXED = 'relaxed'

const CACHED_REPORT_THRESHOLDS = [
  {id: [CACHED_REPORT_THRESHOLD_RIGID], data_version_difference: 0},
  {id: [CACHED_REPORT_THRESHOLD_STRICT], age_in_days: 14, data_version_difference: 2},
  {id: [CACHED_REPORT_THRESHOLD_RELAXED], age_in_days: 28, data_version_difference: 4}
]

export const CACHED_REPORT_THRESHOLDS_BY_ID = get_as_map(CACHED_REPORT_THRESHOLDS, 'id')

export const PORTFOLIO_SIZE_WARNING_THRESHOLD = 2000000
export const PORTFOLIO_SIZE_NO_PASS_THRESHOLD = 4500000

export const CLASSIFIER_LANDSCAPE_LIMIT = 25
export const AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT = 50

export const ALL_FAMILIES_LANDSCAPE_OPTION_ID = 'all_families'

const ALL_FAMILIES_LANDSCAPE_OPTION    = { id: ALL_FAMILIES_LANDSCAPE_OPTION_ID,  name: 'All families' }
const ACTIVE_FAMILIES_LANDSCAPE_OPTION = { id: 'active_families', name: 'Active families', search_phrase: 'ST:(granted OR pending)' }
const EXCLUDE_CHINA_LANDSCAPE_OPTION   = { id: 'exclude_cn', name: 'Exclude China only', search_phrase: `NOT OIT:${CN}`, check_if_available: ({user}) => has_us_centric_territories(user) }

const ALL_FAMILIES_IP5 = { id: 'all_ip5', name: 'All families in IP5 countries', short_name: 'IP5 countries', search_phrase: `EEIT:(${IP5_COUNTRIES.join(' OR ')})` }
const ALL_FAMILIES_UPC = { id: 'all_upc', name: 'All families in UPC countries', short_name: 'UPC countries', search_phrase: `EEIT:(${UPC_COUNTRIES.join(' OR ')})` }
const ALL_FAMILIES_US =  { id: 'all_us',  name: 'All families in US',            short_name: 'US',            search_phrase: `EEIT:${US}` }

export const ACTIVE_FAMILIES_GEO_OPTIONS = [
  { id: 'active_us',  name: 'Active families in US',              short_name: 'US',            search_phrase: `GIT:${US} OR PIT:${US}` },
  { id: 'active_cn',  name: 'Active families in China',           short_name: 'China',         search_phrase: `GIT:${CN} OR PIT:${CN}` },
  { id: 'active_jp',  name: 'Active families in Japan',           short_name: 'Japan',         search_phrase: `GIT:${JP} OR PIT:${JP}` },
  { id: 'active_kr',  name: 'Active families in Korea',           short_name: 'Korea',         search_phrase: `GIT:${KR} OR PIT:${KR}` },
  { id: 'active_epo', name: 'Active families in EPO',             short_name: 'EPO',           search_phrase: `GIT:${EP} OR PIT:${EP}` },
  { id: 'active_ip5', name: 'Active families in IP5 countries',   short_name: 'IP5 countries', search_phrase: `GIT:(${IP5_COUNTRIES.join(' OR ')}) OR PIT:(${IP5_COUNTRIES.join(' OR ')})` },
  { id: 'active_upc', name: 'Active families in UPC countries',   short_name: 'UPC countries', search_phrase: `GIT:(${UPC_COUNTRIES.join(' OR ')}) OR PIT:(${UPC_COUNTRIES.join(' OR ')})` },
]

export const ALL_FAMILIES_GEO_OPTIONS = [
  ALL_FAMILIES_US,
  { id: 'all_cn',  name: 'All families in China',         short_name: 'China',         search_phrase: `EEIT:${CN}` },
  { id: 'all_jp',  name: 'All families in Japan',         short_name: 'Japan',         search_phrase: `EEIT:${JP}` },
  { id: 'all_kr',  name: 'All families in Korea',         short_name: 'Korea',         search_phrase: `EEIT:${KR}` },
  { id: 'all_epo', name: 'All families in EPO',           short_name: 'EPO',           search_phrase: `EEIT:${EP}` },
  ALL_FAMILIES_IP5,
  ALL_FAMILIES_UPC,
]

const ALL_LANDSCAPES = [
  ALL_FAMILIES_LANDSCAPE_OPTION,
  ACTIVE_FAMILIES_LANDSCAPE_OPTION,
  EXCLUDE_CHINA_LANDSCAPE_OPTION,
  ...ALL_FAMILIES_GEO_OPTIONS,
  ...ACTIVE_FAMILIES_GEO_OPTIONS
]

export const SEARCH_PHRASE_TO_LANDSCAPE = get_as_map(ALL_LANDSCAPES.filter(item => item.search_phrase != null), 'search_phrase')

export const LANDSCAPE_GROUPS = [
  { ...ALL_FAMILIES_LANDSCAPE_OPTION, options: ALL_FAMILIES_GEO_OPTIONS },
  { ...ACTIVE_FAMILIES_LANDSCAPE_OPTION,  options: ACTIVE_FAMILIES_GEO_OPTIONS },
  EXCLUDE_CHINA_LANDSCAPE_OPTION
]

export const ID_TO_LANDSCAPE_GROUP = get_as_map(LANDSCAPE_GROUPS, 'id')

export const OPTION_ID_TO_LANDSCAPE_GROUP_ID = LANDSCAPE_GROUPS.reduce((acc, item) => {
  const { options=[] } = item
  options.forEach(option => {
    acc[option.id] = item.id
  })
  return acc
}, {})

const ACCESSION_DATE_CUT_OFF_30_DAYS_OPTION = 30
const ACCESSION_DATE_CUT_OFF_60_DAYS_OPTION = 60
const ACCESSION_DATE_CUT_OFF_90_DAYS_OPTION = 90

export const ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION = ACCESSION_DATE_CUT_OFF_30_DAYS_OPTION

export const ACCESSION_DATE_CUT_OFF_OPTIONS = [
  ACCESSION_DATE_CUT_OFF_30_DAYS_OPTION,
  ACCESSION_DATE_CUT_OFF_60_DAYS_OPTION,
  ACCESSION_DATE_CUT_OFF_90_DAYS_OPTION
]
