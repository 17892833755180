import React, { Component } from 'react'
import _ from 'underscore'
import qs from 'query-string'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'

import { DEBOUNCE_PERIOD } from '../../constants/constants.js'

import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../model/patent_family_list_page_sizes.js'
import { ID_TO_FIELD, PRIORITY_DATE_FIELD_ID, ALL_ES_FIELDS, ALL_SORTABLE_ES_FIELDS, DEFAULT_SELECTED_GLOBAL_SEARCH_FIELD_IDS } from '../../model/patent_family_fields.js'
import { DESCENDING, SORT_DIRECTIONS, ID_TO_SORT_DIRECTION } from '../../model/sort_directions.js'
import { TABLE, CARDS } from '../../model/patent_family_list_views.js'

import {
  get_patent_families_by_search_phrase_as_csv,
  update_page_size_preferences,
  get_page_size_preference,
  get_patent_families_by_search_phrase,
  get_patent_families_by_search_phrase_as_xls,
  get_normalised_search_phrase,
  FAMILIES_TO_DOWNLOAD_THRESHOLD,
  remove_unavailable_field_ids,
  get_list_field_ids_preference,
  update_list_field_ids_preferences,
  get_patent_link_option_id_preference,
  update_patent_link_option_id_preference,
  get_es_only_fields,
  is_search_phrase_valid,
  GLOBAL_BOOL_SEARCH_PHRASE_LIMIT,
  update_list_view_preference,
  get_list_view_preference,
  order_field_ids_by_group,
} from '../../utils/patent_family_list_utils.js'
import { trigger_download, get_clean_filename } from '../../utils/download_utils.js'
import { MIMETYPE_XLSX, MIMETYPE_CSV, CSV_FILE_EXT, EXCEL_FILE_EXT } from '../../utils/download_utils.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { track_download_event, track_report_builder_event, track_list_event } from '../../utils/tracking_utils.js'
import { with_prev_subidx, with_next_subidx } from '../../utils/pagination_utils.js'

import PatentFamilyListGuideLink from './PatentFamilyListGuideLink.js'
import PatentFamilyListActions, { PatentFamilyListActionsWrapper } from './PatentFamilyListActions.js'
import PatentFamiliesTable from './PatentFamiliesTable.js'
import BadSyntaxAlertModal from './BadSyntaxAlertModal.js'
import ListControlsInRow from './list_controls/ListControlsInRow.js'

import PatentCardList from '../classifiers_editor/components/PatentCardList.js'

import ReportBuilderStartScreen from '../builder/ReportBuilderStartScreen.js'
import ErrorModal from '../ErrorModal.js'
import { withUser } from '../UserContext.js'
import SearchBar from '../widgets/SearchBar.js'
import { PrimaryButton } from '../widgets/Button.js'
import Spinner from '../widgets/Spinner.js'
import { PATENT_LINK_OPTION_DEFAULT_ID } from '../../model/patent_links.js'
import { is_400_error } from '../../utils/axios_utils.js'
import { FormFeedback } from '../widgets/FormFeedback.js'
import { update_url_with_search_phrase } from '../../utils/url_utils.js'
import PatentFamilyListDetailsContainer from './PatentFamilyListDetailsContainer.js'
import PatentFamilyListDetailViewNav from './PatentFamilyListDetailViewNav.js'
import PatentKeyboardShortcutsWrapper from './PatentKeyboardShortcutsWrapper.js'

import s from './PatentFamilyGlobalSearch.module.scss'

class PatentFamilyGlobalSearch extends Component {
  constructor(props) {
    super(props)

    this.fetch                                         = this.fetch.bind(this)
    this.on_page_size_change                           = this.on_page_size_change.bind(this)
    this.on_page_change                                = this.on_page_change.bind(this)
    this.on_change_subidx                              = this.on_change_subidx.bind(this)
    this.on_prev_subidx                                = this.on_prev_subidx.bind(this)
    this.on_next_subidx                                = this.on_next_subidx.bind(this)
    this.on_show_detail                                = this.on_show_detail.bind(this)
    this.on_hide_detail                                = this.on_hide_detail.bind(this)

    this.on_change_selected_field_ids                  = this.on_change_selected_field_ids.bind(this)
    this.on_change_sort_direction_id                   = this.on_change_sort_direction_id.bind(this)
    this.on_change_sort_field_id                       = this.on_change_sort_field_id.bind(this)
    this.on_change_sort_field_id_and_sort_direction_id = this.on_change_sort_field_id_and_sort_direction_id.bind(this)
    this.on_change_patent_family_list_view_id          = this.on_change_patent_family_list_view_id.bind(this)
    this.on_change_patent_link_option_id               = this.on_change_patent_link_option_id.bind(this)

    this.apply_search_input                            = this.apply_search_input.bind(this)
    this.on_change_from_search_input                   = this.on_change_from_search_input.bind(this)
    this.on_clear_search_input                         = this.on_clear_search_input.bind(this)

    this.download_as_csv                               = this.download_as_csv.bind(this)
    this.download_as_excel                             = this.download_as_excel.bind(this)

    this.results_action                                = this.results_action.bind(this)

    this.fetch_debounced = _.debounce(this.fetch, DEBOUNCE_PERIOD)

    this.search_input_ref = React.createRef()

    const { location, user } = this.props

    const query_params = qs.parse(location.search)

    // Reference data (store as object properties rather than state, as static - i.e. could also evaluate on demands, but convient to do this once)

    const all_field_ids      = order_field_ids_by_group(remove_unavailable_field_ids(ALL_ES_FIELDS.map(field => field.id), {is_keyword_search: true, user}))
    const all_sort_field_ids = remove_unavailable_field_ids(ALL_SORTABLE_ES_FIELDS.map(field => field.id), {is_keyword_search: true, user})
    this.all_fields          = all_field_ids.map( id => ID_TO_FIELD[id])
    this.all_sort_fields     = all_sort_field_ids.map(id => ID_TO_FIELD[id])
    // this.timerange_field_id_to_default_extent = get_timerange_field_id_to_default_extent(new Date().getTime()) // not used for now - add when backend supports

    // Initial selected values
    this.default_selected_field_ids     = remove_unavailable_field_ids(DEFAULT_SELECTED_GLOBAL_SEARCH_FIELD_IDS, {is_keyword_search: true, user})
    const selected_field_ids_preference = get_list_field_ids_preference() // try to read from localStorage
    const selected_field_ids            = get_es_only_fields(selected_field_ids_preference || DEFAULT_SELECTED_GLOBAL_SEARCH_FIELD_IDS)

    const patent_link_option_id_preference = get_patent_link_option_id_preference()
    const patent_link_option_id = patent_link_option_id_preference || PATENT_LINK_OPTION_DEFAULT_ID

    const initial_search_phrase = query_params.families_search ? decodeURIComponent(query_params.families_search) : null

    this.state = {
      is_initial_search_done: false, // as soon as a fetch is done, we set flag to true
      is_fetching: false,
      fetching_pat_families_error: null,
      bad_search_syntax_error: null,
      patent_families: null,
      total: null,
      page: 0,
      page_size: get_page_size_preference() || DEFAULT_PAGE_SIZE,
      subidx: null, // currently selected family in this page

      show_detail: false,

      search_phrase: null,                       // already applied
      next_search_phrase: initial_search_phrase, // not yet been applied
      // status_ids: ALL_STATUS_IDS, // not used yet - add when backend supports it
      // timerange_field_id: NO_FILTER_ID,
      // timerange: null,
      patent_family_list_view_id: get_list_view_preference(user) || TABLE,
      selected_field_ids: selected_field_ids,
      sort_field_id: PRIORITY_DATE_FIELD_ID,
      sort_direction_id: DESCENDING,
      patent_link_option_id: patent_link_option_id,

      is_csv_downloading: false,
      is_xls_downloading: false,
      csv_download_error: null,
      xls_download_error: null,


      is_family_tagging_mode_on: false,
      family_tagging_search_phrase: null
    }
  }

  componentDidMount() {
    const { next_search_phrase } = this.state

    if (next_search_phrase && next_search_phrase !== '') {
      this.fetch()
    }
  }

  on_change_from_search_input(new_search_phrase) {
    const { next_search_phrase } = this.state

    if (next_search_phrase === new_search_phrase) {
      return
    }

    this.setState({ next_search_phrase: new_search_phrase }) // store in "next_search_phrase" (once search is applied, we move it to "search_phrase")
  }

  on_clear_search_input() {
    this.setState({ next_search_phrase: null })
    this.set_focus_on_search_input()
  }

  apply_search_input() {
    track_report_builder_event('action="search" obj="keyword_search" context="builder"')
    const { next_search_phrase } = this.state

    // write to url
    const { location, history } = this.props
    if (location && history) {
      update_url_with_search_phrase(next_search_phrase, 'families_search', location, history)
    }

    if (next_search_phrase == null || next_search_phrase === '') {
      return
    }

    this.setState({ page: 0, subidx: null }, () => { // reset page state
      this.fetch() // fetch already applies the "next_search_phrase" (and moves it to "search_phrase")
    })
  }

  download_as_csv() {
    const { search_phrase, selected_field_ids, sort_field_id, sort_direction_id } = this.state
    const selected_field_ids_string = selected_field_ids.join(',')
    const normalised_search_phrase = get_normalised_search_phrase(search_phrase)

    this.setState({ is_csv_downloading: true }) // show spinner

    track_download_event('obj="keyword_search" format="CSV"')

    get_patent_families_by_search_phrase_as_csv(normalised_search_phrase, selected_field_ids_string, sort_field_id, sort_direction_id)
      .then(response => {
        this.setState({ is_csv_downloading: false })
        const filename = get_clean_filename(normalised_search_phrase + '_export' + CSV_FILE_EXT)
        trigger_download(response.data, MIMETYPE_CSV, filename)
      })
      .catch(error => {
        this.setState({ is_csv_downloading: false, csv_download_error: error })
        throw error
      })
  }

  download_as_excel() {
    const { search_phrase, selected_field_ids, sort_field_id, sort_direction_id, patent_link_option_id } = this.state
    const normalised_search_phrase = get_normalised_search_phrase(search_phrase)

    this.setState({ is_xls_downloading: true }) // show spinner

    track_download_event('obj="keyword_search" format="XLS"')

    get_patent_families_by_search_phrase_as_xls(normalised_search_phrase, selected_field_ids, sort_field_id, sort_direction_id, patent_link_option_id)
      .then(response => {
        this.setState({ is_xls_downloading: false })
        const filename = get_clean_filename(normalised_search_phrase + '_export' + EXCEL_FILE_EXT)
        trigger_download(response, MIMETYPE_XLSX, filename)
      })
      .catch(error => {
        this.setState({ is_xls_downloading: false, xls_download_error: error })
        throw error
      })
  }

  fetch() {

    const {
      // status_ids, timerange_field_id, timerange  // add these back when backend supports them
      sort_field_id, sort_direction_id,  page, page_size, next_search_phrase
    } = this.state

    this.setState({
      fetching_pat_families_error: null,
      bad_search_syntax_error: null,
      is_fetching: true,
      search_phrase: next_search_phrase, // apply search_phrase
      is_initial_search_done: true
    })

    const offset = page * page_size
    const limit = page_size

    const normalised_search_phrase = get_normalised_search_phrase(next_search_phrase)

    get_patent_families_by_search_phrase(normalised_search_phrase, limit, offset, sort_field_id, sort_direction_id)
      .catch((err) => {
        if (is_400_error(err)) {
          // BAD SYNTAX
          this.setState({ is_fetching: false, bad_search_syntax_error: err })
          return null // don't rethrow, as we don't want to log the error
        } else {
          // OTHER ERROR
          this.setState({ is_fetching: false, fetching_pat_families_error: err })
          throw err
        }
      })
      .then((response) => {
        if (response == null) {
          // This can happen if 400 bad syntax error
          return
        }

        const { searchResults, totalResults } = response
        // SUCCESS
        this.setState({ is_fetching: false, patent_families: searchResults, total: totalResults })
        this.set_focus_on_search_input()
      })
  }

  results_action() {
    const { results_action_handler } = this.props
    const { search_phrase, total } = this.state

    track_report_builder_event('action="add_to_basket" obj="keyword_search"')
    results_action_handler(search_phrase, total)
    this.search_input_ref.current.focus()

    // Clear results
    this.setState({ search_phrase: null, next_search_phrase: null, patent_families: null, total: null, is_initial_search_done: false })
  }

  set_focus_on_search_input() {
    // Workaround to set focus on search input

    const { show_detail } = this.state
    const search_input = this.search_input_ref.current

    if (!search_input) {
      // search input is not yet renered, so do nothing.
      return
    }

    if (show_detail) {
      // Modal is showing, so do nothing.
      return
    }

    search_input.focus()
  }

  on_page_size_change(page_size) {
    track_list_event(`action="change_page_size"  page_size="${page_size}" context="report"`)

    update_page_size_preferences(page_size) // saves to local storage

    this.setState({ page_size, page: 0, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })

  }

  on_page_change(page) {
    this.setState({ page, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })

    track_list_event(`action="pager" page="${page}" context="report"`)
  }

  on_change_subidx(subidx) {
    this.setState({ subidx })
  }

  on_show_detail(subidx) {
    this.setState({ subidx, show_detail: true })
  }

  on_hide_detail() {
    this.setState({ show_detail: false })
  }

  on_prev_subidx() {
    const { page, subidx, page_size, is_fetching } = this.state
    if (is_fetching) {
      return
    }

    const { page: new_page, subidx: new_subidx } = with_prev_subidx(subidx, page, page_size)

    if (new_page !== page) {
      this.setState({ page: new_page, subidx: new_subidx, is_fetching: true }, () => {
        this.fetch_debounced()
      })
    } else {
      this.setState({ page: new_page, subidx: new_subidx })
    }
  }

  on_next_subidx() {
    const { page, subidx, page_size, total, is_fetching } = this.state
    if (is_fetching) {
      return
    }

    const total_num_pages = this.get_total_num_pages()

    if (!total_num_pages || !total) {
      return
    }

    const { page: new_page, subidx: new_subidx } = with_next_subidx(subidx, page, page_size, total_num_pages, total)

    if (new_page !== page) {
      this.setState({ page: new_page, subidx: new_subidx, is_fetching: true }, () => {
        this.fetch_debounced()
      })
    } else {
      this.setState({ page: new_page, subidx: new_subidx })
    }
  }

  on_change_selected_field_ids(selected_field_ids) {
    this.setState({ selected_field_ids })
    update_list_field_ids_preferences(selected_field_ids) // save to localStorage
  }

  on_change_patent_family_list_view_id(patent_family_list_view_id) {
    update_list_view_preference(patent_family_list_view_id)
    this.setState({ patent_family_list_view_id })
  }

  on_change_timerange_field_id(timerange_field_id) {
    const { timerange_field_id_to_default_extent } = this.state
    const timerange = timerange_field_id_to_default_extent[timerange_field_id]
    this.setState({ timerange_field_id, timerange, page: 0, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })
  }

  on_change_timerange(timerange) {
    this.setState({ timerange, page: 0, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })
  }

  on_change_sort_direction_id(sort_direction_id) {
    const { sort_field_id } = this.state
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="keyword_search"`)

    this.setState({ sort_direction_id, page: 0, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })
  }

  on_change_sort_field_id(sort_field_id) {
    const { sort_direction_id } = this.state
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="keyword_search"`)

    this.setState({ sort_field_id, page: 0, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })
  }

  on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="keyword_search"`)

    this.setState({ sort_field_id, sort_direction_id, page: 0, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })
  }

  on_change_status_ids(status_ids) {
    if (status_ids.length === 0) {
      // Do nothing (don't allow user to select no status - there will no results - plus the backend doesn't like the query)
      return
    }

    this.setState({ status_ids, page: 0, is_fetching: true /* show spinner immeditely */ }, () => {
      this.fetch_debounced()
    })
  }

  on_change_patent_link_option_id(patent_link_option_id) {
    this.setState({ patent_link_option_id })
    update_patent_link_option_id_preference(patent_link_option_id)
  }

  get_total_num_pages() {
    const { total, page_size } = this.state
    if (!total || !page_size) {
      return 0
    }

    return Math.ceil(total / page_size)
  }


  on_toggle_family_tagging_mode() {
    const {is_family_tagging_mode_on} = this.state
    this.setState({is_family_tagging_mode_on: !is_family_tagging_mode_on})
  }

  on_update_family_tagging_search_phrase(text) {
    this.setState({family_tagging_search_phrase: text})
  }

  render() {
    const {
      is_initial_search_done,
      is_fetching,
      fetching_pat_families_error,
      bad_search_syntax_error,
      patent_families,
      total,
      page,
      page_size,
      subidx,

      show_detail,

      next_search_phrase,
      // status_ids,
      // timerange_field_id,
      // timerange,
      patent_family_list_view_id,
      selected_field_ids,
      sort_field_id,
      sort_direction_id,
      patent_link_option_id,

      is_csv_downloading,
      is_xls_downloading,
      csv_download_error,
      xls_download_error,

      is_family_tagging_mode_on,
      family_tagging_search_phrase
    } = this.state

    const {
      results_action_button_label,
      controls_row_className,
      table_header_className,
      className
    } = this.props

    const has_results = (total > 0)

    const selected_fields = this.all_fields.filter(field => _.contains(selected_field_ids, field.id))

    const num_pages = this.get_total_num_pages()
    const idx = page * page_size + subidx

    // Patfam for details
    const selected_patfam = !is_fetching && patent_families ? patent_families[subidx] : null

    const has_result_ready = is_initial_search_done && !is_fetching && !fetching_pat_families_error

    return (
      <div className={cn('w-100 position-relative', {'h-100': show_detail}, className)}>

        <PatentKeyboardShortcutsWrapper
          disable={is_fetching || !total || fetching_pat_families_error}
          source={'GlobalSearch'}
          on_prev_patent={this.on_prev_subidx}
          on_next_patent={this.on_next_subidx}
          on_show_details={this.on_show_detail.bind(null, subidx)}
          on_hide_details={this.on_hide_detail}
        />

        {fetching_pat_families_error &&
          <ErrorModal
            on_hide={() => this.setState({fetching_pat_families_error: null})}
            error={fetching_pat_families_error}
            context='searching for patent families'
          />
        }

        {bad_search_syntax_error &&
          <BadSyntaxAlertModal
            on_hide={() => {
              this.setState({ bad_search_syntax_error: null })
              this.set_focus_on_search_input()
            }}
          />
        }

        {csv_download_error &&
          <ErrorModal
            on_hide={() => this.setState({csv_download_error: null})}
            error={csv_download_error}
            context='exporting boolean search results as csv'
          />
        }

        {xls_download_error &&
          <ErrorModal
            on_hide={() => this.setState({xls_download_error: null})}
            error={xls_download_error}
            context='exporting boolean search results to Excel'
          />
        }

        {!show_detail &&
          <>

          <div className='py-2'>
            {/* Search Bar */}
            <div className={cn('')}>
              <PatentFamilyListGuideLink />
            </div>
            <SearchBar
              search_input_ref={this.search_input_ref}
              search_input={next_search_phrase == null ? '' : next_search_phrase}
              on_change_search_input={this.on_change_from_search_input}
              on_clear={this.on_clear_search_input}
              do_search={this.apply_search_input}
              search_is_fetching={is_fetching}
              is_search_valid={(next_search_phrase == null) || is_search_phrase_valid(next_search_phrase)}
              autofocus={!has_results}
              className='mt-2'
            />
            <FormFeedback
              valid={(next_search_phrase == null) || is_search_phrase_valid(next_search_phrase)}
              validation_text={`Search query cannot be empty or longer than ${format_integer_with_comma(GLOBAL_BOOL_SEARCH_PHRASE_LIMIT)} characters.`}
            />
          </div>


        {is_initial_search_done &&
          <PatentFamilyListActionsWrapper is_csv_downloading={is_csv_downloading} is_xls_downloading={is_xls_downloading} className='pb-2 my-2'>
            <PatentFamilyListActions
              className='my-auto'
              download_as_csv_handler={this.download_as_csv}
              download_as_xls_handler={this.download_as_excel}
              is_export_in_progress={is_csv_downloading || is_xls_downloading}
              no_field_ids_selected={selected_field_ids.length === 0}
              list_no_results={!has_results}
              list_fetching={is_fetching}
              list_too_long={total > FAMILIES_TO_DOWNLOAD_THRESHOLD}
            />
          </PatentFamilyListActionsWrapper>
        }

        {/* Controls */}
        {is_initial_search_done &&
          <ListControlsInRow
            className={cn(controls_row_className)}
            context={'keyword_search'} // for tracking
            hide_status={true}
            hide_timerange={true}

            sort_fields={this.all_sort_fields}
            id_to_sort_field={ID_TO_FIELD}
            selected_sort_field_id={sort_field_id}
            on_change_sort_field_id={this.on_change_sort_field_id}
            sort_directions={SORT_DIRECTIONS}
            id_to_sort_direction={ID_TO_SORT_DIRECTION}
            selected_sort_direction_id={sort_direction_id}
            on_change_sort_direction_id={this.on_change_sort_direction_id}

            patent_family_list_view_id={patent_family_list_view_id}
            on_change_patent_family_list_view_id={this.on_change_patent_family_list_view_id}

            all_fields={this.all_fields}
            selected_field_ids={selected_field_ids}
            default_selected_field_ids={this.default_selected_field_ids}
            on_change_selected_field_ids={this.on_change_selected_field_ids}
            fields_disabled={!has_results || is_fetching} // TODO: do we need this????

            page_sizes={PAGE_SIZES}
            selected_page_size={page_size}
            on_change_page_size={this.on_page_size_change}

            current_page={page}
            num_pages={num_pages}
            on_change_current_page={this.on_page_change}
          />
        }

        <div className={s.spinner_wrapper}>
          {is_fetching &&
            <Spinner className={cn('mt-3')}/>
          }
        </div>

        {has_result_ready &&
          <div className={cn('d-flex', 'align-items-center', 'mt-1')}>
            <span className={s.count_info}>
              This selection contains {format_integer_with_comma(total || 0)} patent families
            </span>

            {(total > 0) &&
              <PrimaryButton
                className={cn('ms-auto')}
                size={'md'}
                onClick={this.results_action}
              >
                {results_action_button_label || 'Add to report'}
              </PrimaryButton>
            }

          </div>
        }

        {(patent_family_list_view_id === CARDS) &&
          <PatentCardList
            className={'my-3'}
            patents={patent_families}
            subidx={subidx}
            on_show_details={this.on_show_detail}

            num_pages={num_pages}
            current_page={page}
            on_change_current_page={this.on_page_change}
            on_change_subidx={this.on_change_subidx}

            is_fetching={is_fetching}
          />
        }

        {/*
            Always render the table component, as it holds scroll_x state.
            When "is_fetching" or "error", it renders an empty div.
         */}
        {(patent_family_list_view_id === TABLE) && 
          <PatentFamiliesTable
            headerClassName={cn(table_header_className)}

            patfams={patent_families}

            subidx={subidx}

            fields={selected_fields}
            selected_sort_field_id={sort_field_id} // relates to 'fields' prop
            selected_sort_direction_id={sort_direction_id}
            on_change_sort_field_id_and_sort_direction_id={this.on_change_sort_field_id_and_sort_direction_id}

            on_change_subidx={this.on_change_subidx}
            on_show_detail={this.on_show_detail}

            is_fetching={is_fetching}

            selected_patent_link_option_id={patent_link_option_id}
          />
        }
        </>
        }

        {show_detail &&
          <>
            <PatentFamilyListDetailViewNav
              on_cancel_details_view={this.on_hide_detail}
              current_idx={idx}
              total_results_count={total}
              on_prev_item={this.on_prev_subidx}
              on_next_item={this.on_next_subidx}

              className={cn('sticky-top py-2', s.details_nav_container)}
            />

            <PatentFamilyListDetailsContainer
              family={selected_patfam}
              patent_families={patent_families}
              on_change_subidx={this.on_change_subidx}

              is_fetching={is_fetching}
              error_fetching={fetching_pat_families_error}
              on_retry={this.fetch}

              patent_link_option_id={patent_link_option_id}
              on_change_patent_link_option_id={this.on_change_patent_link_option_id}

              top_className={s.family_details_top}

              //family tagging
              is_family_tagging_mode_on={is_family_tagging_mode_on}
              family_tagging_search_phrase={family_tagging_search_phrase || ''}
              on_toggle_family_tagging_mode={this.on_toggle_family_tagging_mode.bind(this)}
              on_update_family_tagging_search_phrase={this.on_update_family_tagging_search_phrase.bind(this)}
              //end of family tagging

              className={s.details_container}
            />
          </>
        }

        {/* Start screen message - after an initial search is done, this is no longer shown */}
        {(!is_initial_search_done && !is_fetching) &&
          <ReportBuilderStartScreen
            className={cn('mt-1 mb-3')}
            text={(
              <div className='text-center'>
                <div className={s.hero_text}>
                  Use boolean query to find patent families to add to the report. If no search fields are specified,
                  Cipher will use title, abstract and claims.
                </div>
                <div className='p-3 text-start'>
                  <div className='py-3'>Common examples:</div>
                  <table className={cn('w-100', s.examples_table)}>
                    <thead>
                    <tr>
                        <th>Example</th>
                        <th>Searches</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>vacuum <b>AND</b> cleaner</td>
                        <td>Must contain the words 'vacuum' and 'cleaner'</td>
                      </tr>
                      <tr>
                        <td>vacuum <b>OR</b> hoover</td>
                        <td>Must contain the words 'vacuum' or 'hoover'</td>
                      </tr>
                      <tr>
                        <td><b>NOT</b> electric</td>
                        <td>Must not contain the word 'electric'</td>
                      </tr>
                      <tr>
                        <td>tele*</td>
                        <td>Wildcard. Must contain the characters 'tele' (television, telephone, telegraph, teleport)</td>
                      </tr>
                      <tr>
                        <td>"electric vacuum cleaner"</td>
                        <td>Must contain the exact phrase 'electric vacuum cleaner'</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          />
        }

      </div>
    )
  }
}

export default withRouter(withUser(PatentFamilyGlobalSearch))