import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { withReportBasket } from './ReportBasketContext.js'
import PortfolioBasketSaveControl from './PortfolioBasketSaveControl.js'
import Modal from '../widgets/Modal.js'
import TextLink from '../widgets/TextLink.js'
import {
  PORTFOLIO_SEARCH_TYPE_BY_ID,
  REPORT_BUILDER_MODE_BY_ID,
  REPORT_BUILDER_MODE_ID_PORTFOLIO,
  REPORT_BUILDER_MODE_ID_TECHNOLOGY
} from '../../constants/report_builder.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'

import { is_cipher_engineering } from '../../utils/user_permissions.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import Spinner from '../widgets/Spinner.js'

import { is_family_tag_type, is_patent_families_type, is_tech_search_type } from '../../model/portfolio_basket.js'
import { is_org_group_type, is_org_type } from '../../utils/organisation_utils.js'
import { get_normalised_name } from '../../utils/name_utils.js'
import { InputWithAutofocus } from '../widgets/InputWithAutofocus.js'
import ConfirmOrCancel from '../widgets/ConfirmOrCancel.js'
import {
  EditIcon,
  QuestionMarkIcon,
  ShowSimilarIcon,
  TrashIcon
} from '../widgets/IconSet.js'
import { BasketSizeShortWarning } from './ReportBuilderBasketWarning.js'
import { TECH_PARTITIONING_TYPE_CLUSTERING, TECH_PARTITIONING_TYPE_UTT } from '../../model/technology_basket.js'

import cs from '../cipher_styles.module.scss'
import s from './ReportBuilderBasket.module.scss'

const TECHNOLOGY_BASKET_LENGTH_LIMIT = 10
const PORTFOLIO_BASKET_LENGTH_LIMIT = 10

const ReportBuilderPortfolioInBasket = (
  {
    item,
    item_size,
    item_names,
    is_in_edit_mode,
    on_click_from_search_phrase,
    on_click_from_similar_orgs,
    on_click_from_org_group,
    toggle_rename_mode,
    delete_from_portfolio_basket_handler,
    rename_item,
    disable_actions
  }) => {

  const [new_name, set_new_name] = useState(null)

  const { name, static_name, search_term } = item
  const is_search = is_tech_search_type(item)
  const is_org = is_org_type(item)
  const is_patent_upload = is_patent_families_type(item)
  const is_family_tag = is_family_tag_type(item)
  const is_org_group = is_org_group_type(item)

  useEffect(() => {
    if (!is_in_edit_mode) {
      set_new_name(null)
    }
  }, [is_in_edit_mode])

  function on_change_from_new_name_input(e) {
    const value = e.target.value
    set_new_name(value)
  }

  function on_new_name_cancel() {
    set_new_name(null)
    toggle_rename_mode()
  }

  function on_new_name_submit() {
    rename_item(get_normalised_name(new_name))
    toggle_rename_mode()
  }

  function on_key_down(e) {
    if (e.which === 13 && is_new_name_valid) {
      on_new_name_submit()
    }
  }

  function check_if_new_name_valid() {
    const normalised_name = get_normalised_name(new_name || '')

    return (normalised_name.length > 0) && (item_names.indexOf(normalised_name) === -1)
  }

  const displayed_name = (new_name != null) ? new_name : name

  const is_new_name_valid = is_in_edit_mode ? check_if_new_name_valid() : true

  if (is_in_edit_mode) {
    return (
      <div  className={cn('d-flex mt-1 w-100', s.item_wrapper)}>
        <div className='position-relative flex-grow-1 my-auto'>
          <InputWithAutofocus
            value={displayed_name}
            on_change={on_change_from_new_name_input}
            size='sm'
            className='m-0 p-0'
            on_key_down={on_key_down}
          />
        </div>

        <ConfirmOrCancel
          is_valid={is_new_name_valid}
          on_submit={on_new_name_submit}
          on_cancel={on_new_name_cancel}
          className='my-auto'
        />
      </div>
    )
  }

  return (
    <div className={cn('d-flex justify-content-between mt-1', s.item_wrapper)} >
      <span className={cn('my-auto', s.item_name)} title={static_name || name}>{static_name || name}</span>

      {!static_name &&
        <div className={cn('d-flex flex-nowrap')}>
          {(is_search || is_family_tag) &&
            <TextLink
              className='ms-1 my-auto'
              onClick={() => on_click_from_search_phrase(is_family_tag ? name : search_term, is_family_tag ? 'Family tag' : 'Boolean search', item_size)}
              no_decoration
            >
              <QuestionMarkIcon />
            </TextLink>
          }

          {is_org_group &&
            <TextLink
              className='ms-1 my-auto'
              onClick={() => on_click_from_org_group(item)}
              title='Show details'
              no_decoration
            >
              <QuestionMarkIcon />
            </TextLink>
          }

          {(is_patent_upload || is_org || is_org_group || is_family_tag) && !disable_actions &&
            <TextLink
              className='ms-1 my-auto'
              onClick={on_click_from_similar_orgs}
              title='Show similar organisations'
              disable={disable_actions}
              no_decoration
            >
              <ShowSimilarIcon disabled={disable_actions} />
            </TextLink>
          }

          <TextLink
            className='ms-1 my-auto'
            onClick={toggle_rename_mode}
            title='Rename'
            no_decoration
          >
            <EditIcon/>
          </TextLink>

          {!disable_actions &&
            <TextLink
              className='ms-1 my-auto'
              onClick={delete_from_portfolio_basket_handler}
              title='Remove'
              disable={disable_actions}
              no_decoration
            >
              <TrashIcon />
            </TextLink>
          }
        </div>
      }
    </div>
  )
}

const ReportBuilderBasket = (
  {
    user,

    portfolio_basket,
    portfolio_basket_sizes,
    get_similar_orgs_handler,
    delete_from_portfolio_basket_handler,
    rename_portfolio_basket_item,
    classifiers,
    clear_portfolio_basket_handler,
    show_technologies_basket,
    delete_from_technology_basket_handler,
    clear_technology_basket_handler,
    report_builder_change_search_mode_handler,
    report_builder_mode,
    report_builder_search_mode,
    portfolio_basket_save_handler,
    user_company_lists,
    is_calculating_report_size,
    is_report_big,
    is_report_too_big,
    portfolio_size,
    technology_partitioning,
    is_landscape,
    portfolios_to_cluster
  }) => {
  const [item_in_edit, set_item_in_edit] = useState(null)

  const [selected_search, set_selected_search] = useState(null)
  const [selected_search_title, set_selected_search_title] = useState(null)
  const [selected_total_items_msg, set_selected_total_items_msg] = useState('')
  const [selected_group, set_selected_group] = useState(null)
  const [show_all_portfolios, set_show_all_portfolios] = useState(false)
  const [show_all_classifiers, set_show_all_classifiers] = useState(false)

  function is_basket_empty() {
    return ((portfolio_basket || []).length === 0)
  }

  function on_click_from_similar_orgs(i) {
    track_report_builder_event('obj="similar_orgs" context="portfolio_basket" action="show"')
    get_similar_orgs_handler(i)
  }

  function on_click_from_search_phrase(phrase, title, families_count) {
    track_report_builder_event('obj="basket_item" action="show_search" context="portfolio_basket"')
    set_selected_search(phrase)
    set_selected_search_title(title)
    if (families_count != null) {
      const grouped_families_size = `(${format_integer_with_comma(families_count)} families)`
      set_selected_total_items_msg(grouped_families_size)
    }
    else {
      set_selected_total_items_msg('')
    }
  }

  function on_click_from_org_group(group) {
    track_report_builder_event('obj="basket_item" action="show_group" context="portfolio_basket"')
    set_selected_group(group)
  }

  function on_click_from_rename(i) {
    set_item_in_edit(item_in_edit === i ? null : i)
  }

  const portfolio_item_names = (portfolio_basket || []).map(item => item.name)

  const portfolios_to_display = is_basket_empty() ? [] : (
    show_all_portfolios ? portfolio_basket :
      (portfolio_basket.length > PORTFOLIO_BASKET_LENGTH_LIMIT + 1) ? portfolio_basket.slice(0, PORTFOLIO_BASKET_LENGTH_LIMIT) : portfolio_basket
  )

  const has_classifiers = (classifiers || []).length > 0
  const classifier_names = has_classifiers ? classifiers.map(item => item.name) : []

  const selected_technology_names = show_all_classifiers ? classifier_names : (classifier_names.length > TECHNOLOGY_BASKET_LENGTH_LIMIT + 1) ? classifier_names.slice(0, TECHNOLOGY_BASKET_LENGTH_LIMIT) : classifier_names

  const classifiers_tail_length = classifier_names.length - selected_technology_names.length

  const is_report_builder_in_classifier_mode = report_builder_mode === REPORT_BUILDER_MODE_BY_ID.technology
  const can_switch_to_org_sets = !is_report_builder_in_classifier_mode && report_builder_search_mode !== PORTFOLIO_SEARCH_TYPE_BY_ID['org_sets']

  const {type, use_superclasses} = technology_partitioning || {}

  const has_portfolios_to_cluster = portfolios_to_cluster && portfolios_to_cluster.length > 0

  return (
    <div className='position-relative d-block h-100'>
      {selected_search &&
        <Modal
          on_hide={() => set_selected_search(null)}
          title={selected_search_title}
        >
          <div>
            <span className={s.search_term}>{selected_search} </span> <span className={s.search_term_size}>{selected_total_items_msg} </span>
          </div>
        </Modal>
      }

      {selected_group &&
        <Modal
          on_hide={() => set_selected_group(null)}
          title={selected_group.name || 'Custom organisation group'}
        >
          <ul className='list-unstyled'>
          {(selected_group.members || []).map((item, i) => (
            <li key={i}>{item.name}</li>
            )
          )}
          </ul>
        </Modal>
      }

      <div className={s.basket_section}>
        <div className={cn(s.basket_section_header, 'p-2 d-flex flex-wrap')}>
          <h6 className='me-1 my-auto'>In report</h6>
          <PortfolioBasketSaveControl
            portfolio_basket={portfolio_basket}
            handle_portfolio_basket_saved={portfolio_basket_save_handler}
            user_company_lists={user_company_lists}
            switch_to_org_sets_handler={() => report_builder_change_search_mode_handler(PORTFOLIO_SEARCH_TYPE_BY_ID['org_sets'])}
            can_switch_to_org_sets={can_switch_to_org_sets}
            className='d-none d-md-block'
          />
          {!is_basket_empty() && (REPORT_BUILDER_MODE_ID_PORTFOLIO === report_builder_mode) &&
            <TextLink className='ms-auto d-none d-md-block' onClick={clear_portfolio_basket_handler} disable={REPORT_BUILDER_MODE_ID_PORTFOLIO !== report_builder_mode}>Clear all</TextLink>
          }
        </div>

        <div className={cn(s.basket_items_container, 'p-2')}>
          <div className={s.portfolio_basket_info}>
            {is_calculating_report_size &&
              <span className={cn('d-flex my-auto', s.__calculating)}><Spinner size='xs'/><span className='ms-2 my-auto'>calculating size</span></span>
            }

            {!is_calculating_report_size && (is_report_big || is_report_too_big) &&
              <BasketSizeShortWarning
                title={is_cipher_engineering(user) ? format_integer_with_comma(portfolio_size) : null}
                is_report_big={is_report_big}
                is_report_too_big={is_report_too_big}
              />
            }

            {!is_calculating_report_size && !(is_report_big || is_report_too_big) && is_cipher_engineering(user) &&
              <span>Basket size: {format_integer_with_comma(portfolio_size)}</span>
            }
          </div>

          {portfolios_to_display.map((item, i) => {
            return (
              <ReportBuilderPortfolioInBasket
                key={i}
                item={item}
                item_size={portfolio_basket_sizes[i]}

                item_names={portfolio_item_names}
                is_in_edit_mode={item_in_edit === i}

                on_click_from_search_phrase={on_click_from_search_phrase}
                on_click_from_similar_orgs={() => on_click_from_similar_orgs(i)}
                on_click_from_org_group={on_click_from_org_group}
                toggle_rename_mode={() => on_click_from_rename(i)}

                delete_from_portfolio_basket_handler={() => delete_from_portfolio_basket_handler(i)}
                rename_item={(new_name) => rename_portfolio_basket_item(i, new_name)}
                disable_actions={REPORT_BUILDER_MODE_ID_PORTFOLIO !== report_builder_mode}
              />
            )
          })}

          {((portfolio_basket || []).length > PORTFOLIO_BASKET_LENGTH_LIMIT + 1) &&
            <TextLink className='mt-1' key={'portfolios_switch'} onClick={() => set_show_all_portfolios(!show_all_portfolios)}>
              <span>{show_all_portfolios ? 'Show less' :  `+ ${(portfolio_basket || []).length - portfolios_to_display.length} more`}</span>
            </TextLink>
          }
        </div>
      </div>

      {show_technologies_basket &&
        <div className={s.basket_section}>
          <div className={cn(s.basket_section_header, s.basket_section_spacer, 'p-2 d-flex flex-nowrap')}>
            <h6 className='my-auto me-auto'>Technologies</h6>
            {selected_technology_names.length > 0 && (REPORT_BUILDER_MODE_ID_TECHNOLOGY === report_builder_mode) &&
              <TextLink onClick={clear_technology_basket_handler} disable={REPORT_BUILDER_MODE_ID_TECHNOLOGY !== report_builder_mode} className={cn('d-none d-md-block', cs.white_space_nowrap)}>Clear all</TextLink>
            }
          </div>

          <div className={cn(s.basket_items_container, 'p-2')}>
            {selected_technology_names &&
              <>
                {selected_technology_names.map((item, i) => (
                  <div className={cn('d-flex justify-content-between mt-1', s.item_wrapper)} key={i}>
                    <span className={cn('my-auto', s.item_name)} title={item}>{item}</span>

                    {REPORT_BUILDER_MODE_ID_TECHNOLOGY === report_builder_mode &&
                      <TextLink
                        className='ms-1 my-auto'
                        onClick={() => delete_from_technology_basket_handler(i)}
                        title='Remove'
                        no_decoration
                      >
                        <TrashIcon />
                      </TextLink>
                    }
                  </div>
                ))}

                {((classifiers || []).length > (TECHNOLOGY_BASKET_LENGTH_LIMIT + 1) ) &&
                  <TextLink className='mt-1' key={'techs_switch'} onClick={() => set_show_all_classifiers(!show_all_classifiers)}>
                    <span>{show_all_classifiers ? 'Show less' :  `+ ${classifiers_tail_length} more`}</span>
                  </TextLink>
                }

              </>
            }

            {TECH_PARTITIONING_TYPE_UTT === type && !is_landscape &&
              <span>{`Defined by Universal Technology Taxonomy ${use_superclasses ? 'super' : ''}classes`}</span>
            }

            {TECH_PARTITIONING_TYPE_CLUSTERING === type && has_portfolios_to_cluster &&
              <span>{`Defined by clustering`}</span>
            }

            {TECH_PARTITIONING_TYPE_CLUSTERING === type && !has_portfolios_to_cluster &&
              <span>{`No classification`}</span>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default withReportBasket(ReportBuilderBasket)