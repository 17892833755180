import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import DefaultPageContainer from './DefaultPageContainer.js'
import { HeaderNoAuth } from './header/Header.js'
import DefaultContentContainer from './DefaultContentContainer.js'
import Spinner from './widgets/Spinner.js'
import ErrorBody from './ErrorBody.js'
import { get_tech_discovery_wam_user_details } from '../utils/auth_utils.js'

/*
 * WAM token only contains authentication, not authorisation or user/org data.
 * So we trigger fetch of this data here.
 * It is also cached on the server (db-backed), for verification on API calls.
 */
const TechDiscoveryUserAuthorisationLoader = ({ id_token, user_perm_id, set_tech_discovery__wam_details, on_logout }) => {

  const [is_fetching,    set_is_fetching]    = useState(true)
  const [error_fetching, set_error_fetching] = useState(null)

  const [no_techdiscovery_access, set_no_techdiscovery_access] = useState(false)

  useEffect(() => {
    // On load, fetch user details from WAM
    get_tech_discovery_wam_user_details(id_token, user_perm_id)
      .catch(err => {
        set_error_fetching(err)
        throw err
      })
      .then(user_details => {
        const { has_tech_discovery_access } = user_details
        if (!has_tech_discovery_access) {
          set_no_techdiscovery_access(true)
          return
        }

        set_tech_discovery__wam_details(user_details) // Pass data to parent component (re-render will unmount this component)
      })
      .finally(() => {
        set_is_fetching(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DefaultPageContainer>
      <HeaderNoAuth on_logout={on_logout}/>
      <DefaultContentContainer
        className={cn('m-5')}
      >
        {is_fetching && (
          <div className={cn('d-flex', 'align-items-center')}>
            <Spinner />
            <div className={cn('mx-2')}>Fetching user details... </div>
          </div>
        )}

        {error_fetching &&
          <ErrorBody
            error={error_fetching}
            context={'fetching/saving TechDiscovery user details from WAM'}
          />
        }

        {no_techdiscovery_access &&
          <div>
            <p>
              You do not have access to TechDiscovery. Please contact your <a href="mailto:support@lexisnexisip.com">Customer Success Manager</a> for assistance.
            </p>
            <p>
              If you have multiple LexisNexis accounts, please use <a href="https://login.lexisnexis.com/oauth2/sessions/logout">this link</a> to sign out of all accounts before trying again.
            </p>
          </div>
        }

      </DefaultContentContainer>
    </DefaultPageContainer>
  )
}

export default TechDiscoveryUserAuthorisationLoader