import _ from 'underscore'
import qs from 'query-string'

import {
  DEFAULT_HELP_ENDPOINT,
  HELP_SECTIONS,
  REPORT_BASED_ALERTS_HELP,
  RECENT_HISTORY_HELP,
  NEW_REPORT_HELP,
  NEW_ND_REPORT_HELP,
  CLASSIFIER_REPORT_HELP,
  BUILD_CC_REPORT_HELP,
  UTT_LANDSCAPE_REPORT_HELP,
  PUBLICATION_FORMATS_HELP,
  FAMILY_LIST_COLUMNS_HELP,
  TECH_DISCOVERY_HELP_ENDPOINT
} from '../model/help_sections.js'
import { track_support_event } from './tracking_utils.js'
import { is_tech_discovery } from './utils.js'

const HELP_CENTRE_URL = process.env.HELP_CENTRE_URL || 'https://support.lexisnexisip.com'
export const PATH_PREFIX = '/hc/en-us'
export const DEFAULT_HELP_URL =  `${HELP_CENTRE_URL}${PATH_PREFIX}${is_tech_discovery() ? TECH_DISCOVERY_HELP_ENDPOINT : DEFAULT_HELP_ENDPOINT}`

export function get_help_url() {

  const pathname = get_pathname()

  const params = get_params()

  const { help_endpoint, tech_discovery_help_endpoint } = find_section_by_pathname_and_params(pathname, params) || {}

  return help_endpoint ? get_help_article_url(is_tech_discovery() && (tech_discovery_help_endpoint != null) ? tech_discovery_help_endpoint : help_endpoint) : DEFAULT_HELP_URL
}

export function get_help_article_from_url(help_url) {
  return help_url.replace(HELP_CENTRE_URL, '')
}

function get_pathname() {
  const location = window.location
  return location.pathname || ''
}

function get_params() {
  const location = window.location
  return location.search ? qs.parse(location.search) : null
}

function find_section_by_pathname_and_params(pathname, params) {
  const section_by_pathname = HELP_SECTIONS.filter(section => pathname.lastIndexOf(section.pathname, 0) === 0 )

  if (section_by_pathname.length === 0) return null
  if (section_by_pathname.length === 1) return section_by_pathname[0]

  const section_by_params = section_by_pathname.filter(section => has_matching_params(section, params))

  if (section_by_params.length === 1) return section_by_params[0]

  return null
}

function has_matching_params(section, params) {
  return (params) ? (section.params && _.isMatch(params, section.params)) : (section.params == null)
}

export function get_alerts_help_url() {
  return get_help_article_url(REPORT_BASED_ALERTS_HELP.help_endpoint)
}

export function get_recent_history_help_url() {
  return get_help_article_url(RECENT_HISTORY_HELP.help_endpoint)
}

export function get_new_report_help_url() {
  return get_help_article_url(NEW_REPORT_HELP.help_endpoint)
}

export function get_new_nd_report_help_url() {
  return get_help_article_url(NEW_ND_REPORT_HELP.help_endpoint)
}
export function get_classifier_report_help_url() {
  return get_help_article_url(CLASSIFIER_REPORT_HELP.help_endpoint)
}

export function get_utt_landscape_report_help_url() {
  return get_help_article_url(UTT_LANDSCAPE_REPORT_HELP.help_endpoint)
}

export function get_custom_clustered_report_help_url() {
  return get_help_article_url(BUILD_CC_REPORT_HELP.help_endpoint)
}

export function get_supported_publication_formats_help_url(){
  return get_help_article_url(PUBLICATION_FORMATS_HELP.help_endpoint)
}

export function get_family_list_fields_help_url() {
  return get_help_article_url(FAMILY_LIST_COLUMNS_HELP.help_endpoint)
}

export const DATASET_HELP_LINK_CLASSNAME = 'help_article_link'

export function get_help_article_url(help_article) {
  if (help_article) {
    return HELP_CENTRE_URL + `${PATH_PREFIX}/articles/${help_article}`
  }
  return null
}

export function on_click_from_help_link(help_url) {
  const pathname = get_pathname()

  const help_section = get_help_article_from_url(help_url)
  const page = (pathname || '').split('/')[1] || ''

  track_support_event(`action="help_article_open" help_article="${help_section}" page="/${page}"`)
}

