import _ from 'underscore'
import axios from 'axios'
import qs from 'query-string'
import moment from 'moment'

import {
  CLASSIFIER_LANDSCAPE_REPORT_TYPE,
  COMPARABLES_IN_SPEEDY_REPORT_COUNT,
  DEFAULT_REPORT_TYPE,
  MAX_REPORT_NAME_LENGTH,
  ND_REPORT_TYPE,
  NO_REBUILD_REPORT_TYPES,
  UTT_LANDSCAPE_REPORT_TYPE,
  UTT_REPORT_TYPE
} from '../constants/constants.js'

import {
  get_boolean_search_as_portfolio_item,
  get_org_as_portfolio_item,
  get_patent_upload_as_portfolio_item,
  is_family_tag_type,
  is_patent_families_type,
  is_tech_search_type
} from '../model/portfolio_basket.js'
import { is_int, is_number } from './utils.js'
import { add_source_err_to_target_err } from './axios_utils.js'
import { save_report_created, update_report_internal_id } from './report_created_utils.js'
import { check_for_existing_and_in_progress_reports, create_report_on_choreo } from './choreo_utils.js'
import { STATUS_QUEUED } from '../model/report_tasks_and_statuses.js'
import { get_data_version } from './domain_utils.js'
import { fetch_report_input } from './report_reader_utils.js'
import { REPORT_INPUT_BASE_URL, REPORT_STREAM_BASE_URL, SUBSET_REPORT_GET_INPUT_URL } from '../constants/urls.js'

import { get_short_company_name, remove_last_special_character_from_text } from './name_utils.js'
import {
  check_for_invalid_organisations,
  get_org_item_size_input,
  get_org_suggestions_by_org_ids,
  get_organisation_size,
  is_organisation,
  is_org_group_type,
  is_org_type
} from './organisation_utils.js'
import {
  build_classifier_alert_report_input,
  build_generic_builder_report_input,
  build_manual_clustering_report_input,
  build_utt_landscape_report_input
} from './report_input_utils.js'
import { is_classifier_landscape_report_type, is_nd_report_type } from './report_utils.js'
import { get_family_ids_filtered, get_bool_search_items_sizes } from './patent_family_list_utils.js'
import { create_search_name, find_similar_families_by_input_id } from './knn_search.js'
import { COMPARABLES_BY_SIMILAR_SIZE } from '../model/organisation.js'
import { get_id_to_classifier } from './classifier_group_utils.js'
import { get_clean_classifier_description } from './classifier_description_utils.js'
import { BUILD_ND_REPORT, BUILD_REPORT, REPORT } from '../constants/paths.js'
import { EXPORT_SUBPATH, SELECTED_SUBPATH } from '../constants/viewer_paths.js'
import { ALERT_SETUP_ID, GOOGLE_VALUATION_ID, ND_REPORT_ID } from '../model/hyperscripts.js'
import { is_poweruser } from './user_permissions.js'
import {
  AVAILABLE_SCORE_THRESHOLDS_BY_VALUE,
  get_technology_partitioning_by_utt,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT,
  TECH_PARTITIONING_TYPES
} from '../model/technology_basket.js'
import { add_parent_refs, get_leaf_nodes_as_array } from './classifier_tree_utils'
import { PORTFOLIO_SIZE_GROUP_ID } from '../model/spec_groups/spec_group_ids.js'
import { ALL_FAMILIES_BY_TECH_ID } from '../model/spec_ids.js'
import { save_eval_report_selected_charts_in_state } from './report_state_utils.js'
import { get_from_local_storage } from './local_storage_utils.js'
import {
  ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION,
  ACCESSION_DATE_CUT_OFF_OPTIONS,
  ACCESSION_DATE_PORTFOLIO_ITEM,
  ALL_FAMILIES_LANDSCAPE_OPTION_ID,
  CACHED_REPORT_THRESHOLD_RELAXED,
  CACHED_REPORT_THRESHOLD_STRICT,
  CACHED_REPORT_THRESHOLDS_BY_ID,
  GROUP_BY_OWNER_LEVEL_LS_KEY,
  ID_TO_LANDSCAPE_GROUP,
  LANDSCAPE_PORTFOLIO_CONNECTOR,
  PORTFOLIO_ROLLUP_LIMIT_LS_KEY,
  PORTFOLIO_SEARCH_TYPE_BY_ID,
  PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID,
  PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID,
  PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID,
  PORTFOLIO_SEARCH_TYPE_ORG_SETS_ID,
  SCORE_THRESHOLD_LS_KEY,
  SEARCH_PHRASE_TO_LANDSCAPE,
  TECH_SPLIT_TYPE_LS_KEY
} from '../constants/report_builder.js'
import { DEFAULT_GROUP_BY_OWNER_LEVEL, ID_TO_GROUP_BY_OWNER_LEVELS } from '../model/group_by_owner_level.js'
import { AVAILABLE_PORTFOLIO_ROLLUP_LIMITS, DEFAULT_PORTFOLIO_ROLLUP_LIMIT } from '../model/portfolio_rollup_limits.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../constants/report_input.js'
import { get_short_date_string_for_now } from './time_utils.js'
import { UTT_GROUP_NAME } from '../constants/utt.js'

export function get_default_portfolio_search_mode({portfolio_search_mode, is_valuation_report, is_nd_report}) {
  const available_portfolio_search_modes = [...Object.keys(PORTFOLIO_SEARCH_TYPE_BY_ID), PORTFOLIO_SEARCH_TYPE_ORG_SETS_ID]

  if (portfolio_search_mode == null || (available_portfolio_search_modes.indexOf(portfolio_search_mode) === -1)) {
    if (is_valuation_report) {
      return PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID]
    }

    if (is_nd_report) {
      return PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID]
    }

    return PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID]
  }

  return portfolio_search_mode === PORTFOLIO_SEARCH_TYPE_ORG_SETS_ID ? PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID] : PORTFOLIO_SEARCH_TYPE_BY_ID[portfolio_search_mode]
}

export function get_accession_start_date_by_days(days_count=ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION) {
  const {date_format} = ACCESSION_DATE_PORTFOLIO_ITEM
  return moment().subtract(days_count, 'days').format(date_format)
}

export function get_accession_date_portfolio_item(param) {
  const {prefix, suffix, connector} = ACCESSION_DATE_PORTFOLIO_ITEM

  const days_count_param = param * 1
  const threshold = is_int(days_count_param) && ACCESSION_DATE_CUT_OFF_OPTIONS.indexOf(days_count_param) > -1 ? days_count_param : ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION

  const portfolio_item = get_boolean_search_as_portfolio_item(`${prefix}${get_accession_start_date_by_days(threshold)}${connector}${get_short_date_string_for_now()}${suffix}`)

  return {...portfolio_item, name: `${days_count_param} days`}
}

export function get_classifiers_group_names(classifiers) {
  return _.uniq((classifiers || []).map(item => {
    const {path} = item

    return (path || [])[0] || ''
  }))
}

export function is_utt_among_classifiers(classifiers) {
  if ((classifiers || []).length === 0) return false

  return get_classifiers_group_names(classifiers).indexOf(UTT_GROUP_NAME) !== -1
}

export function get_all_available_taxonomy_ids(classifier_groups) {
  if (!classifier_groups) return []

  return classifier_groups.map(group => group.id)
}

export function get_preselected_technology_basket_for_eval_classifier(classifier_id, classifier_groups) {
  const id_to_classifier = get_id_to_classifier(classifier_groups)

  const eval_classifier = id_to_classifier[classifier_id]
  if (!eval_classifier) {
    return []
  }

  const { name, description, taxonomy_path } = eval_classifier
  return build_technology_basket_for_single_classifier(classifier_id, name, description, taxonomy_path)
}

export function build_technology_basket_for_single_classifier(classifier_id, classifier_title, classifier_description, taxonomy_path) {

  const description = get_clean_classifier_description(classifier_description)

  return [{
    path: taxonomy_path || [], // must be non-null
    classifier_id,
    name: classifier_title,
    ...description ? {description} : {}
  }]
}

function get_comparables_for_speedy_org_report(org, is_fetch_comparables) {
  if (!is_fetch_comparables) return Promise.resolve({})

  return get_org_suggestions_by_org_ids([org])
}

export function run_speedy_org_report(org, report_type, is_fetch_comparables, utt_version) {
  const report_name = org.name

  return get_comparables_for_speedy_org_report(org, is_fetch_comparables)
    .then(comparables => {
      const org_suggestions = (comparables || {})[COMPARABLES_BY_SIMILAR_SIZE] || []
      const portfolios = [get_org_as_portfolio_item(org), ...org_suggestions.slice(0, COMPARABLES_IN_SPEEDY_REPORT_COUNT).map(item => get_org_as_portfolio_item(item))]
      const report_input = build_generic_builder_report_input({
        report_name,
        portfolios,
        report_type: report_type || DEFAULT_REPORT_TYPE,
        portfolios_to_cluster: [0],
        utt_version
      })

      return existing_or_new_report(report_input, report_name, CACHED_REPORT_THRESHOLD_STRICT)
    })
}

export function run_speedy_utt_landscape_report(classifier, report_name, utt_version) {
  const report_input = build_utt_landscape_report_input({report_name, classifier, utt_version})
  return existing_or_new_report(report_input, report_name, CACHED_REPORT_THRESHOLD_STRICT)
}

export function run_manual_clustering_report(user_report_name, portfolios, technologies, evaluation_classifier_id) {
  const auto_report_name = 'Custom clustered report'
  const report_input = build_manual_clustering_report_input({report_name: auto_report_name, portfolios, technologies, evaluation_classifier_id})

  return build_report(report_input, user_report_name)
}

export function run_classifier_alert_report(report_name, pat_fam_ids, classifier_name)
{
  const report_input = build_classifier_alert_report_input({
    report_name,
    pat_fam_ids,
    classifier_name
  })
  return build_report(report_input, report_name)
}

export function existing_or_new_report(report_input, report_name, cached_report_threshold_type) {
  if (cached_report_threshold_type == null) {
    return build_report(report_input, report_name)
  }

  return Promise.all([check_for_existing_and_in_progress_reports(report_input), get_data_version()])
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to check for existing reports: ')
      throw wrapped_err
    })
    .then(([{completed_report}, current_data_version]) => {
      if (can_use_existing_report(completed_report, current_data_version.data_version, cached_report_threshold_type)) {
        const {report_id:existing_internal_report_id} = completed_report || {}
        return existing_internal_report_id ? save_new_report_with_existing_internal_id({existing_internal_report_id, report_name, report_input}) : build_report(report_input, report_name)
      } else {
        return build_report(report_input, report_name)
      }
    })
}

export function can_use_existing_report(existing_report, current_data_version, threshold_mode) {
  if (!existing_report) return false

  const {data_version_difference, age_in_days} = CACHED_REPORT_THRESHOLDS_BY_ID[threshold_mode] || {}

  const is_age_ok = age_in_days ? is_existing_report_age_ok(existing_report, age_in_days) : true // no need to check if reports must be from the same data version
  const is_version_ok = is_existing_report_data_version_ok(existing_report, current_data_version, data_version_difference)

  return ( is_age_ok && is_version_ok )
}

export function get_version_appropriate_existing_reports(existing_report, in_progress_report, data_version) {
  const existing_report_clean    = can_use_existing_report(existing_report,    data_version, CACHED_REPORT_THRESHOLD_RELAXED) ? existing_report    : null
  const in_progress_report_clean = can_use_existing_report(in_progress_report, data_version, CACHED_REPORT_THRESHOLD_RELAXED) ? in_progress_report : null
  return {
    existing_report:    existing_report_clean,
    in_progress_report: in_progress_report_clean
  }
}

function is_existing_report_age_ok(existing_report, threshold) {
  const { timestamp } = existing_report
  const now = moment(new Date())
  const report_date_created = moment(timestamp)
  const report_age = moment.duration(now.diff(report_date_created))

  return report_age.asDays() <= threshold
}

function is_existing_report_data_version_ok(existing_report, current_data_version, threshold) {
  const report_data_version = existing_report.data_version
  return (report_data_version !== -1) && (is_number(current_data_version)) && (parseInt(current_data_version) - report_data_version <= threshold)
}

export function save_new_report_with_existing_internal_id({existing_internal_report_id, report_name, report_input}) {
  const report_type = get_report_type(report_input)
  const { meta={}, name } = report_input
  const { evaluation_classifier_id } = (meta || {})
  return save_report_created(existing_internal_report_id, (report_name || name), report_type, null /* external_report_id */, evaluation_classifier_id)
}

export function rerun_report_and_replace_id(internal_report_id, external_report_id) {
  return fetch_report_input(internal_report_id)
    .then(report_input => create_report_on_choreo(report_input))
    .then(choreo_response => choreo_response.report_id)
    // update the frontend database with the new report id
    .then(new_internal_report_id => update_report_internal_id(external_report_id, new_internal_report_id))
}

export function get_report_type(report_input) {
  return report_input['report_type'] || DEFAULT_REPORT_TYPE
}

export function build_report(report_input, db_meta_report_name) {
  const report_type = get_report_type(report_input)

  const { meta={}, name } = report_input
  const { evaluation_classifier_id } = (meta || {})
  const title = db_meta_report_name || name // save user-entered report name to the FE only

  return create_report_on_choreo(report_input)
    .then(choreo_response => choreo_response.report_id)
    .then(internal_report_id => save_report_created(internal_report_id, title, report_type, null /* external_report_id */, evaluation_classifier_id))
}

export function get_new_report_from_existing_url(external_report_id, report_type, start) {
  let new_url = report_type && is_nd_report_type(report_type) ? BUILD_ND_REPORT : BUILD_REPORT

  if (external_report_id) {
    const new_query = {
      base_report: external_report_id,
      ...(start ? {start} : {})
    }
    return `${new_url}?${qs.stringify(new_query)}`
  }
  return new_url
}

export function new_report_from_existing(external_report_id, history, report_type, start) {
  history.push(get_new_report_from_existing_url(external_report_id, report_type, start) )
}

export function report_can_be_built_from_params(report) {
  const {report_type, status, evaluation_classifier_id} = report

  if (status && status === STATUS_QUEUED) {
    return false
  }
  return !(
    _.contains(NO_REBUILD_REPORT_TYPES, report_type) || //strict list of report types not available for rebuilding
    (is_classifier_landscape_report_type(report_type) && (evaluation_classifier_id != null)) //disable rebuilding if report is classifier eval landscape report
  )
}

export function get_subpath_for_report_link({is_nd_report, is_valuation_report, evaluation_classifier_id, alert_id, is_comparison_report}) {
  const export_base_subpath = `/${EXPORT_SUBPATH}/`
  if (alert_id) {
    return export_base_subpath + `${ALERT_SETUP_ID}/${alert_id}`
  } else if (is_nd_report) {
    return export_base_subpath + ND_REPORT_ID
  } else if (is_valuation_report) {
    return export_base_subpath + GOOGLE_VALUATION_ID
  } else if (is_comparison_report) {
    return `/g/${PORTFOLIO_SIZE_GROUP_ID}/d/${ALL_FAMILIES_BY_TECH_ID}` // show all families by tech dataset
  }
  return (evaluation_classifier_id != null) ? `/${SELECTED_SUBPATH}` : ''
}

export function new_eval_report(report_input, report_name, evaluation_classifier_id, history) {
  return build_report(report_input, report_name)
    .then(external_report_id => {
      const report_subpath = get_subpath_for_report_link({evaluation_classifier_id})
      return save_eval_report_selected_charts_in_state(external_report_id)
        .then(() => {
          history.push(`${REPORT}/${external_report_id}${report_subpath}`)
        })
    })
}

export function get_classifier_landscape_report_name(selected_classifiers) {
  const suffix = `${selected_classifiers.length > 4 ? ' etc.' : ''} (landscape)`
  const available_length = MAX_REPORT_NAME_LENGTH - suffix.length

  const titles = [...selected_classifiers].slice(0, (selected_classifiers.length < 5) ? selected_classifiers.length : 3).map(item => (item.name)).join(', ')

  function build_report_name_from_titles(titles, available_length) {
    if (titles.length <= available_length) {
      return titles
    }

    const report_name = titles.substr(0, available_length)

    if (titles[available_length] !== ' ' && report_name[available_length - 1] !== ' ') {
      //doing this to remove the last word if it got truncated in the middle by the substr operation
      const chunks = report_name.split(' ')

      chunks.pop()

      return chunks.join(' ')
    }

    return report_name
  }

  return build_report_name_from_titles(titles, available_length) + suffix
}

export function get_report_type_from_input({portfolios, technology_partitioning, selected_landscape_option, selected_new_families_option, is_nd_report}) {
  const {type} = technology_partitioning || {}

  if (is_nd_report) {
    return ND_REPORT_TYPE
  }

  const is_tech_landscape = _.some(portfolios || [], item => item.is_landscape)
  if (is_tech_landscape) {
    if (TECH_PARTITIONING_TYPE_UTT === type) {
      const {id} = selected_landscape_option

      const is_utt_landscape = (id === ALL_FAMILIES_LANDSCAPE_OPTION_ID) && (selected_new_families_option == null)

      return is_utt_landscape ? UTT_LANDSCAPE_REPORT_TYPE : UTT_REPORT_TYPE
    }

    return CLASSIFIER_LANDSCAPE_REPORT_TYPE
  }

  return TECH_PARTITIONING_TYPE_UTT === type ? UTT_REPORT_TYPE : DEFAULT_REPORT_TYPE
}

export function get_default_report_name({portfolios, classifiers, technology_partitioning, selected_landscape_option, selected_new_families_option, is_nd_report }) {
  const report_type = get_report_type_from_input({portfolios, technology_partitioning, selected_landscape_option, selected_new_families_option})

  const portfolio_names = (portfolios || []).map(item => item.name || '')
  const classifier_names = (classifiers || []).map(item => item.name || '')

  if ([CLASSIFIER_LANDSCAPE_REPORT_TYPE, UTT_LANDSCAPE_REPORT_TYPE].indexOf(report_type) !== -1) {
    return get_tech_landscape_report_name({classifier_names, portfolio_names, default_name: 'landscape', suffix: 'landscape'})
  }

  //utt landscapes with options selected are not landscapes
  const is_tech_landscape = _.some(portfolios || [], item => item.is_landscape)
  if (is_tech_landscape && (selected_new_families_option != null)) {
    return get_tech_landscape_report_name({default_name: 'New families in technology space', suffix: 'new families', portfolio_names, classifier_names})
  }

  if (is_tech_landscape && (selected_landscape_option != null)) {
    return get_tech_landscape_report_name({default_name: 'Technology landscape', suffix: 'landscape', portfolio_names, classifier_names})
  }

  if (is_nd_report) {
    const prefix = 'n/d '
    return `${prefix}${pick_report_name(portfolio_names.map(name => ({name})), MAX_REPORT_NAME_LENGTH - prefix.length)}`
  }

  return pick_report_name(portfolios || [])
}


export function get_tech_landscape_report_name({default_name, suffix, portfolio_names, classifier_names}) {
  if ((classifier_names || []).length === 0) return default_name

  const portfolio_names_suffix = (portfolio_names || []).length > 0 ? portfolio_names.join(' '): ''

  const suffix_extended = ` - ${suffix}${portfolio_names_suffix.trim() !== '' ? `, ${portfolio_names_suffix.trim()}` : ''}`

  return `${pick_report_name(classifier_names.map(name => ({name})), MAX_REPORT_NAME_LENGTH - suffix_extended.length)}${suffix_extended}`
}

export function pick_report_name(items=[], max_length=MAX_REPORT_NAME_LENGTH) {
  let report_name = ''

  //this is literally taken from the old portfolio_basket and just tweaked a little to adjust to different portfolio_basket structure
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    let name = is_org_type(item) ? get_short_company_name(item.name) : item.name
    name = remove_last_special_character_from_text(name)
    if (i !== 0) {
      if (report_name.length + name.length + 9 > max_length) {
        return report_name + ' et al.'
      }
      report_name += ', '
    }
    report_name += name
  }
  return report_name
}

export function get_verified_portfolios({portfolios}) {
  const portfolio_org_ids = []
  portfolios.forEach(item => {
    const {id, members=[]} = item

    if (is_organisation(item)) {
      portfolio_org_ids.push(id)
    }

    if (is_org_group_type(item)) {
      members.forEach(group_item => {
        const {id: group_item_id} = group_item

        if (is_organisation(group_item)) {
          portfolio_org_ids.push(group_item_id)
        }})
    }
  })
  
  return check_for_invalid_organisations({org_ids: _.uniq(portfolio_org_ids)})
    .then(invalid_org_ids => {
      const updated_portfolios = []
      const invalid_portfolios = []

      if (invalid_org_ids.length === 0) {
        return { portfolios }
      }

      portfolios.forEach(item => {
        const {id, members=[]} = item

        let is_valid = true


        if (is_organisation(item)) {
          is_valid = invalid_org_ids.indexOf(id) === -1
        }

        if (is_org_group_type(item)) {
          const group_members_org_ids = []

          members.forEach(group_item => {
            const {id: group_item_id} = group_item
            if (is_organisation(group_item)) {
              group_members_org_ids.push(group_item_id)
          }})

          is_valid = _.intersection(group_members_org_ids, invalid_org_ids).length === 0
        }

        return is_valid ? updated_portfolios.push(item) : invalid_portfolios.push(item)
      })

      return { portfolios: updated_portfolios, invalid_portfolios }
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to verify base report portfolios: ')
      throw wrapped_err
    })
}

export function get_portfolio_basket_orgs_total_size(portfolio_basket) {
  let all_organisation_ids = []
  let all_assignee_ids = []

  portfolio_basket.forEach(item => {
    if (!is_org_type(item) && !is_org_group_type(item)) return
    const {organisation_ids=[], assignee_ids=[]} = get_org_item_size_input(item)

    all_organisation_ids = [...all_organisation_ids, ...organisation_ids]
    all_assignee_ids = [...all_assignee_ids, ...assignee_ids]
  })

  return get_organisation_size({organisation_ids: all_organisation_ids, assignee_ids: all_assignee_ids})
}

export function prepare_base_report_portfolios({portfolios}) {
  return get_verified_portfolios({portfolios})
    .then(({ portfolios, invalid_portfolios }) =>{
      const organisations = []
      const keyword_search = []

      portfolios.forEach(item => {
        if (is_org_type(item) || is_org_group_type(item)) {
          organisations.push(item)
        }

        if (is_tech_search_type(item)) {
          keyword_search.push(item.search_term)
        }
      })

      return Promise.all([
        get_portfolio_basket_orgs_total_size(portfolios),
        get_bool_search_items_sizes(keyword_search)
      ])
        .then(([organisations_total_size, keyword_search_items_sizes]) => {
          const portfolio_sizes = (portfolios || []).map(item => {
            const {pat_fam_ids, search_term} = item || {}

            if (is_patent_families_type(item) || is_family_tag_type(item)) return (pat_fam_ids || []).length

            if (is_tech_search_type(item)) {
              return (keyword_search_items_sizes|| {})[search_term] || 0
            }

            return 0 //we only care for keyword search and patent upload sizes (org sizes are accumulated in organisations_total_size)
          })
          return {portfolios, portfolio_sizes, organisations_total_size, invalid_portfolios}
        })
    })
}

export function get_basket_portfolio_total_size(portfolio_basket, portfolio_basket_sizes, portfolio_basket_orgs_total_size=0) {
  if (!portfolio_basket || portfolio_basket.length === 0) {return 0}

  let non_org_items_total_size = 0

  portfolio_basket.forEach((item, i) => {
    if(!is_org_type(item) && !is_org_group_type(item)) {
      non_org_items_total_size = non_org_items_total_size + (portfolio_basket_sizes[i] || 0)
    }
  })

  return non_org_items_total_size + portfolio_basket_orgs_total_size
}

function find_handler_to_get_input_by_type(input_type) {
  switch (input_type) {
    case 'report_stream': return fetch_report_stream_report_input
    default: return fetch_clickthrough_report_input
  }
}

export function fetch_input_by_id({input_id, knn_search_input}) {
  if (knn_search_input != null) {
    return fetch_similar_families_report_input(knn_search_input)
  }

  return axios.get(`${REPORT_INPUT_BASE_URL}/${input_id}/type`)
    .then(response => {
      const {data} = response || {}
      const {input_type} = data || {}
      const handler = find_handler_to_get_input_by_type(input_type)
      return handler(input_id)
    })
}

export function fetch_similar_families_report_input(id) {
  return find_similar_families_by_input_id(id)
    .then(response => {
      const { results, input, settings, name } = response || {}
      const { search_phrase = '' } = settings || {}

      return Promise.all([get_family_ids_filtered(results || [], search_phrase), input, settings, name])
    })
    .then(([pat_fam_ids, input, settings, name]) => {
      const { report_type, evaluation_classifier_id } = settings || {}

      const portfolio_name = name || create_search_name(input)

      return {
        portfolios: [get_patent_upload_as_portfolio_item({
          name: portfolio_name !== '' ? portfolio_name : 'similar families',
          pat_fam_ids: pat_fam_ids,
          group_by_owner: true
        })],
        portfolio_sizes: [pat_fam_ids.length],
        report_type: report_type || DEFAULT_REPORT_TYPE,
        evaluation_classifier_id
      }
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch similar families report input by id ${id}: `)
      throw wrapped_err
    })
}

export function fetch_clickthrough_report_input(input_id) {
  return axios.get(`${SUBSET_REPORT_GET_INPUT_URL}/${input_id}`)
    .then(response => {
      const {data} = response || {}

      const {items=[], technology_partitioning, evaluation_classifier_id} = data || {}
      const portfolios = []
      const portfolio_sizes = []

      items.forEach(item => {
        const {families, name, is_rollup, group_by_owner} = item
        portfolios.push(get_patent_upload_as_portfolio_item({
          name,
          pat_fam_ids: families,
          group_by_owner: (group_by_owner != null) ? group_by_owner : (is_rollup === true)
        }))
        portfolio_sizes.push(families.length)
      })

      return {portfolios, portfolio_sizes, technology_partitioning, evaluation_classifier_id}
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch clickthrough report input by id ${input_id}: `)
      throw wrapped_err
    })
}

export function fetch_report_stream_report_input(input_id) {
  const data = {id: input_id}

  return axios.post(`${REPORT_STREAM_BASE_URL}/get_input`, data)
    .then(response => {
      const { request_data } = response.data

      const {name, portfolios, technology_partitioning } = request_data || {}

      return prepare_base_report_portfolios({portfolios})
        .then(response => {

          return {
            ...response || {},
            report_name: name,
            technology_partitioning
          }
        })
    })

    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch report stream input by id ${input_id}: `)
      throw wrapped_err
    })
}

export function run_report_for_similar_families({report_name, pat_fam_ids, use_utt, utt_version}) {
  const portfolios = [get_patent_upload_as_portfolio_item({
    name: 'similar families',
    pat_fam_ids,
    group_by_owner: true
  })]

  const report_input = build_generic_builder_report_input({
    report_name,
    report_type: use_utt === true ? UTT_REPORT_TYPE : DEFAULT_REPORT_TYPE,
    portfolios,
    portfolios_to_cluster: [0],
    utt_version
  })

  return existing_or_new_report(report_input, report_name, CACHED_REPORT_THRESHOLD_STRICT)
}

export function get_classifier_path(classifier) {
  // find path value (if available) for a user-built or taxonomy classifier
  const {parent, taxonomy_path} = classifier
  return parent || taxonomy_path || []
}

export function build_technology_basket_from_product(classifiers) {
  const classifiers_with_parent_ref = add_parent_refs(classifiers)

  return get_leaf_nodes_as_array(classifiers_with_parent_ref).map(item => {
    const {name, description, classifier_id, version} = item
    const path = get_classifier_path(item)
    return {name, description, classifier_id, version, path}
  })
}

export function enable_tech_partitioning_by_classifiers({has_classifiers}) {
  return has_classifiers
}

export function enable_tech_partitioning_by_utt({is_report_too_big, is_nd_report, is_valuation_report}) {
  return !is_report_too_big && !(is_nd_report || is_valuation_report)
}

export function enable_tech_partitioning_by_super_utt({is_report_too_big, is_nd_report, is_valuation_report, is_landscape}) {
  return enable_tech_partitioning_by_utt({is_report_too_big, is_nd_report, is_valuation_report}) && !is_landscape
}

export function enable_tech_partitioning_by_clustering({is_report_big, is_nd_report, is_valuation_report, is_landscape}) {
  return !is_report_big && !(is_nd_report || is_valuation_report) && !is_landscape
}

export function get_default_group_by_owner_level() {
  const ls_setting = get_from_local_storage(GROUP_BY_OWNER_LEVEL_LS_KEY) || ''

  return ID_TO_GROUP_BY_OWNER_LEVELS[ls_setting] != null ? ls_setting : DEFAULT_GROUP_BY_OWNER_LEVEL
}

export function get_default_portfolio_roll_up_limit() {
  const ls_setting = get_from_local_storage(PORTFOLIO_ROLLUP_LIMIT_LS_KEY) || ''

  return AVAILABLE_PORTFOLIO_ROLLUP_LIMITS.indexOf(ls_setting) > -1 ? ls_setting : DEFAULT_PORTFOLIO_ROLLUP_LIMIT
}

export function get_default_score_threshold({user}) {
  const ls_setting = get_from_local_storage(SCORE_THRESHOLD_LS_KEY) || ''
  const score_by_ls_setting = AVAILABLE_SCORE_THRESHOLDS_BY_VALUE[get_from_local_storage(SCORE_THRESHOLD_LS_KEY) || '']

  if (score_by_ls_setting == null) return CLASSIFIER_SCORE_THRESHOLD_DEFAULT

  const {only_poweruser} = score_by_ls_setting

  return only_poweruser ? (user && is_poweruser(user)) ? ls_setting : CLASSIFIER_SCORE_THRESHOLD_DEFAULT : ls_setting
}

function get_tech_partitioning_by_setting_and_availability(
  {
    has_classifiers,
    is_report_big,
    is_report_too_big,
    is_nd_report,
    is_valuation_report,
    is_landscape,
    utt_version,

    setting
  }) {
  const {type, use_superclasses} = setting || {}

  if ((setting == null) || (type == null) || (TECH_PARTITIONING_TYPES.indexOf(type) === -1 )) return null

  if (type === TECH_PARTITIONING_TYPE_CLASSIFIER && enable_tech_partitioning_by_classifiers({has_classifiers})) {
    return { type }
  }

  if (type === TECH_PARTITIONING_TYPE_UTT && !use_superclasses && enable_tech_partitioning_by_utt({is_report_too_big, is_nd_report, is_valuation_report})) {
    return get_technology_partitioning_by_utt({ utt_version, use_utt_superclasses: use_superclasses })
  }

  if (type === TECH_PARTITIONING_TYPE_UTT && use_superclasses && enable_tech_partitioning_by_super_utt({is_report_too_big, is_nd_report, is_valuation_report, is_landscape})) {
    return get_technology_partitioning_by_utt({ utt_version, use_utt_superclasses: use_superclasses })
  }

  if (type === TECH_PARTITIONING_TYPE_CLUSTERING && enable_tech_partitioning_by_clustering({is_report_big, is_nd_report, is_valuation_report, is_landscape})) {
    return { type }
  }

  return null
}

export function get_default_tech_partitioning(
  {
    has_classifiers,
    is_report_big,
    is_report_too_big,
    is_nd_report,
    is_valuation_report,
    is_landscape,
    utt_version
  }) {
  const tech_partitioning_default = has_classifiers ? { type: TECH_PARTITIONING_TYPE_CLASSIFIER } : get_technology_partitioning_by_utt({ utt_version })

  const ls_setting = get_from_local_storage(TECH_SPLIT_TYPE_LS_KEY)

  const {type} = ls_setting || {}

  if ((ls_setting == null) || (type == null) || (TECH_PARTITIONING_TYPES.indexOf(type) === -1 )) return tech_partitioning_default

  const tech_partitioning_preferred = get_tech_partitioning_by_setting_and_availability(
    {
      has_classifiers,
      is_report_big,
      is_report_too_big,
      is_nd_report,
      is_valuation_report,
      is_landscape,
      utt_version,

      setting: ls_setting
    })

  return tech_partitioning_preferred ? tech_partitioning_preferred : tech_partitioning_default
}

export function retrieve_portfolio_landscape_option_from_report_input(report_input) {
  const { prefix } = ACCESSION_DATE_PORTFOLIO_ITEM
  const { portfolios } = report_input || {}
  const { search_term } = (portfolios || [])[0] || {}

  if (search_term == null) return ID_TO_LANDSCAPE_GROUP[ALL_FAMILIES_LANDSCAPE_OPTION_ID]

  const search_term_chunks = (search_term || '').split(LANDSCAPE_PORTFOLIO_CONNECTOR)
  const landscape_search_term = search_term_chunks.filter(item => !item.startsWith(prefix))[0]

  const landscape_by_search_phrase = SEARCH_PHRASE_TO_LANDSCAPE[landscape_search_term] || {}

  const {id} = landscape_by_search_phrase || {}

  return ID_TO_LANDSCAPE_GROUP[id] || landscape_by_search_phrase
}

export function retrieve_new_families_landscape_option_from_report_input(report_input) {
  const {date_format, prefix, suffix, connector} = ACCESSION_DATE_PORTFOLIO_ITEM

  const { portfolios } = report_input || {}
  const { search_term } = (portfolios || [])[0] || {}
  const search_term_chunks = (search_term || '').split(LANDSCAPE_PORTFOLIO_CONNECTOR)
  const time_search_term = search_term_chunks.filter(item => item.startsWith(prefix))[0]
  if (time_search_term == null) return null

  const [date_from, date_to] = time_search_term.replace(prefix, '').replace(suffix, '').split(connector)

  if ((date_from == null) || (date_to == null)) return null

  const date_from_moment = moment(date_from, date_format)
  const date_to_moment = moment(date_to, date_format)

  const days = date_to_moment.diff(date_from_moment, 'days')

  return ACCESSION_DATE_CUT_OFF_OPTIONS.indexOf(days) > -1  ? days : null
}
