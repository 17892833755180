import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'

import ContainerFixedWidth from '../ContainerFixedWidth.js'
import { Heading } from '../widgets/PaneHeader.js'
import TextLink from '../widgets/TextLink.js'
import { track_visit_event } from '../../utils/tracking_utils.js'
import { SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH, scroll_to_top } from '../../utils/scroll_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './PatentFamilyListGuide.module.scss'
import { is_tech_discovery } from '../../utils/utils.js'

const ACCEPTED_GEO_CODES_SECTION_URL_HASH = '#accepted-geo-codes'

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th className={s.table_column_width_30}>Field</th>
        <th>Example</th>
        <th className={s.table_column_width_40}>Searches</th>
      </tr>
    </thead>
  )
}

const GeoTableHeader = () => {
  return (
    <thead>
      <tr>
        <th className={s.table_column_width_50}>Territory</th>
        <th>Code</th>
      </tr>
    </thead>
  )
}

const SearchExampleLink = ({search_phrase, className, children}) => {
  if (is_tech_discovery()) {
    return (<span>{children}</span>)
  }

  return (
    <TextLink
      element='a'
      href={`/build-report?families_search=${search_phrase}&portfolio_search_mode=families_search`}
      target='_blank'
      className={className}
    >
      {children}
    </TextLink>
  )
}

const PatentFamilyListGuide = (
  {
    location,
    history,
  }) => {
  document.title = 'Cipher: Search Guide'
  track_visit_event('page="search_guide"')

  const accepted_geo_codes_table_ref = useRef()

  useEffect(() => {
    const { hash } = location

    if ((hash === ACCEPTED_GEO_CODES_SECTION_URL_HASH) && accepted_geo_codes_table_ref) {
      scroll_to_top(accepted_geo_codes_table_ref, SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH)
    }

  }, [location, accepted_geo_codes_table_ref])

  return (
    <ContainerFixedWidth>

      <h2>Boolean search guide</h2>
      <h3>Example search queries</h3>
      <div>Must contain any instance of "electric" AND "toothbrush" (or "tooth" AND "brush") where the status is granted</div>
      <div>Example:{' '}
        <SearchExampleLink
          search_phrase='electric%2520AND%2520%28toothbrush%2520OR%2520%28tooth%2520AND%2520brush%29%29%2520status%253Agranted'
        >
          electric AND (toothbrush OR (tooth AND brush)) status:granted
        </SearchExampleLink>
      </div>

      <div className='mt-2'>
        Must contain any instance of "robot" (e.g. robot, robots, robotic, robotics) AND "vacuum" OR "hoover" where the status is alive (pending or granted) in 2018 and excludes patent families ONLY in China.
      </div>
      <div>
        NOTE: Cipher will search and include expired patents by default. Include the following as part of your search query if these aren’t required: AND alive_at:2018
      </div>

      <div>
        Example:{' '}
        <SearchExampleLink
          search_phrase='robot%2A%2520AND%2520%28vacuum%2520OR%2520hoover%29%2520alive_at%253A2018%2520NOT%2520only_in_territory%253ACN'
        >
          robot* AND (vacuum OR hoover) alive_at:2018 NOT only_in_territory:CN
        </SearchExampleLink>
      </div>

      <h3>Operators</h3>
      <div>The following operators connect your search strings. Use these operators to narrow or broaden your search query.</div>

      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.table_column_width_30}>Operator</th>
            <th>Example</th>
            <th className={s.table_column_width_40}>Searches</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A</td>
            <td>
              <SearchExampleLink
                search_phrase='vacuum'
              >
                vacuum
              </SearchExampleLink>
            </td>
            <td>Must contain A, i.e. must contain the word "vacuum"</td>
          </tr>
          <tr>
            <td colSpan={1} rowSpan={2}>A AND B</td>
            <td>
              <SearchExampleLink
                search_phrase='vacuum%2520AND%2520cleaner'
              >
                vacuum AND cleaner
              </SearchExampleLink>
            </td>
            <td>Must contain A and B, i.e. must contain the words "vacuum" and "cleaner"</td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='%28vacuum%2520cleaner%29'
              >
                (vacuum cleaner)
              </SearchExampleLink>
            </td>
            <td>The use of parentheses groups together the terms, i.e. must include "vacuum" and "cleaner"</td>
          </tr>
          <tr>
            <td>A OR B</td>
            <td>
              <SearchExampleLink
                search_phrase='vacuum%2520OR%2520hoover'
              >
                vacuum OR hoover
              </SearchExampleLink>
            </td>
            <td>Must contain A or B (or both), i.e. must contain the word "vacuum" and/or "hoover"</td>
          </tr>
          <tr>
            <td>NOT A</td>
            <td>
              <SearchExampleLink
                search_phrase='NOT%2520electric'
              >
                NOT electric
              </SearchExampleLink>
            </td>
            <td>
              <div>Must not contain A</div>
              <div>e.g. must not contain the word "electric"</div>
            </td>
          </tr>
          <tr>
            <td>A*</td>
            <td>
              <SearchExampleLink
                search_phrase='tele%2A'
              >
                tele*
              </SearchExampleLink>
            </td>
            <td>Wildcard multiple characters, i.e. must contain the word "tele" (television, telephone, telegraph, teleport)</td>
          </tr>
          <tr>
            <td>"A B"</td>
            <td>
              <SearchExampleLink
                search_phrase='%2522electric%2520vacuum%2520cleaner%2522'
              >
                "electric vacuum cleaner"
              </SearchExampleLink>
            </td>
            <td>Match exact phrase, i.e. must contain the phrase "electric vacuum cleaner"</td>
          </tr>
          <tr>
            <td>title: A</td>
            <td>
              <SearchExampleLink
                search_phrase='title%253Acleaner'
              >
                title:cleaner
              </SearchExampleLink>
            </td>
            <td>The field "title" must contain A, i.e. must contain the word "cleaner" in the patent title</td>
          </tr>
          <tr>
            <td>[A TO B]</td>
            <td>[2000 TO 2010]</td>
            <td>Range from A to B inclusive, i.e. range must include the years 2000 TO 2010</td>
          </tr>
          <tr>
            <td>{`{A TO B}`}</td>
            <td>{`{2000 TO 2010}`}</td>
            <td>Range from A to B exclusive, range must include the years 2001 TO 2009</td>
          </tr>
          <tr>
            <td>"word1 word2"~n</td>
            <td>"complementary oxide"~5</td>
            <td>Proximity operator to find words that are within a specific distance away, i.e. "complementary" and "oxide" within 5 words of each other</td>
          </tr>
        </tbody>
      </table>

      <h3>Supported fields</h3>
      <div>
        Cipher’s supported fields help narrow your search string. Use these fields when specifying your patent search to a specific area e.g. by jurisdiction.
      </div>

      <Heading text='Jurisdiction (See the bottom of this document for accepted country/territory codes)' className='mt-3' />
      <table className={s.table}>
        <TableHeader />
        <tbody>
          <tr>
            <td>
              <div>granted_in_territory:</div>
              <div>GIT:</div>
              <div>pending_in_territory:</div>
              <div>PIT:</div>
              <div>ever_existed_in_territory:</div>
              <div>EEIT:</div>
            </td>
            <td>
              <div className='d-flex flex-column'>
                <SearchExampleLink
                  search_phrase='granted_in_territory%253AGB'
                >
                  granted_in_territory:GB
                </SearchExampleLink>
                <SearchExampleLink
                  search_phrase='pending_in_territory%253AUS'
                >
                  pending_in_territory:US
                </SearchExampleLink>
                <SearchExampleLink
                  search_phrase='ever_existed_in_territory%253ACN'
                >
                  ever_existed_in_territory:CN
                </SearchExampleLink>
              </div>
            </td>
            <td>Patent families granted, or pending, or ever existed in the given territory</td>
          </tr>
          <tr>
            <td>
              <div>granted_in_region:</div>
              <div>GIR:</div>
              <div>pending_in_region:</div>
              <div>PIR:</div>
            </td>
            <td>
              <div className='d-flex flex-column'>
                <SearchExampleLink
                  search_phrase='granted_in_region%253AEUROPE'
                >
                  granted_in_region:EUROPE
                </SearchExampleLink>
                <SearchExampleLink
                  search_phrase='pending_in_region%253AROW'
                >
                  pending_in_region:ROW
                </SearchExampleLink>
              </div>
            </td>
            <td>
              <div>Patent families granted, or pending in the given region</div>
              <div>(see Note 1 below)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>granted_only_in_territory:</div>
              <div>GOIT:</div>
              <div>pending_only_in_territory:</div>
              <div>POIT:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='granted_only_in_territory%253AGB'
              >
                granted_only_in_territory:GB
              </SearchExampleLink>
            </td>
            <td>Patent families granted, or pending, ONLY in the given territory</td>
          </tr>
          <tr>
            <td>
              <div>granted_only_in_region:</div>
              <div>GOIR:</div>
              <div>pending_only_in_region:</div>
              <div>POIR:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='granted_only_in_region%253AEUROPE'
              >
                granted_only_in_region:EUROPE
              </SearchExampleLink>
            </td>
            <td>
              <div>Patent families granted, or pending, ONLY in the given region</div>
              <div>(see Note 1 below)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>NOT granted_only_in_territory:</div>
              <div>NOT GOIT:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='NOT%2520granted_only_in_territory%253AUS'
              >
                NOT granted_only_in_territory:US
              </SearchExampleLink>
            </td>
            <td>"NOT" : excludes patent families ONLY granted in US</td>
          </tr>
          <tr>
            <td>
              <div>only_in_territory:</div>
              <div>OIT:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='only_in_territory%253ACN'
              >
                only_in_territory:CN
              </SearchExampleLink>
            </td>
            <td>Patent families ONLY in given territory(s)</td>
          </tr>
          <tr>
            <td>
              <div>only_in_region:</div>
              <div>OIR:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='only_in_region%253AROW'
              >
                only_in_region:ROW
              </SearchExampleLink>
            </td>
            <td>
              <div>Patent families ONLY in given region(s)</div>
              <div>(see Note 1 below)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>first_filing_territory:</div>
              <div>FFT:</div>
            </td>
            <td>FFT:US</td>
            <td>Territory in which the earliest application in the family was made</td>
          </tr>
          <tr>
            <td colSpan={1} rowSpan={2}>
              Adding multiple territories after the search field
            </td>
            <td>Search field:(US AND GB AND CN)</td>
            <td>Patent families (granted, pending, ever existed in etc) in the territories US and GB and CN</td>
          </tr>
          <tr>
            <td>Search field:(US OR GB OR CN)</td>
            <td>Patent families (granted, pending, ever existed in etc) in the territories US or GB or CN</td>
          </tr>
        </tbody>
      </table>
      <div className={cs.font_weight_bold}>Note 1:</div>
      <div>Accepted regions: EUROPE, ROW (Rest of World), CHINA, JAPAN, KOREA, US, WO (WIPO)</div>

      <Heading text='Status and Age' className='mt-3'/>
      <table className={s.table}>
        <TableHeader />
        <tbody>
          <tr>
            <td>
              <div>pending_at:</div>
              <div>PAT:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='pending_at%253A2018'
              >
                pending_at:2018
              </SearchExampleLink>
            </td>
            <td>The status is pending in the year 2018</td>
          </tr>
          <tr>
            <td>
              <div>granted_at:</div>
              <div>GAT:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='granted_at%253A2017'
              >
                granted_at:2017
              </SearchExampleLink>
            </td>
            <td>The status is granted in the year 2017</td>
          </tr>
          <tr>
            <td>
              <div>alive_at:</div>
              <div>AAT:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='alive_at%253A2016'
              >
                alive_at:2016
              </SearchExampleLink>
            </td>
            <td>The status is alive (pending/granted) in the year 2016</td>
          </tr>
          <tr>
            <td>granted_projected:</td>
            <td>
              <SearchExampleLink
                search_phrase='granted_projected%253A2030'
              >
                granted_projected:2030
              </SearchExampleLink>
            </td>
            <td>Patent families projected to be alive AND granted in the year 2030</td>
          </tr>
          <tr>
            <td colSpan={1} rowSpan={3}>
              <div>status:</div>
              <div>ST:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='status%253Agranted'
              >
                status:granted
              </SearchExampleLink>
            </td>
            <td>The current status of a patent family is granted</td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='status%253Apending'
              >
                status:pending
              </SearchExampleLink>
            </td>
            <td>The current status of a patent family is pending</td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='status%253A%28granted%2520OR%2520pending%29'
              >
                status:(granted OR pending)
              </SearchExampleLink>
            </td>
            <td>The current status of a patent family is granted OR pending</td>
          </tr>
          <tr>
            <td>
              <div>priority_date:</div>
              <div>PRD:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='priority_date%253A2010'
              >
                priority_date:2010
              </SearchExampleLink>
            </td>
            <td>Includes patent families with the priority date in 2010</td>
          </tr>
          <tr>
            <td>
              <div>granted_date:</div>
              <div>GD:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='granted_date%253A2010-05-25'
              >
                granted_date:2010-05-25
              </SearchExampleLink>
            </td>
            <td>Includes patents that granted at this specific date</td>
          </tr>
          <tr>
            <td>
              <div>expiry_date:</div>
              <div>ED:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='expiry_date%253A%257B2010%2520TO%25202012%257D'
              >
                {`expiry_date:{2010 TO 2012}`}
              </SearchExampleLink>
              <div>(see Note 2 below)</div>
            </td>
            <td>Includes patent families expired from 2009 to 2011</td>
          </tr>
          <tr>
            <td>
              <div>publication_date:</div>
              <div>PD:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='publication_date%253A2016'
              >
                publication_date:2016
              </SearchExampleLink>
            </td>
            <td>Includes patent families published in 2016</td>
          </tr>
          <tr>
            <td>
              <div>accession_date:</div>
              <div>AD:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='accession_date%253A%255B2022-01-01%2520TO%25202022-01-31%255D'
              >
                accession_date:[2022-01-01 TO 2022-01-31]
              </SearchExampleLink>
            </td>
            <td>The date at which a family was first added to Cipher</td>
          </tr>
          <tr>
            <td>
              <div>granted_age:</div>
              <div>GAGE:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='granted_age%253A3'
              >
                granted_age:3
              </SearchExampleLink>
            </td>
            <td>Patents that are alive and granted three years since priority date</td>
          </tr>
          <tr>
            <td>
              <div>pending_age:</div>
              <div>PAGE:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='pending_age%253A7'
              >
                pending_age:7
              </SearchExampleLink>
            </td>
            <td>Patents that have been pending for seven years</td>
          </tr>
          <tr>
            <td>
              <div>alive_age:</div>
              <div>AAGE:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='alive_age%253A5'
              >
                alive_age:5
              </SearchExampleLink>
            </td>
            <td>
              Patents that are alive (granted or pending) five years since priority date
            </td>
          </tr>
          <tr>
            <td>age:</td>
            <td>
              <SearchExampleLink
                search_phrase='age%253A18'
              >
                age:18
              </SearchExampleLink>
            </td>
            <td>Patents that are eighteen years old since their priority date</td>
          </tr>
          <tr>
            <td>conversion:</td>
            <td>
              <SearchExampleLink
                search_phrase='conversion%253A2'
              >
                conversion:2
              </SearchExampleLink>
            </td>
            <td>
              The first patent of the family that granted two years since its priority date
              <div>(see Note 3 below)</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={cs.font_weight_bold}>Note 2:</div>
      <div>[ and ] mean: include the boundary</div>
      <div>{`{and}`} mean: exclude the boundary</div>
      <div>The can be mixed however you want, eg.:</div>
      <div>[A TO B] mean: including A and B</div>
      <div>{`[A TO B}`} mean: including A, excluding B</div>
      <div>Combination of date and year in a range is not supported, e.g. [2010 TO 2013-02-25] would not work</div>
      <div className={cs.font_weight_bold}>Note 3:</div>
      <div>
        Key jurisdiction rule applies. Any patent family with a member in a ‘major geography’ (US, EPO, DE, UK, JP, KR) will be considered granted with the first of the major geographies to grant. The few patent families without members in major geographies will be considered granted when the first of any geography grant.
      </div>

      <Heading text='Cost' className='mt-3' />
      <table className={s.table}>
        <TableHeader />
        <tbody>
          <tr>
            <td>
              <div>total_cost:</div>
              <div>TC:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='total_cost%253A%255B20000%2520TO%252025000%255D'
              >
                total_cost:[20000 TO 25000]
              </SearchExampleLink>
            </td>
            <td>
              <div>Patent families with a specific total cost to date</div>
              <div>Currencies are US$</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>total_cost_projection:</div>
              <div>TCP:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='total_cost_projection%253A%255B20000%2520TO%252025000%255D'
              >
                total_cost_projection:[20000 TO 25000]
              </SearchExampleLink>
            </td>
            <td>
              <div>Approximation of remaining total cost for granted patent families</div>
              <div>Currencies are US$</div>
            </td>
          </tr>
        </tbody>
      </table>

      <Heading text='Text' className='mt-3' />
      <table className={s.table}>
        <TableHeader />
        <tbody>
          <tr>
            <td>
              <div>title:</div>
              <div>TI:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='title%253Aheadphone'
              >
                title:headphone
              </SearchExampleLink>
            </td>
            <td>The title must contain the word "headphone"</td>
          </tr>
          <tr>
            <td>
              <div>abstract:</div>
              <div>AB:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='abstract%253Aheadphone'
              >
                abstract:headphone
              </SearchExampleLink>
            </td>
            <td>The abstract must contain the word "headphone"</td>
          </tr>
          <tr>
            <td>
              <div>claims:</div>
              <div>CL:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='claims%253Aheadphone'
              >
                claims:headphone
              </SearchExampleLink>
            </td>
            <td>The claims must contain the word "headphone"</td>
          </tr>
          <tr>
            <td>
              <div>title_abstract_claims:</div>
              <div>TAC</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='TAC%253A%28headphone%2520OR%2520headset%29%2520&amp;org_search='
              >
                TAC:(headphone OR headset)
              </SearchExampleLink>
            </td>
            <td>The title, abstract or claims must contain the word "headphone" or "headset"</td>
          </tr>
        </tbody>
      </table>

      <Heading text='Assignees' className='mt-3' />
      <table className={s.table}>
        <TableHeader />
        <tbody>
          <tr>
            <td>
              <div>owner:</div>
              <div>OW:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='owner%253A%2522Panasonic%2520Corporation%2522'
              >
                owner:"Panasonic Corporation"
              </SearchExampleLink>
              <SearchExampleLink
                search_phrase='owner%253A%28Panasonic%2520OR%2520Dolby%29'
              >
                owner:(Panasonic OR Dolby)
              </SearchExampleLink>
            </td>
            <td colSpan={1} rowSpan={2}>The name of the organisation(s) whose patent portfolio you want to search</td>
          </tr>
          <tr>
            <td>
              <div>assignee:</div>
              <div>AS:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='assignee%253A%2522panasonic%2522'
              >
                assignee:"panasonic"
              </SearchExampleLink>
            </td>
          </tr>
          <tr>
            <td>
              <div>inventor:</div>
              <div>IN:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='inventor%253A%2522john%2520smith%2522'
              >
                inventor:"john smith"
              </SearchExampleLink>
            </td>
            <td>Patent families which include inventor name</td>
          </tr>
        </tbody>
      </table>

      <Heading text='Patent numbers' className='mt-3' />
      <table className={s.table}>
        <TableHeader />
        <tbody>
          <tr>
            <td colSpan={1} rowSpan={2}>
              <div>publication_number:</div>
              <div>PN:</div>
            </td>
            <td>
              <div className='d-flex flex-column'>
                <SearchExampleLink
                  search_phrase='patent_numbers%253AUS9955749B2'
                >
                  publication_number:US9955749B2
                </SearchExampleLink>
                <SearchExampleLink
                  search_phrase='patent_numbers%253AJP6300523B2'
                >
                  publication_number:JP6300523B2
                </SearchExampleLink>
                <SearchExampleLink
                  search_phrase='patent_numbers%253AUS9955749%2A'
                >
                  publication_number:US9955749*
                </SearchExampleLink>
                <SearchExampleLink
                  search_phrase='patent_numbers%253AJP6300523B%2A'
                >
                  publication_number:JP6300523B*
                </SearchExampleLink>
              </div>
            </td>
            <td>
              <div>Patent families that include specific patents</div>
              <div>(see Note 4 below)</div>
            </td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='patent_numbers%253A%28US9955749B2%2520OR%2520JP6300523B2%29'
              >
                publication_number:(US9955749B2 OR JP6300523B2)
              </SearchExampleLink>
            </td>
            <td>
              To search for multiple patent numbers include parentheses. Ensure to include the operator ‘OR’ between each patent number
            </td>
          </tr>
          <tr>
            <td colSpan={1} rowSpan={2}>
              <div>family_id:</div>
              <div>FN:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='family_id%253AC0006940688'
              >
                family_id:C0006940688
              </SearchExampleLink>
            </td>
            <td>Patent family identifier unique to Cipher</td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='family_id%253A%28C0006940688%2520OR%2520C0000211270%29'
              >
                family_id:(C0006940688 OR C0000211270)
              </SearchExampleLink>
            </td>
            <td>
              To search for multiple patent family ids include parentheses. Ensure to include the operator 'OR' between each family id
            </td>
          </tr>
        </tbody>
      </table>

      <div className={cs.font_weight_bold}>Note 4:</div>
      <div>
        To search for the exact publication, ensure to include the full publication number, including the kind code. E.g. ‘A’ , ‘B’, ‘A1’, ‘B1’ etc.
      </div>
      <div>Use the wild card (asterisk) to search only the first few numbers/letters of the patent number</div>

      <Heading text='CPC codes' className='mt-3'/>
      <table className={s.table}>
        <TableHeader />
        <tbody>
          <tr>
            <td colSpan={1} rowSpan={4}>
              <div>cpc_code:</div>
              <div>CPC:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='cpc_code%253AB01D'
              >
                cpc_code:B01D
              </SearchExampleLink>
            </td>
            <td>
              Subclass
              <div>(see Note 5 below)</div>
            </td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='cpc_code%253A%2522B01D%252015%252F16%2522'
              >
                cpc_code:"B01D 15/16"
              </SearchExampleLink>
            </td>
            <td>
              <div>Subgroup</div>
              <div>(see Note 5 and Note 6 below)</div>
            </td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='cpc_code%253A%2522B01D%252015%252F163%2522'
              >
                cpc_code:"B01D 15/163"
              </SearchExampleLink>
            </td>
            <td>
              <div>Subgroup</div>
              <div>(see Note 5 and Note 6 below)</div>
            </td>
          </tr>
          <tr>
            <td>
              <SearchExampleLink
                search_phrase='cpc_code%253A%2522B01D%25202015%252F3838%2522'
              >
                cpc_code:"B01D 2015/3838"
              </SearchExampleLink>
            </td>
            <td>
              CPC 2000 series
            </td>
          </tr>
          <tr>
            <td>
              <div>primary_cpc:</div>
              <div>CPCP:</div>
            </td>
            <td>
              <SearchExampleLink
                search_phrase='CPCP%253AH04R'
              >
                CPCP:H04R
              </SearchExampleLink>
            </td>
            <td>
              Primary CPC in subclass
            </td>
          </tr>
        </tbody>
      </table>

      <div className={cs.font_weight_bold}>Note 5:</div>
      <div>
        An asterisk can only be inserted after the subclass (e.g. cpc_code:B01D*) or after a main group, after the forward slash (e.g. cpc_code:"B01D 15/*").
      </div>
      <div className={cs.font_weight_bold}>Note 6:</div>
      <div>
        Be sure to include quotation marks around the cpc code if it includes a space
      </div>

      <div ref={accepted_geo_codes_table_ref} className='mt-3'>
        <Heading text='Accepted country/territory codes:' on_click={() => history.push({hash: ACCEPTED_GEO_CODES_SECTION_URL_HASH})} className={cs.cursor_pointer}/>
        <div className='d-sm-flex justify-content-between'>

          <table className={cn(s.table, s.geo_table)}>
            <GeoTableHeader />
            <tbody>
            <tr>
              <td>
                Andorra
              </td>
              <td>
                AD
              </td>
            </tr>
            <tr>
              <td>
                Albania
              </td>
              <td>
                AL
              </td>
            </tr>
            <tr>
              <td>
                Armenia
              </td>
              <td>
                AM
              </td>
            </tr>
            <tr>
              <td>
                African Regional IP Organization
              </td>
              <td>
                AP
              </td>
            </tr>
            <tr>
              <td>
                Argentina
              </td>
              <td>
                AR
              </td>
            </tr>
            <tr>
              <td>
                Austria
              </td>
              <td>
                AT
              </td>
            </tr>
            <tr>
              <td>
                Australia
              </td>
              <td>
                AU
              </td>
            </tr>
            <tr>
              <td>
                Bosnia and Herzegovina
              </td>
              <td>
                BA
              </td>
            </tr>
            <tr>
              <td>
                Belgium
              </td>
              <td>
                BE
              </td>
            </tr>
            <tr>
              <td>
                Bulgaria
              </td>
              <td>
                BG
              </td>
            </tr>
            <tr>
              <td>
                Brazil
              </td>
              <td>
                BR
              </td>
            </tr>
            <tr>
              <td>
                Benelux Office for IP
              </td>
              <td>
                BX
              </td>
            </tr>
            <tr>
              <td>
                Belarus
              </td>
              <td>
                BY
              </td>
            </tr>
            <tr>
              <td>
                Canada
              </td>
              <td>
                CA
              </td>
            </tr>
            <tr>
              <td>
                Switzerland
              </td>
              <td>
                CH
              </td>
            </tr>
            <tr>
              <td>
                Chile
              </td>
              <td>
                CL
              </td>
            </tr>
            <tr>
              <td>
                China
              </td>
              <td>
                CN
              </td>
            </tr>
            <tr>
              <td>
                Colombia
              </td>
              <td>
                CO
              </td>
            </tr>
            <tr>
              <td>
                Costa Rica
              </td>
              <td>
                CR
              </td>
            </tr>
            <tr>
              <td>
                Serbia and Montenegro
              </td>
              <td>
                CS
              </td>
            </tr>
            <tr>
              <td>
                Cuba
              </td>
              <td>
                CU
              </td>
            </tr>
            <tr>
              <td>
                Cyprus
              </td>
              <td>
                CY
              </td>
            </tr>
            <tr>
              <td>
                Czechia
              </td>
              <td>
                CZ
              </td>
            </tr>
            <tr>
              <td>
                East Germany
              </td>
              <td>
                DD
              </td>
            </tr>
            <tr>
              <td>
                Germany
              </td>
              <td>
                DE
              </td>
            </tr>
            <tr>
              <td>
                Denmark
              </td>
              <td>
                DK
              </td>
            </tr>
            <tr>
              <td>
                Dominican Republic
              </td>
              <td>
                DO
              </td>
            </tr>
            <tr>
              <td>
                Algeria
              </td>
              <td>
                DZ
              </td>
            </tr>
            <tr>
              <td>
                Eurasian Patent Organization
              </td>
              <td>
                EA
              </td>
            </tr>
            <tr>
              <td>
                Ecuador
              </td>
              <td>
                EC
              </td>
            </tr>
            <tr>
              <td>
                Estonia
              </td>
              <td>
                EE
              </td>
            </tr>
            <tr>
              <td>
                Union of Countries under the European Community Patent Convention
              </td>
              <td>
                EP
              </td>
            </tr>
            <tr>
              <td>
                Egypt
              </td>
              <td>
                EG
              </td>
            </tr>
            <tr>
              <td>
                European Union IP Office
              </td>
              <td>
                EM
              </td>
            </tr>
            <tr>
              <td>
                Spain
              </td>
              <td>
                ES
              </td>
            </tr>
            <tr>
              <td>
                Finland
              </td>
              <td>
                FI
              </td>
            </tr>
            <tr>
              <td>
                France
              </td>
              <td>
                FR
              </td>
            </tr>
            <tr>
              <td>
                Metropolitan France
              </td>
              <td>
                FX
              </td>
            </tr>
            <tr>
              <td>
                United Kingdom
              </td>
              <td>
                GB
              </td>
            </tr>
            </tbody>
          </table>

          <table className={cn(s.table, s.geo_table)}>
            <GeoTableHeader />
            <tbody>
            <tr>
              <td>
                Gulf Cooperation Council
              </td>
              <td>
                GC
              </td>
            </tr>
            <tr>
              <td>
                Georgia
              </td>
              <td>
                GE
              </td>
            </tr>
            <tr>
              <td>
                Gibraltar
              </td>
              <td>
                GI
              </td>
            </tr>
            <tr>
              <td>
                Greenland
              </td>
              <td>
                GL
              </td>
            </tr>
            <tr>
              <td>
                Greece
              </td>
              <td>
                GR
              </td>
            </tr>
            <tr>
              <td>
                Guatemala
              </td>
              <td>
                GT
              </td>
            </tr>
            <tr>
              <td>
                Hong Kong, SAR China
              </td>
              <td>
                HK
              </td>
            </tr>
            <tr>
              <td>
                Honduras
              </td>
              <td>
                HN
              </td>
            </tr>
            <tr>
              <td>
                Croatia
              </td>
              <td>
                HR
              </td>
            </tr>
            <tr>
              <td>
                Hungary
              </td>
              <td>
                HU
              </td>
            </tr>
            <tr>
              <td>
                Indonesia
              </td>
              <td>
                ID
              </td>
            </tr>
            <tr>
              <td>
                Ireland
              </td>
              <td>
                IE
              </td>
            </tr>
            <tr>
              <td>
                Israel
              </td>
              <td>
                IL
              </td>
            </tr>
            <tr>
              <td>
                India
              </td>
              <td>
                IN
              </td>
            </tr>
            <tr>
              <td>
                Iceland
              </td>
              <td>
                IS
              </td>
            </tr>
            <tr>
              <td>
                Italy
              </td>
              <td>
                IT
              </td>
            </tr>
            <tr>
              <td>
                Jordan
              </td>
              <td>
                JO
              </td>
            </tr>
            <tr>
              <td>
                Japan
              </td>
              <td>
                JP
              </td>
            </tr>
            <tr>
              <td>
                Kenya
              </td>
              <td>
                KE
              </td>
            </tr>
            <tr>
              <td>
                Kyrgyzstan
              </td>
              <td>
                KG
              </td>
            </tr>
            <tr>
              <td>
                Cambodia
              </td>
              <td>
                KH
              </td>
            </tr>
            <tr>
              <td>
                North Korea
              </td>
              <td>
                KP
              </td>
            </tr>
            <tr>
              <td>
                South Korea
              </td>
              <td>
                KR
              </td>
            </tr>
            <tr>
              <td>
                Kazakhstan
              </td>
              <td>
                KZ
              </td>
            </tr>
            <tr>
              <td>
                Laos
              </td>
              <td>
                LA
              </td>
            </tr>
            <tr>
              <td>
                Liechtenstein
              </td>
              <td>
                LI
              </td>
            </tr>
            <tr>
              <td>
                Sri Lanka
              </td>
              <td>
                LK
              </td>
            </tr>
            <tr>
              <td>
                Lithuania
              </td>
              <td>
                LT
              </td>
            </tr>
            <tr>
              <td>
                Luxembourg
              </td>
              <td>
                LU
              </td>
            </tr>
            <tr>
              <td>
                Latvia
              </td>
              <td>
                LV
              </td>
            </tr>
            <tr>
              <td>
                Morocco
              </td>
              <td>
                MA
              </td>
            </tr>
            <tr>
              <td>
                Monaco
              </td>
              <td>
                MC
              </td>
            </tr>
            <tr>
              <td>
                Moldova
              </td>
              <td>
                MD
              </td>
            </tr>
            <tr>
              <td>
                Montenegro
              </td>
              <td>
                ME
              </td>
            </tr>
            <tr>
              <td>
                Macedonia
              </td>
              <td>
                MK
              </td>
            </tr>
            <tr>
              <td>
                Mongolia
              </td>
              <td>
                MN
              </td>
            </tr>
            <tr>
              <td>
                Macao, SAR China
              </td>
              <td>
                MO
              </td>
            </tr>
            <tr>
              <td>
                Malta
              </td>
              <td>
                MT
              </td>
            </tr>
            <tr>
              <td>
                Malawi
              </td>
              <td>
                MW
              </td>
            </tr>
            <tr>
              <td>
                Mexico
              </td>
              <td>
                MX
              </td>
            </tr>
            <tr>
              <td>
                Malaysia
              </td>
              <td>
                MY
              </td>
            </tr>
            </tbody>
          </table>

          <table className={cn(s.table, s.geo_table)}>
            <GeoTableHeader />
            <tbody>
            <tr>
              <td>
                Nicaragua
              </td>
              <td>
                NI
              </td>
            </tr>
            <tr>
              <td>
                Netherlands
              </td>
              <td>
                NL
              </td>
            </tr>
            <tr>
              <td>
                Norway
              </td>
              <td>
                NO
              </td>
            </tr>
            <tr>
              <td>
                New Zealand
              </td>
              <td>
                NZ
              </td>
            </tr>
            <tr>
              <td>
                African Intellectual Property Organization
              </td>
              <td>
                OA
              </td>
            </tr>
            <tr>
              <td>
                Panama
              </td>
              <td>
                PA
              </td>
            </tr>
            <tr>
              <td>
                Peru
              </td>
              <td>
                PE
              </td>
            </tr>
            <tr>
              <td>
                Philippines
              </td>
              <td>
                PH
              </td>
            </tr>
            <tr>
              <td>
                Poland
              </td>
              <td>
                PL
              </td>
            </tr>
            <tr>
              <td>
                Portugal
              </td>
              <td>
                PT
              </td>
            </tr>
            <tr>
              <td>
                Romania
              </td>
              <td>
                RO
              </td>
            </tr>
            <tr>
              <td>
                Serbia
              </td>
              <td>
                RS
              </td>
            </tr>
            <tr>
              <td>
                Russian Federation
              </td>
              <td>
                RU
              </td>
            </tr>
            <tr>
              <td>
                Saudi Arabia
              </td>
              <td>
                SA
              </td>
            </tr>
            <tr>
              <td>
                Sweden
              </td>
              <td>
                SE
              </td>
            </tr>
            <tr>
              <td>
                Singapore
              </td>
              <td>
                SG
              </td>
            </tr>
            <tr>
              <td>
                Slovenia
              </td>
              <td>
                SI
              </td>
            </tr>
            <tr>
              <td>
                Slovakia
              </td>
              <td>
                SK
              </td>
            </tr>
            <tr>
              <td>
                San Marino
              </td>
              <td>
                SM
              </td>
            </tr>
            <tr>
              <td>
                Soviet Union
              </td>
              <td>
                SU
              </td>
            </tr>
            <tr>
              <td>
                El Salvador
              </td>
              <td>
                SV
              </td>
            </tr>
            <tr>
              <td>
                Thailand
              </td>
              <td>
                TH
              </td>
            </tr>
            <tr>
              <td>
                Tajikistan
              </td>
              <td>
                TJ
              </td>
            </tr>
            <tr>
              <td>
                Tunisia
              </td>
              <td>
                TN
              </td>
            </tr>
            <tr>
              <td>
                East Timor
              </td>
              <td>
                TP
              </td>
            </tr>
            <tr>
              <td>
                Turkey
              </td>
              <td>
                TR
              </td>
            </tr>
            <tr>
              <td>
                Trinidad and Tobago
              </td>
              <td>
                TT
              </td>
            </tr>
            <tr>
              <td>
                Taiwan
              </td>
              <td>
                TW
              </td>
            </tr>
            <tr>
              <td>
                Ukraine
              </td>
              <td>
                UA
              </td>
            </tr>
            <tr>
              <td>
                United States Minor Outlying Islands
              </td>
              <td>
                UM
              </td>
            </tr>
            <tr>
              <td>
                United States of America
              </td>
              <td>
                US
              </td>
            </tr>
            <tr>
              <td>
                Uruguay
              </td>
              <td>
                UY
              </td>
            </tr>
            <tr>
              <td>
                Uzbekistan
              </td>
              <td>
                UZ
              </td>
            </tr>
            <tr>
              <td>
                Vatican
              </td>
              <td>
                VA
              </td>
            </tr>
            <tr>
              <td>
                Viet Nam
              </td>
              <td>
                VN
              </td>
            </tr>
            <tr>
              <td>
                WIPO
              </td>
              <td>
                WO
              </td>
            </tr>
            <tr>
              <td>
                Yugoslavia
              </td>
              <td>
                YU
              </td>
            </tr>
            <tr>
              <td>
                South Africa
              </td>
              <td>
                ZA
              </td>
            </tr>
            <tr>
              <td>
                Zambia
              </td>
              <td>
                ZM
              </td>
            </tr>
            <tr>
              <td>
                Zimbabwe
              </td>
              <td>
                ZW
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className='mt-2'>Search Guide, January 2024</div>

    </ContainerFixedWidth>
  )
}

export default withRouter(PatentFamilyListGuide)